.read-articles {
    .ch-header-sub {
        display: none !important;
    }

    .ad.ad-loaded::before {
        background: none;
    }

    .articles-container {
        font-family: Inter;
        max-width: $grid-width-desktop;
        margin: auto;

        h1 {
            font-size: 1.5em;
        }
        .not-logged-in {
            border-style: solid;
            border-width: 1px;
            border-radius: 4px;
            border-color: #ddd;
            margin: 12px 12px;
            padding: 12px;
            .not-logged-in-title {
                margin: 12px 0;
            }
        }
        .read-article-teaser-wrapper {
            margin: 12px 12px 12px 24px;
        }
        .widget-articles-container {
            margin-left: 12px;
            margin-right: 12px;
            margin-bottom: 12px;
            height: 100%;
            display: flex;
            flex-direction: column;
            .widget-articles {
                display: flex;
                flex-wrap: wrap;
                margin: 12px;
                .article {
                    flex: 1 0 33%;
                    margin: 1%;
                    border-style: none;
                    border-style: solid;
                    border-width: 1px;
                    border-radius: 4px;
                    border-color: #ddd;
                    .article__img-container {
                        height: 110px;
                        width: 165px;
                        padding-bottom: 0;
                        padding-top: 0;
                        padding-right: 0;
                        margin: 12px 0 12px 12px;
                        img {
                            border-radius: 4px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .articles-container {
            margin: 0 0 24px 0;
            padding: 0;
            h1 {
                margin: 12px 0;
            }
            .widget-articles-container {
                margin: 0;
                .read-article-teaser-wrapper {
                    margin-bottom: 40px;
                    .read-article-teaser-desc {
                        margin: 0;
                        line-height: 22px;
                    }
                }
                .widget-articles {
                    margin: 0;
                    .article:first-child {
                        border-style: solid;
                        border-width: 1px;
                        border-color: #ddd;
                        border-left: none;
                        border-right: none;
                    }
                    .article {
                        flex: 1 0 100%;
                        border-top: none;
                        border-left: none;
                        border-right: none;
                        border-radius: 0;
                        margin: 0;
                        .article__teaser-container {
                            flex: 1;
                            margin: 24px 12px;
                            padding: 0;
                        }
                        .article__text-container {
                            margin: 0;
                        }
                        a.url.article__link {
                            display: flex;
                            flex-direction: row-reverse;
                        }
                        .article__title {
                            line-height: 1.2em;
                            font-size: 1.3125rem;
                            letter-spacing: -0.01em;
                            margin-bottom: 0;
                            font-family: TiemposHeadlineMedium, Georgia,
                                'Times New Roman', Times, serif;
                            font-weight: 500;
                            word-break: break-word;
                            margin: 0;
                            padding: 0;
                        }
                        .article__img-container {
                            width: 128px;
                            height: 85px;
                            margin: 0 0 6px 6px;
                        }
                    }
                }
            }
        }
    }
}

.read-article-teaser.frontpage {
    height: 50px;
    font-family: Inter;
    margin-bottom: 0;
    line-height: 50px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    border: solid 1px #ddd;
    border-radius: 4px;
    padding: 12px;

    img {
        margin: 14px 8px 0 0;
    }
    h1 {
        color: #333;
        font-family: Inter;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.06px;
        margin: 0;
    }

    span {
        font-size: 16px;
        margin-left: 30px;
    }

    a {
        text-align: right;
        flex: 2;
        font-size: 16px;
        margin-left: 30px;
    }

    .see-all-mobile {
        display: none;
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 12px;
        border-left: none;
        border-right: none;
        border-radius: 0;

        a {
            font-size: 15px;
        }
        span {
            display: none;
        }
        .see-all-desktop {
            display: none;
        }
        .see-all-mobile {
            display: block;
        }
    }
}
