.row.bundle-large {
  border-radius: $borderRadius;
  border-style: solid;
  border-width: 1px;
  margin-right: 0;
  overflow: hidden;

  .article {
    flex: 2;
    border-width: 0px;
    margin-bottom: 0;

    .article__kicker-title {
      margin-bottom: $gapSmall * 0.5;
    }
    .article__text-container {
      margin: $gapStandard 0;
    }
    &:hover {
      background-color: #eee;
    }
    &.article--dark:hover {
      background-color: #001b29;
    }
  }
  .article--large {
    padding: $gapStandard $gapStandard 0;
    .article__kicker-title {
      font-size: 16px;
      margin-bottom: $gapSmall;
    }
    .article__img-container {
      height: 0;
      padding-bottom: 67.25%;
      img {
        height: auto;
        border-radius: $gapSmall * 0.5;
      }
    }

    &.article--questions {
      border-bottom: none !important;
      .article-divider {
        display: none;
      }
      .article__teaser-container {
        margin-right: 12px;
        .video-box {
          height: 234px;
        }
      }
    }
  }
  .article--breaking:not(.article--full-width) .article__img-container {
    border-bottom: none;
  }
  .article-divider.vertical {
    height: auto;
    width: 1px;
    margin: $gapStandard 0;
  }
  .side-column {
    flex: 1;

    .article {
      flex: none; // Hover effect only applies to where you can click
      margin: 0;
      overflow: visible;

      .article__teaser-container a {
        flex-direction: column;
      }
      .article__img-container {
        padding: $gapStandard $gapStandard 0 0;
        height: 0;
        padding-bottom: 56.25%;

        img {
          border-radius: $gapSmall * 0.5;
          height: auto;
        }
      }
      .video-container {
        margin-top: $gapStandard;
      }
      .article__labels {
        display: none;
      }
    }

    .article-divider {
      height: 1px;
      width: calc(100% - (#{$gapStandard} * 2));
      margin-left: $gapStandard;
    }

    .article--podcast {
      .podcast__icon {
        display: none;
      }
    }
    .article--questions {
      padding: 0;
      border-bottom: none !important;
    }
  }

  &.bundle-large-x3,
  &.bundle-large-x2 {
    .side-column {
      .article {
        padding: 0 $gapStandard;
      }
    }
  }

  &.bundle-large-x2 {
    .article--large {
      .article__img-container {
        padding-bottom: 56.25%;
      }
    }
    .side-column {
      .article {
        .article__img-container {
          padding-bottom: 75.25%;
        }
        .article__kicker-title {
          font-size: 17px;
          line-height: 21px;
        }
        .article__title {
          @include articleTitle-h2();
          font-family: $fontHeadline;
          font-weight: 500;
        }
      }
    }
  }

  &.bundle-large-x3 {
    .side-column {
      .article {
        .article__title {
          @include articleTitle-h3();
          font-family: $fontHeadline;
          font-weight: 500;
        }
      }
    }
  }

  &.bundle-large-x4,
  &.bundle-large-x5 {
    .article {
      flex: 1;
      margin-right: 0;
      .article__text-container {
        margin: $gapStandard;
      }
    }
    .side-column {
      flex: 1;
      .article {
        .article__teaser-container a {
          flex-direction: row-reverse;
        }
        .article__text-container {
          .article__title {
            @include articleTitle-bundle-medium();
            font-family: $fontHeadline;
            font-weight: $fontHeadlineWeight;
          }
        }
        .article__img-container {
          height: auto;
          padding-bottom: 0;
          width: 140px;

          img {
            border-radius: $gapSmall * 0.5;
          }
        }
      }
    }
  }
  &.bundle-breaking {
    border-bottom: 6px solid #f6231e;
    &.bundle-dark {
      border-bottom: 6px solid #b52521;
    }
  }
}
