@mixin lineClamp(
  $lineHeight,
  $lines,
  $fromColor: rgba(255, 255, 255, 0),
  $toColor: rgba(255, 255, 255, 1) 80%
) {
  position: relative;
  max-height: $lineHeight * $lines * 1em;
  overflow: hidden;

  &::after {
    content: '';
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: $lineHeight * 1em;
    background: linear-gradient(to right, $fromColor, $toColor);
  }

  @supports (-webkit-line-clamp: $lines) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;

    &::after {
      display: none;
    }
  }
}

@mixin articleTitle($fontSize, $lineHeight, $letterSpacing) {
  line-height: $lineHeight;
  font-size: $fontSize;
  letter-spacing: $letterSpacing;
}

@mixin articleTitleLong {
  &.article__title--long {
    @content;
  }
}

@mixin articleTitleShort {
  &.article__title--short {
    @content;
  }
}

@mixin articleTitle-h1() {
  @include articleTitle(
    nth($h1-metrics, 1),
    nth($h1-metrics, 2),
    nth($h1-metrics, 3)
  );
}

@mixin articleTitle-h2() {
  @include articleTitle(
    nth($h2-metrics, 1),
    nth($h2-metrics, 2),
    nth($h2-metrics, 3)
  );
}

@mixin articleTitle-h3() {
  @include articleTitle(
    nth($h3-metrics, 1),
    nth($h3-metrics, 2),
    nth($h3-metrics, 3)
  );
}

@mixin articleTitle-h4() {
  @include articleTitle(
    nth($h4-metrics, 1),
    nth($h4-metrics, 2),
    nth($h4-metrics, 3)
  );
}

@mixin articleTitle-h5() {
  @include articleTitle(
    nth($h5-metrics, 1),
    nth($h5-metrics, 2),
    nth($h5-metrics, 3)
  );
}

@mixin articleTitle-h3-mobile() {
  @include articleTitle(
    nth($h3-mobile-metrics, 1),
    nth($h3-mobile-metrics, 2),
    nth($h3-mobile-metrics, 3)
  );
}

@mixin articleTitle-h4-mobile() {
  @include articleTitle(
    nth($h4-mobile-metrics, 1),
    nth($h4-mobile-metrics, 2),
    nth($h4-mobile-metrics, 3)
  );
}

@mixin articleTitle-h5-mobile() {
  @include articleTitle(
    nth($h5-mobile-metrics, 1),
    nth($h5-mobile-metrics, 2),
    nth($h5-mobile-metrics, 3)
  );
}

@mixin articleTitle-h6-mobile() {
  @include articleTitle(
    nth($h6-mobile-metrics, 1),
    nth($h6-mobile-metrics, 2),
    nth($h6-mobile-metrics, 3)
  );
}

@mixin force1to1Size($size) {
  min-width: $size;
  width: $size;
  max-width: $size;
  min-height: $size;
  height: $size;
  max-height: $size;
}
