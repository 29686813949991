.responsive {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 58%;
}
.responsive .jwplayer {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.webcam-video .jw-preview.jw-reset,
.webcam-video .jw-wrapper.jw-reset,
.webcam-video .jw-wrapper.jw-reset .jw-controls-backdrop {
  border-radius: 8px;
  background-color: transparent;
}
.webcam-video .jw-controls-backdrop {
  background: none;
}
.webcam-video .jw-state-idle .jw-controls.jw-reset {
  background: none;
}
.webcam-video .jw-wrapper.jw-reset .jw-title {
  display: block;
}
.webcam-video video.jw-video.jw-reset {
  border-radius: $borderRadiusSmall;
}
.webcam-video .jw-flag-small-player .jw-title-primary,
.webcam-video .jw-wrapper.jw-reset .jw-title-primary {
  font-size: 14px;
  margin-top: -10px;
  padding-left: 10px;
  font-family: $fontSansSerif;
  font-weight: 600;
  text-shadow: #000 0px 2px 14px;
}
.webcam-video .jw-state-playing .jw-title .jw-title-primary {
  text-shadow: none;
}
.webcam-video
  .svp-player-live.jw-state-idle
  .jw-wrapper
  .jw-icon-display:after {
  display: none;
}
.webcam-video .jw-flag-small-player .jw-wrapper.jw-reset .jw-title-secondary {
  display: block;
}
.webcam-video .jw-wrapper.jw-reset .jw-title-secondary::before {
  border: 4px solid #f6231e;
  border-radius: 17px;
  content: '';
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  width: 4px;
  height: 4px;
  top: 5px;
  right: 57px;
}
.webcam-video .jw-wrapper.jw-reset .jw-title-secondary {
  color: #f6231e;
  text-align: right;
  margin: 10px 10px 0 0;
  background: rgb(255, 255, 255);
  opacity: 0.7;
  width: 77px;
  position: absolute;
  right: 0px;
  font-family: $fontSansSerif;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 12px;
  height: 21px;
  top: 0px;
  padding: 3px 5px;
  border-radius: $borderRadiusSmall;
  line-height: normal;
}
#webcam-frontpage {
  max-width: 980px;
  margin: 0 auto $gapStandard;
  font-size: 17px;
  color: #333333;
  font-family: $fontSansSerif;
  font-weight: 600;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: $borderRadiusSmall;
  overflow: hidden;
}
.webcam-video {
  padding: 16px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border-radius: $borderRadiusSmall;
  background-color: #ffffff;
}
.webcam-video .video-headline {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 16px;
}
.video-headline .title {
  padding-right: 5px;
}
.webcam-teasers-container {
  display: flex;
  flex-direction: column;
  padding: 15px 0 15px 16px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
}
.webcam-teasers-container .teasers-headline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.teasers-headline .title {
  font-size: 19px;
  letter-spacing: 0;
  line-height: 20px;
}
.teasers-headline .see-all {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.57px;
  line-height: 21.6px;
  padding-right: 10px;
}
.webcam-teasers-container .scroll-container {
  height: 100%;
  display: flex;
  flex-grow: 1;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
}
.webcam-teasers-container .image-container {
  display: flex;
}
.image-container .image {
  position: relative;
  overflow: visible;
}
.image-container .image .webcam-img {
  width: 130px;
  max-width: 130px;
  object-fit: cover;
  height: 75px;
  margin: 10px 10px 0 0;
  border-radius: $borderRadiusSmall;
}
.image-container .label {
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  font-family: $fontSansSerif;
  font-weight: 600;
  font-size: 14.4px;
  letter-spacing: -0.15px;
  line-height: 21.6px;
  padding: 14px 0 0 8px;
  text-shadow: #000 0px 2px 14px;
}
.video-headline .camera-icon {
  background-image: url('/seksjon/dist/img/rnd_webcam_icon.svg');
  height: 11px;
  width: 18px;
  background-repeat: no-repeat;
  margin-top: 3px;
}

.webcam-desktop,
.webcam-tablet {
  margin: 40px 0;
}
.webcam-phone {
  margin: 32px 16px;
}

.webcam-desktop .scroll-container {
  visibility: hidden;
}
.webcam-desktop .scroll-container:hover,
.webcam-desktop .scroll-container:focus {
  visibility: visible;
}
.webcam-desktop .image-container {
  visibility: visible;
}

@media screen and (min-width: 992px) {
  .webcam-content {
    display: flex;
  }
  .webcam-video {
    height: 434px;
  }
  .webcam-video .jw-state-idle .jw-wrapper.jw-reset:hover,
  .webcam-video .jw-state-paused .jw-wrapper.jw-reset:hover {
    opacity: 0.9;
  }
  #webcam-frontpage .teasers-headline {
    margin-bottom: 16px;
  }
  .teasers-headline .see-all {
    padding-right: 18px;
  }
  .webcam-teasers-container {
    padding: 16px 0 0 16px;
    height: 434px;
    width: 50%;
    border-left: 1px solid #ddd;
  }
  .webcam-teasers-container .scroll-container {
    flex-direction: row;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .webcam-teasers-container .image-container {
    flex-direction: column;
  }
  .image-container .image {
    flex-direction: column;
  }
  .image-container .label {
    font-size: 16px;
  }
  .image-container .image .webcam-img {
    width: 280px;
    max-width: 280px;
    height: 140px;
    margin: 0 0 10px 0;
  }
  .image-container .image .webcam-img:hover {
    opacity: 0.9;
    cursor: pointer;
  }
}
