.article .article__labels .label--comments .counter-wrapper {
  margin: -4px 0 12px;
  .widget-comment-counter {
    text-align: center;
    font-weight: 500;
    font-size: 9px;
    height: 28px;
    align-items: end;

    div.counter-icon {
      height: 26px;
      line-height: 22px;
      background-size: 100% 100%;
    }

    &.singles {
      div.counter-icon {
        width: 24px;
        background-image: url('#{$cdnHost}/rnd/icons/singles.svg');
      }
    }
    &.tens {
      div.counter-icon {
        width: 24px;
        background-image: url('#{$cdnHost}/rnd/icons/tens.svg');
      }
    }
    &.hundreds {
      div.counter-icon {
        width: 26px;
        background-image: url('#{$cdnHost}/rnd/icons/hundreds.svg');
      }
    }
    &.thousands {
      div.counter-icon {
        width: 34px;
        background-image: url('#{$cdnHost}/rnd/icons/thousands.svg');
      }
    }
  }
}

.horizontal-x1 .article.article--noImage {
  .article__labels .label--comments .counter-wrapper {
    margin: 0;
  }
}

.article--dark
  .article__labels
  .label--comments
  .counter-wrapper
  .widget-comment-counter.singles
  div.counter-icon {
  color: black;
  filter: invert(100%);
}
