$gapStandard: 12px;

#liveticker {
    &.liveticker-count-0 {
        display: none;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-top: 14px;
    position: relative;
    z-index: 1;
    color: $black-base;

    span {
        letter-spacing: -0.01em;
        font-family: 'Inter';
    }

    @include respond-to(mobile-only) {
        justify-content: flex-start;
        margin: $gapStandard 0;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        a:first-of-type {
            margin-left: $gapStandard;
        }

        a:last-child {
            margin-right: $gapStandard;
        }

        &.liveticker-count-1 {
            .liveticker-red {
                display: block;
                margin-left: 12px;
            }
        }
    }

    .liveticker-dot {
        border: none;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin-top: 0;
        animation: livetickerRedDot 750ms infinite alternate;
        animation-delay: 1ms;
        animation-timing-function: ease-in;
        align-self: center;
    }

    .liveticker-red {
        display: block;
        font-weight: 700;
        color: $colorLive;
        font-size: 15px;
        align-self: center;
        margin-right: 6px;

        @include respond-to(mobile-only) {
            display: none;
        }
    }

    .liveticker-item {
        display: flex;
        flex-direction: row;
        gap: 5px;
        border: 1px solid $grey-tint-80;
        border-radius: $borderRadiusSmall;
        padding: 5px 12px 5px 12px;
        margin-right: 6px;
        background-color: $colorBackgroundPage;
        text-decoration: none;
        cursor: pointer;

        span {
            align-self: center;
            font-size: 15px;
            margin: 0;
            line-height: 22px;
            white-space: nowrap;

            &.name {
                color: #333333;
                font-weight: 700;
            }

            &.time {
                color: $black-base;
                font-weight: 400;
            }
        }

        &:hover {
            cursor: pointer;
            background-color: $grey-tint-90;
            border: 1px solid $grey-tint-60;
        }
    }
    .liveticker-item--snooze {
        background: $grey-tint-80;
        span {
            &.name {
                font-weight: 400;
            }

            &.time {
                font-weight: 400;
            }
        }
    }
}

@keyframes livetickerRedDot {
    from {
        background: $colorLive;
    }
    to {
        background: $red-tint-80;
    }
}
