.read-article-teaser.frontpage {
  max-width: 980px !important;
  box-sizing: border-box;
  margin: 0 auto;
  height: unset !important;
}

.article.article--breaking {
  border-color: $borderColorBreaking;
}

.article.article--breaking:hover {
  border-color: $borderColorBreakingHover;
}

// Ad tweaking for full-width page

// Makes all the ads maximum 980px wide so that they will not be full width when theyre not supposed to be.
// This makes the cogwheel and "annonse" stay with the ad itself.
// the :not makes sure this does not affect topscroll, without the :not, the topscroll ad would be sized
// 50% and centered where it is supposed to be fullwidth.
.ad:not(.topscroll) {
  max-width: 980px !important;
}

// Parallax ad - was taking up alot more space than usual and also on the right side of the page besides articles.
// This prevents the L shape and forces it to be a size that fits within our page.
// These unsets the max-width: 1010px.
.ad-takeover > div {
  width: 100vw !important;
}

.ad-takeover .ad-info-wrap .ad-info {
  max-width: 980px;
  margin: 0 auto !important;
}

.ad-takeover-annonse-marker {
  max-width: 980px;
  margin: 0 auto;
}

.ad.brandboard.ad-takeover.ad-loaded.in-screen,
.ad.netboard.ad-takeover.ad-loaded.in-screen {
  max-width: unset !important;
}

// Ads tweaks -- stop

.articles-container {
  width: 100%;
  padding: unset;
  margin: unset;
  margin-top: $page-top-gap;

  .contentboard-container,
  .row,
  #football-widget {
    @media #{$queryDesktop} {
      max-width: 980px;
      margin: 0 auto $gapStandard;
    }
  }
  .row:empty {
    margin: 0;
  }
}

#football-widget {
  background-color: #fff;
  box-sizing: border-box;
  font-family: Inter;
  color: #333333;
  padding: 24px;
  margin: 12px;
  @include respond-to(tablet) {
    max-width: 980px;
    margin: 0 12px 16px;
  }
}

.row {
  margin-right: unset;
  @include respond-to(tablet) {
    margin-left: 12px;
    margin-right: 12px;
  }
}

section.jobb {
  max-width: 980px;
  margin: 0 auto $gapStandard;
  margin-bottom: unset;
}

#innocode {
  max-width: 980px;
  margin: 0 auto $gapStandard;
}

.widget-webcam {
  @include respond-to(tablet) {
    margin-left: 12px;
    margin-right: 12px;
  }
}

.widget-bolig.regional-widget {
  @media #{$queryDesktop} {
    max-width: 980px;
    margin: 0 auto $gapStandard;
  }
}

#bolig-list-widget {
  @include respond-to(web) {
    max-width: 980px;
    margin: 0;
    margin-bottom: 16px;
  }
  @include respond-to(tablet) {
    max-width: 980px;
    margin: 0 0px 12px;
  }
}

.widget-cabin {
  max-width: 980px;
  margin: 0 auto;
}
section.widget-cabin-widget {
  max-width: 980px;
  margin: 0 auto;
}

#cabin-list-widget {
  @include respond-to(web) {
    max-width: 980px;
    margin: 0;
    margin-bottom: 16px;
  }
  @include respond-to(tablet) {
    max-width: 980px;
    margin: 0;
    margin-bottom: 12px;
  }
}

.widget-resultat {
  max-width: 980px;
  margin: 0 auto;
}
