.mm-integration-x1 {
  .article__teaser-container {
    display: flex;

    .video-box * {
      pointer-events: all;
      cursor: pointer;
    }

    .video-box {
      width: 66%;

      .article__link.mm-container {
        width: 100%;
        position: relative;
        height: 363px;
      }
      .article__link.mm-container.podcast {
        padding-top: 0;
      }

      a.article__link.mm-container div.jwplayer {
        position: absolute;
        top: 0;
        left: 0;
      }

      .jw-preview.jw-reset,
      .jw-wrapper.jw-reset,
      .jw-wrapper.jw-reset .jw-controls-backdrop,
      .jwplayer.jw-stretch-uniform .jw-media video {
        border-top-right-radius: $gapStandard * 0.25;
        border-bottom-right-radius: $gapStandard * 0.25;
        background-color: transparent;
      }

      .jw-state-idle .jw-controls.jw-reset {
        background: none;
      }

      .jwplayer.svp-flag-media-audio {
        border-radius: $gapStandard * 0.25;
      }

      .jwplayer.svp-flag-media-audio .jw-preview:after {
        clip-path: inset(0% 0% 0% 0% round $gapStandard * 0.25);
        -webkit-clip-path: inset(0% 0% 0% 0% round $gapStandard * 0.25);
      }
    }

    .text-box {
      width: 34%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .article__link {
        height: unset;
      }

      .article__text-container {
        margin-bottom: 0;

        .article__title {
          &.article__title--long {
            @include articleTitle-h3();
          }
          &.article__title--medium {
            @include articleTitle-h2();
          }
          &.article__title--short {
            @include articleTitle-h2();
          }
        }
      }
    }
  }
  .article--questions {
    display: flex;
    flex-direction: row !important;
    padding: 16px;
    .article__teaser-container {
      flex-direction: column;
      flex: 2;
      .video-box,
      .text-box {
        width: 100%;
      }
      .video-box {
        border-radius: $borderRadiusSmall;
        overflow: hidden;
        height: 344px;
        .mm-container {
          height: 100%;
        }
      }
      .article__text-container {
        margin: $gapStandard 0;
        .article__title--long {
          @include articleTitle-h3();
        }
        .article__title--medium {
          @include articleTitle-h3();
        }
        .article__title--short {
          @include articleTitle-h3();
        }
      }
    }
    .highlighted-comments-container {
      flex: 1;
      font-family: Inter;
      .comments-header {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #0075b0;
        left: 0;
        justify-content: flex-start;
        .comments-counter {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #0075b0;
          left: 0;
          margin-right: 4px;

          &::before {
            content: none;
          }
        }
      }
      .comments-lead-text {
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        margin-top: 4px;
      }
      .highlighted-comments-outer {
        height: 80%;
      }
      .comment-text {
        width: 100%;
        .comment-text-inner {
          border-radius: 16px;
          width: 100%;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: -0.01em;
          color: #333333;
          border-width: 16px;
        }
        &:after {
          content: none;
        }
      }
      .additional-info {
        .comment-author {
          font-size: 12px;
          line-height: 14px;
          color: #666;
        }
      }
      .go-to-comment {
        color: #0075b0;
        border-color: #0075b0;
      }
    }
    .article-divider {
      height: auto;
      width: 1px;
      margin: 0 16px;
    }
  }
}
