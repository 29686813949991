.alt-stripe-wrapper,
.stripe-wrapper {
  max-width: 990px;
  margin: 0 auto;
  position: relative;
  z-index: 999;
  display: none;
}
@include respond-to(mobile-only) {
  .alt-stripe-wrapper,
  .stripe-wrapper {
    margin: 0;
  }
}
