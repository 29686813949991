@keyframes opinion-form-spinner {
  to {
    transform: rotate(360deg);
  }
}

html.section section.widgets.widget-opinion-form {
  max-width: 100%;
  margin: 0 auto;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

  .widget-link,
  #root {
    display: block;
    color: transparent;
    min-height: 1000px;
  }

  .widget-link,
  #root:empty {
    &::before {
      opacity: 0.6;
      content: '';
      display: block;
      width: 130px;
      height: 130px;
      border: 10px solid silver;
      border-top-color: #00878a;
      border-radius: 50%;
      margin: 150px auto auto;
      animation: opinion-form-spinner 0.6s linear infinite;
    }
  }
}
