.opinion-banner {
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  background-color: transparent;
  width: 100%;
  text-align: center;
  margin: 5px auto;
  max-width: 980px;

  a:link,
  a:visited,
  a:hover {
    width: 100%;
    display: block;
    padding: 25px;
    text-decoration: none;
    color: #00878a;
    box-sizing: border-box;
  }

  .opinion-banner-wrapper {
    display: block;
    margin: 0 auto;
  }

  .opinion-banner-title {
    font-size: 24px;
    font-family: $fontHeadline;
    font-weight: $fontHeadlineWeight;
    margin: 0 auto 15px;
    color: #00878a;
  }

  .opinion-banner-desc {
    font-size: 16px;
    line-height: 1.2;
    color: #00878a;
  }

  .opinion-banner-btn {
    display: block;
    width: 170px;
    height: 36px;
    color: #fff;
    font-size: 12px;
    line-height: 36px;
    border-radius: 22px;
    background: #009ea0 url('/seksjon/dist/img/pencil@1.svg') no-repeat 12px
      center;
    background-size: auto 20px;
    box-shadow: 0 2px 0 0 #014a51;
    text-transform: uppercase;
    margin: 15px auto 0;
    border: none;
    padding-left: 30px;
  }

  @media screen and (min-width: 768px) {
    margin: 0 auto;

    .opinion-banner-wrapper {
      max-width: 532px;
    }

    .opinion-banner-title {
      font-size: 38px;
      margin: 30px auto;
    }

    .opinion-banner-btn {
      margin: 30px auto;
      width: 178px;
      height: 43px;
      line-height: 43px;
      border-radius: 21.5px;
      background-position: 16px center;
      padding-left: 24px;
    }
  }
}
