.vertical-x3 {
  .column--small {
    flex: 1;
  }

  .article__img-container {
    height: 0;
    padding-bottom: 56.25%;

    img {
      height: auto;
    }
  }

  .article__title {
    @include articleTitle-h4();

    @include articleTitleLong {
      @include articleTitle-h5();
    }
  }

  .article--withTitleMeta .article__title {
    @include articleTitle-h5();

    @include articleTitleShort {
      @include articleTitle-h4();
    }
  }

  .article--live.no-entry {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
