.ad {
  isolation: isolate;

  &.ad-loaded.topboard:not(.in-horseshoe) {
    &::before {
      padding-left: 16px;
    }
  }

  &:not(.ad-takeover):before {
    position: absolute;
    content: 'Annonse';
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1.2em;
    letter-spacing: 0.1em;
    color: #666666;
    text-transform: uppercase;
    max-height: $ad-annonse-marker-height;
    margin: 0;
    left: 0;
    top: 0;
    padding: 1px 0 2px 0;
  }

  &:empty {
    visibility: hidden;
  }
}

.ad[id*='board']:not(.no-ad-marker):before {
  position: relative;
}

.ad-info-wrap {
  z-index: 3001;
}

.mobile-ad > .ad-info-wrap {
  max-width: none !important;
}

// Tablet only hack to override gigantic desktop font-size on contentboard
@media (min-width: 768px) and (max-width: 1024px) {
  .contentboard-container
    .brandstudio-premium-content-widget
    .preview-description {
    > .description-element p {
      font-size: 44px !important;
    }
    > .preview-subtitle-container p {
      font-size: 20px !important;
    }
  }
}

@include respond-to(mobile-only) {
  .ad:not(.ad-takeover) {
    width: 100%;
    margin: 0 0 15px;
    position: relative;
    overflow: hidden;
    padding: $gapStandard 0;
  }

  .brandstudio-premium-content-widget {
    margin-top: $gapStandard * 2;
    margin-bottom: $gapStandard * 2;
  }

  .ad:before {
    width: 100%;
  }

  .topBanner {
    overflow: visible;
  }

  .ad img:not(.ad-image, .ad-logo),
  .ad embed:not(.ad-image) {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    display: block;
  }

  .ad > a > img:not(.ad-image),
  .ad > div > a > img:not(.ad-image) {
    max-width: 320px;
    max-height: 100%;
    height: auto !important;
    width: auto;
  }

  .jobb {
    margin: auto;
    width: 100%;
  }

  .realestate > .ad-info-wrap {
    max-width: 320px !important;
  }
}

#inner-container .ad.ad-takeover {
  height: 100vh !important;
  border-bottom: none;
  border-top: none;
  display: block !important;

  &.in-screen:before,
  &.in-screen:after {
    background-color: #000;
  }
}

.ch-header-main.has-takeover {
  display: none;
}

@include respond-to(mobile-only) {
  .frontpage .ad.ad-takeover {
    overflow: visible;
  }
}

.frontpage #inner-container .ad.ad-takeover > div {
  padding: 0 !important;
}

.frontpage #inner-container .ad.ad-takeover:before {
  content: '' !important;
}

@media only screen and (max-width: 739px) {
  .frontpage #inner-container .ad.ad-takeover:before {
    content: '' !important;
  }
}

.ad-native.ssa-ad {
  @include respond-to(mobile-only) {
    .title {
      margin-top: 15px;
    }
  }
}

.mobile-ad.has-native > .ad-info-wrap {
  top: 19px !important;
}
.mobile-ad.has-native-content > .ad-info-wrap,
.mobile-ad.has-ssa > .ad-info-wrap {
  top: 0 !important;
}

.frontpage #inner-container .ad.mobile-ad > div {
  margin: auto !important;
}

.frontpage #inner-container .ad.mobile-ad.has-native > .ad-info-wrap {
  margin: 0 auto !important;
  padding: 0 !important;
}

@include respond-to(tablet) {
  .ad[id*='netboard']:not(.has-native):not(#brandboard) .ad-info-wrap,
  .ad[id*='netboard']:not(.has-native):not(#brandboard):not(.mobile-ad)
    .ad-info-wrap {
    margin: 0 auto;
  }

  .ad[id*='netboard'].has-native:not(#brandboard):not(#contentboard)
    .ad-info-wrap {
    width: auto !important;
    margin: 0 auto;
  }

  #topboard {
    display: block;
  }
}

.frontpage .mobile-ad:not(.has-native).blink .ad-info-wrap {
  top: 0 !important;
}

.disable-cogwheel .ad-info {
  display: none;
}

.takeover-parent {
  .list-row {
    padding-bottom: 0;
  }
}

// import of old huberto-ads.scss
@import '../../core/scss/helpers/mixins';
@import '../../core/scss/helpers/core-variables';
@import '../../core/scss/huberto/ads';

.ad {
  padding: 0;

  &::before {
    display: none;
    color: $color-grey;
  }

  .articles-container & {
    background-color: transparent;
  }

  &.ad-loaded {
    &::before {
      display: block;
      background: url('#{$cdnHost}/aftenbladet/arrow-down.svg') $gridBase 0
        no-repeat;
      padding: 0;
      text-transform: uppercase;
      position: absolute;
      width: 40px;
    }
  }
}

#top-ads-container {
  margin: 0 auto;
  text-align: center;
  min-height: 150px;
}

#topboard {
  margin: auto;
  margin-bottom: -5px;
  padding: 0;

  &.ad:before {
    position: relative;
  }
}

#skyscraperleft_1 {
  padding-top: 16px;
}

#skyscraperright_1 {
  padding-top: 15px;
}

.ad.topboard.ad-loaded.in-horseshoe:before {
  padding-left: 0;
}

.frontpage .ad.topboard:before {
  padding-left: 16px;
}

div#wallpaper-background {
  background-position: center 16px;
}

.sectionpage {
  #skyscraperleft_1,
  #skyscraperright_1 {
    top: 0;
  }
}

.contentboard-container,
.brandboard-container {
  box-sizing: border-box;
  margin-bottom: $gapStandard * 2;
  margin-top: $gapStandard;

  &.no-ad {
    margin-bottom: 0;
    margin-top: 0;
  }

  @media #{$queryDesktop} {
    margin-left: 0;
    width: $widthAdFullwidth;
  }
}

.brandboard {
  padding-top: $gapStandard;
}

#brandboard {
  margin-left: 0;
  margin-bottom: $gapStandard;
}

.articles-container .netboard {
  padding: 0;
}

.native-ad::before {
  display: block;
  background: url('#{$cdnHost}/aftenbladet/arrow-down.svg') $gridBase 0
    no-repeat;
  padding: 0 20px;
  font-family: sans-serif;
  font-size: 9px;
  line-height: 1.6;
  color: #979797;
  text-transform: uppercase;

  content: 'Annonse';
}

div.ad-takeover-annonse-marker {
  display: none;
}

body.takeover-in-screen div.ad-takeover-annonse-marker {
  display: block;
  .marker-wrap .marker-label {
    text-transform: uppercase;
  }
  @include respond-to(tablet) {
    margin-bottom: 0;
    .marker-wrap {
      margin-top: 15px;
    }
  }
}

// Mobile
#inner-container > .stripe-wrapper {
  margin: $gapStandard auto;
}

// Desktop
#top-ads-container + .stripe-wrapper {
  margin: $gapStandard auto;
}
