.lf {
  &-search {
    font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial;
    position: relative;
    width: 100%;

    &__filter {
      min-width: 100%;
    }

    &-icon {
      width: 19px;
      height: 19px;
      position: absolute;
      top: 12px;
      left: 16px;
      cursor: pointer;

      &__right {
        right: 16px;
        left: initial;
        transition: 0.3s;

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }

    &-input {
      background-color: rgb(255, 255, 255);
      border: 1px solid #999090;
      border-radius: $searchBorderRadius;
      box-sizing: border-box;
      padding: 12px 16px 12px 40px;
      width: 100%;
      cursor: pointer;
      font-size: 16px;

      &:focus,
      &.visible {
        cursor: auto;
        border-bottom-right-radius: initial;
        border-bottom-left-radius: initial;
        border-bottom: 1px solid #888;
        outline: none;
      }
    }

    &-dropdown {
      position: absolute;
      z-index: 10;
      top: calc(24px + 19px);
      left: 0;
      width: 100%;
      background-color: #fff;
      border: 1px solid #999090;
      border-bottom-right-radius: $searchBorderRadius;
      border-bottom-left-radius: $searchBorderRadius;
      box-sizing: border-box;
      overflow: auto;
      max-height: 200px;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      visibility: hidden;

      &.visible {
        visibility: visible;
      }

      &-list {
        color: $text-color;
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;

        &__item {
          padding: 8px 20px;

          &:hover,
          &:focus {
            background-color: rgb(232, 233, 227);
            font-weight: 800;
            cursor: pointer;
            outline: none;
          }
        }
      }
    }
  }
  &-previoussearch {
    margin-top: 16px;
    font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial;
    width: 100%;
    display: flex;
    align-items: center;

    &-title {
      font-size: 0.9em;
      margin: 0 4px 0 0;
      white-space: nowrap;
    }

    &-collection {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    &-badge {
      max-width: calc(50% - 44px);
      border-radius: 50px;
      background-color: $color-btn;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0 2px;
      padding: 6px 18px 6px 14px;

      &:hover {
        background-color: $color-btn-hover;
      }

      &-img {
        max-height: 20px;
        max-width: 20px;
        margin-right: 4px;
        @include bigger-than(tablet) {
          max-height: 24px;
          max-width: 24px;
        }
      }

      &-text {
        color: $text-color;
        margin: 0;
        font-size: 0.8em;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &-filter {
    $widthCompact: 100px;
    font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial;
    position: absolute;
    left: calc(100% - #{$widthCompact});
    width: $widthCompact;

    &-input {
      background-color: #383838;
      border: 1px solid #383838;
      color: #fff !important;
      border-radius: $searchBorderRadius;
      box-sizing: border-box;
      padding: 8px 12px;
      width: $widthCompact;
      cursor: pointer;
      transition: border-bottom-right-radius 100ms,
        border-bottom-left-radius 100ms, width 0ms;
      font-weight: 400;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &::placeholder {
        color: #fff;
      }

      &:focus,
      &.visible {
        cursor: auto;
        border-bottom-right-radius: initial;
        border-bottom-left-radius: initial;
        border-bottom: 1px solid #888;
        outline: none;
        width: 100%;
      }
    }

    &-dropdown {
      position: absolute;
      z-index: 10;
      top: calc(16px + 18px);
      left: 0;
      background-color: #383838;
      border: 1px solid #383838;
      border-bottom-right-radius: $searchBorderRadius;
      border-bottom-left-radius: $searchBorderRadius;
      box-sizing: border-box;
      overflow: auto;
      max-height: 200px;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */

      &.visible-fadeOut {
        visibility: hidden;
        width: auto;
        opacity: 0;
        transition: 100ms;
      }

      &.visible-fadeIn {
        visibility: visible;
        width: 100%;
        opacity: 1;
        transition: 200ms;
      }

      &-list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        color: #fff;

        &__item {
          padding: 8px 20px;
          color: #fff !important;
          &:hover,
          &:focus {
            background-color: #535353;
            color: #fff;
            font-weight: 800;
            cursor: pointer;
            outline: none;
          }
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  &-filterlist {
    position: relative;
    width: 120px;
    background-color: $color-btn;
    color: #000;
    border: 1px solid #bbbbbb;
    padding: 6px 30px 6px 12px;
    border-radius: 100px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    @include bigger-than(tablet) {
      width: 140px;
    }

    &::placeholder {
      color: #000;
    }
    &:focus {
      outline: none;
    }
    &:hover,
    &:active {
      cursor: pointer;
      background-color: $color-btn-hover;
    }
    &-container {
      position: relative;
      display: inline-flex;
    }

    &-icon {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 9px;
      right: 12px;
      cursor: pointer;
      pointer-events: none;
    }
  }
}
