.lysfesten-front-widget {
  color: #fff;
  font-weight: 600;
  font-family: $fontSansSerif;
  min-height: fit-content;
  margin: $gridBase * 2 0;

  a {
    display: inline-block;
    min-height: 147px;
    width: 100%;
    background-image: url($cdnHost + '/bt/lysfesten/lysfesten-banner-2023.png');
    background-size: cover;
    background-position: 32% center;

    .widget-inner-container {
      width: 100%;
      margin: auto;
    }
    .text-content {
      width: fit-content;
      height: fit-content;
      margin: 10% 6%;
      padding: $gridBase * 2;

      img {
        height: 30px;
      }
      p {
        width: fit-content;
        margin: $gridBase auto;
      }
    }
  }

  @media (min-width: $grid-width-tablet) {
    font-size: 20px;

    //---- full width css -----
    position: relative;
    width: calc(100vw - 16px);
    left: calc(-50vw + 50% + 8px);
    //-------------------------

    a {
      min-height: 328px;
      background-position: center 73%;

      .text-content {
        margin-left: $gridBase;
        img {
          height: 55px;
        }
      }
    }
    .widget-inner-container {
      max-width: $grid-width-tablet;
    }
  }

  @media (min-width: $grid-width-desktop) {
    font-size: 24px;

    a {
      min-height: 374px;
      .text-content {
        margin-left: $gridBase * 2;
        img {
          height: 76px;
        }
      }
    }
    .widget-inner-container {
      max-width: $grid-width-desktop;
    }
  }
}
