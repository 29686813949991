#brandboard.ad.brandboard.ad-takeover.ad-loaded.in-screen:before,
#brandboard.ad.brandboard.ad-takeover.ad-loaded.in-screen:after {
  background: $colorBackgroundPage !important;
}

#brandboard.ad.brandboard {
  margin-left: 0px;
  margin-bottom: $gapStandard;
}

#brandboard.ad.brandboard.ad-takeover.ad-loaded.in-screen {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

body.takeover-in-screen #brandboard-annonse-marker.ad-takeover-annonse-marker {
  .marker-wrap {
    .marker-label {
      text-transform: uppercase;
    }
  }

  @include respond-to(tablet) {
    margin-bottom: 0;
    .marker-wrap {
      margin-top: 31px;
    }
  }
}

section.jobb {
  margin-bottom: $gapStandard;
}

#stripe {
  margin: 14px 0;

  &:empty {
    margin: 0;
  }
}
