.mobile {
  .ad.mobile-ad.ad-loaded:not(.ad-takeover):not(.skyscraper-wallpaper) {
    background-color: $adBackgroundColor;
    padding: 24px 0;
    &::before {
      margin: 0 auto;
      color: $adLabelColor;
      line-height: 24px;
      max-height: 24px;
    }

    // overwrite native ads width 100%
    .ad-native-premium,
    .ad-native {
      width: calc(100% - 32px);
    }
  }
}
