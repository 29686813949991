html,
body {
  font-size: $fontBaseSize;
  background: $colorBackgroundPage;
}

body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}
