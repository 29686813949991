// The baseline is mobile first and classic braze cards — variant overrides are at the bottom of the file
.braze.content-card {
    --content-spacing: 12px;
    --card-title-color: #000;
    --card-description-color: #333333;
    --white-base-color: #fff;
    --card-bg-color: #f5f5f5;

    &.sa {
        --title-family: 'TiemposHeadlineMedium';
        --title-font-weight: 500;
        --action-bg-color: #1458b3;
        --action-bg-hover-color: #1971e6;
        --card-border-color: var(--action-bg-color);
    }

    &.bt {
        --title-family: 'TabacG2';
        --title-font-weight: 600;
        --action-bg-color: #1971e6;
        --action-bg-hover-color: #1c7eff;
        --card-border-color: var(--action-bg-color);
        &.classic-card-salg,
        &.captioned-image-salg {
            --action-bg-color: #1e1e1e;
            --action-bg-hover-color: #302f2f;
            --card-bg-color: #0082ff;
            --card-title-color: #fff;
            --card-description-color: #fff;
            --card-border-color: var(--card-bg-color);
        }
    }

    .ab-feed {
        box-shadow: none;
        width: 100%;
        background-color: transparent;
        border-radius: 0;
        font-family: Inter;
    }

    .ab-feed .ab-card {
        margin: 0;
    }

    .ab-feed .ab-card .ab-pinned-indicator,
    .ab-feed .ab-card .ab-unread-indicator,
    .ab-feed .ab-feed-buttons-wrapper {
        display: none;
    }

    .ab-feed .ab-feed-body {
        padding: 0;
        border: none;
    }

    .ab-feed .ab-feed-body .ab-card {
        width: unset;
        box-shadow: none;
        border-radius: 6px;

        display: flex;
        justify-content: center;
        gap: var(--content-spacing);

        background-color: var(--card-bg-color);
        border: 2px solid var(--card-border-color);
        border-radius: 6px;

        @media screen and (min-width: $pageWidthTablet) {
            align-items: center;
        }
    }

    .ab-feed .ab-card.ab-classic-card {
        padding: 16px 20px;
        margin-bottom: 0;

        @media screen and (min-width: $pageWidthTablet) {
            padding: 24px 52px;
        }
    }

    .ab-feed .ab-card.ab-classic-card .ab-image-area {
        height: 60px;
        width: 60px;
        flex-shrink: 0;
        position: static;
        padding: 0;

        > img {
            aspect-ratio: 1 / 1;
        }

        @media screen and (min-width: $pageWidthTablet) {
            height: 94px;
            width: 94px;
        }
    }

    .ab-feed .ab-card.ab-classic-card .ab-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
    }

    .ab-feed .ab-feed-body .ab-card .ab-title {
        font-family: var(--title-family);
        text-wrap: balance;
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: var(--title-font-weight);
        padding: 0;
        color: var(--card-title-color);

        @media screen and (min-width: $pageWidthTablet) {
            font-size: 1.5625rem;
            line-height: 1.625rem;
        }
    }

    .ab-feed .ab-card.ab-classic-card:not(.with-image) .ab-title {
        margin-top: 0;
    }

    .ab-feed .ab-card .ab-description,
    .ab-feed .ab-card.ab-classic-card.with-image .ab-description {
        padding: 0;
        margin-top: var(--content-spacing);
        font-family: inherit;
        font-size: 0.875rem;
        line-height: 1.375rem;
        font-weight: 500;
        color: var(--card-description-color);
    }

    .ab-feed .ab-card .ab-url-area {
        margin-top: var(--content-spacing);

        > a {
            color: var(--action-bg-color);
            font-weight: 500;
            font-family: inherit;
            font-size: 1rem;

            &::after {
                content: '→';
                margin-left: 5px;
                font-family: 'Inter';
            }
        }
    }

    // Variant override — captioned-image
    .ab-feed .ab-card.ab-captioned-image {
        --image-height: 225px;
        flex-direction: column;
        align-items: center;

        .ab-card-body {
            padding: 24px 30px 16px 30px;
        }

        .ab-image-area {
            height: var(--image-height);

            > img {
                aspect-ratio: 16 / 9;
                object-fit: contain;
                height: 100%;
            }
        }

        .ab-url-area {
            margin-top: var(--content-spacing);

            > a {
                display: inline-block;
                text-align: center;
                font-size: 1rem;
                line-height: 1.5rem;
                padding: 8px 16px;
                min-width: 138px;
                color: var(--white-base-color);
                border-radius: 6px;
                background-color: var(--action-bg-color);

                &:hover {
                    text-decoration: none;
                    background-color: var(--action-bg-hover-color);
                }

                &::after {
                    content: none;
                }
            }
        }

        @media screen and (min-width: $pageWidthTablet) {
            --image-height: 290px;
            --content-spacing: 24px;
            gap: 0;
            flex-direction: row;
            flex-flow: row-reverse;

            .ab-card-body {
                padding: 48px 80px;
            }

            .ab-card-body .ab-title {
                margin-top: 0;
            }

            .ab-card-body .ab-description {
                margin-top: 24px;
                font-size: 1.125rem;
                line-height: 1.5rem;
            }
        }
    }
}
