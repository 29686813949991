$tablet-width: 768px;

.ch-sub-links {
  padding-top: 30px;
}

.page-header {
  max-width: unset;
  font-family: $fontSansSerif;
  font-weight: normal;
  color: $colorText;
  margin-bottom: $gapStandard;
  padding: 0px $gapStandard $gapStandard;

  > * {
    margin: 0 auto;
    max-width: 980px;
  }

  .page-main-container {
    gap: 24px;
    display: block;
    align-items: unset;
    padding: 0px 0px $gapStandard * 2 0px;
  }
}

.page-title {
  color: #000;
  font-family: $fontHeadline;
  font-weight: 100;
  line-height: normal;
  letter-spacing: -0.64px;
  margin: 0px;
  padding: 0px;
  font-size: 35px;
}

.page-description {
  color: #333;
  letter-spacing: -0.17px;
  font-size: 15px;
  line-height: 22px;
  max-width: 100%;
  padding-top: 16px;
}

.page-info-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  color: $colorArticleLabels;
  padding: 0px;
  border: none;
}

.page-timestamp {
  color: #ddd;
}

.page-total-articles {
  display: none;
}

.page-tag {
  color: $colorTextPageTag;
  font-size: 13px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  background-color: $colorTextPageTagBackground;
  border-radius: 16px;
  padding: 0 $gapStandard;
  margin-right: 12px;
  display: none;
}

.divider {
  display: none;
  border-left-width: 1px;
  border-left-style: solid;
  height: $gapStandard * 2;
  margin: 0 $gapStandard;
}

.published {
  height: 16px;
  width: 16px;
  background-image: url('/seksjon/dist/img/small_publish.svg');
  margin: 0 6px;
}

@media (min-width: $tablet-width) {
  .page-header {
    padding: $gapStandard * 5 $gapStandard * 2 $gapStandard * 3;

    .page-main-container {
      display: flex;
      align-items: last baseline;
      padding: 40px 0px;

      .page-title {
        font-size: 64px;
      }

      .page-description {
        font-size: 17px;
        line-height: normal;
        max-width: 80%;
      }
    }
  }

  .divider,
  .page-tag,
  .page-total-articles {
    display: inline-block;
  }
}
