body .highlighted-comments-container.hc-mobile {
  height: auto;
  margin-top: 0;

  &.hc-hide {
    display: none;
  }

  .highlighted-comments-outer {
    display: block;
    position: static;
    bottom: 0;
    height: auto;
    width: auto;
  }

  .comments-header {
    margin: 0 16px 18px;
  }

  .go-to-comment-container {
    margin: 0 16px 18px;
    border-bottom: solid 1px #bdb4af;
  }
  .go-to-comment {
    display: block;
  }

  .highlighted-comments {
    -webkit-mask-image: none;
    height: 150px;
    position: relative;

    .hc-carousel-container {
      position: absolute;
      z-index: 5;
      top: 0;
      left: -320px;
      display: flex;
      flex-direction: row;
    }

    .highlighted-comment {
      transition: all 1s;
      width: auto;
    }

    .highlighted-comment-inner {
      margin-left: 16px;
      display: block;
      box-sizing: border-box;
    }

    .comment-text-inner {
      height: 131px;
      line-height: 21px;
      font-size: 18px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

body article.article.article--highlightedComments {
  border-bottom: none;

  .article__title {
    margin-bottom: 25px;
  }

  .article__labels {
    padding-bottom: 3px;
  }

  .label--comments .counter-wrapper {
    display: none;
  }
}
