.vertical-x1-ad {
  &.hasCenteredAd {
    background-color: $adBackgroundColor;
    padding: $gapStandard;
    margin-left: -($gapStandard * 2);
    margin-right: -($gapStandard * 2);
  }

  &.row .column.column--medium {
    flex: none;
    width: 388px; // 980px-12px-580px || calc(980px - $gapStandard - $widthAdNetboard)
  }

  .column--big {
    flex: none;

    width: $widthAdNetboard;
    &.centeredAd {
      margin: 0 auto;
    }
  }

  .column--medium {
    flex: auto;
    margin-top: 0px;

    .article__img-container {
      height: 0;
      padding-bottom: 56.25%;

      img {
        height: auto;
      }
    }
  }
}
