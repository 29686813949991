.lf {
  &-header {
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding: 16px;
    margin: -45px -16px 45px;
    box-sizing: border-box;
    font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial;
    color: #fff;
    background-color: #0f055e;

    &.bt {
      background-color: #001c3a;
      background: linear-gradient(
        225.66deg,
        rgba(255, 215, 215, 1) 26.47%,
        rgba(242, 242, 242, 1) 62.12%
      );

      .lf-header-bottom p {
        color: #666;
      }
    }
    &.sa {
      background-color: #0f055e;

      .lf-header-bottom p {
        color: rgba(255, 255, 255, 0.6);
      }
    }

    @include respond-to(tablet) {
      padding: 16px;
      margin: 0 0 45px;
      border-radius: 8px;
      width: 100%;
    }
    &-top {
      grid-area: 1 / 1 / 2 / 6;
      h2 {
        font-size: 1em;
        font-weight: 600;
        color: #333;
      }
    }
    &-main {
      background: linear-gradient(262.63deg, #c71b10 11.57%, #f6231e 58.8%);
      grid-area: 2 / 1 / 4 / 6;
      z-index: 10;
      width: fit-content;
      display: flex;
      align-items: center;
      border-radius: 2rem;
      max-width: 100%;
      box-sizing: border-box;

      h1 {
        color: #fff;
        width: fit-content;
        padding: 16px 31px 16px 16px;
        border-radius: 32px;
        font-size: 28px;
        line-height: 16px;
        font-weight: 800;
        margin: 0;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        @include respond-to(tablet) {
          font-size: 1.6em;
        }
      }
      img {
        height: 2rem;
        width: auto;
        padding-left: 16px;
        padding-right: -8px;
        @include respond-to(tablet) {
          height: 2rem;
        }
      }
    }
    &-logo {
      grid-area: 2 / 5 / 4 / 6;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 58px;
        width: auto;

        @include respond-to(tablet) {
          height: 77px;
        }
      }
    }
    &-bottom {
      grid-area: 4 / 1 / 5 / 6;
      padding-top: 16px;
      display: flex;
      align-items: left;
      flex-direction: column;
      gap: 16px;
      p {
        margin: 0;
        font-size: 0.9em;
        font-weight: 500;
      }
      img {
        height: 2.5rem;
        width: auto;
      }
    }
    &-background {
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      pointer-events: none;

      @include respond-to(tablet) {
        border-radius: 8px;
      }
      .footballs {
        height: 200%;
        width: auto;
        right: -7rem;
        top: -7rem;
        position: absolute;
      }
    }
  }
}

.lf {
  &-header {
    &.bt {
      .lf-header-main.clubpage {
        background: #fff !important;
        h1 {
          color: #333;
        }
      }
    }
  }
}
