.ch-core-header {
  #ch-cc-notifications {
    display: flex;
    align-items: center;
    margin: 0;
  }

  #ch-cc-notifications-link {
    display: flex;
    flex-wrap: wrap;
    align-content: center;

    .ch-cc-icon {
      width: 24px;
      height: 24px;
      margin: 0 16px;

      &.read {
        display: none;
      }
      @include respond-to(tablet-only) {
        margin: 0;
      }
      @include respond-to(mobile-only) {
        margin: 0 6px;
      }
    }
  }
}
