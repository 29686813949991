.article__kicker-title {
  @include articleTitle-kicker-label();
  font-family: $fontSansSerif;
  font-weight: normal;
  margin: 0 0 $gapSmall 0;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;

  .column--big & {
    @include articleTitle-kicker-label-big();
  }

  @media (max-width: 768px) {
    @include articleTitle-kicker-label-mobile();
  }
}

.article__title {
  font-family: $fontHeadline;
  font-weight: $fontHeadlineWeight;
  text-wrap: balance;
  margin: 0;
  padding: 0;
}
