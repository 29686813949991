#football-widget {
    background-color: #fff;
    box-sizing: border-box;
    font-family: Inter;
    color: #333333;
    padding: 24px;
    margin: 12px;
    @include respond-to(web) {
        width: auto;
        margin: 0 0 16px;
    }
    @include respond-to(tablet) {
        width: auto;
        margin: 0 0 16px;
    }
    border: solid 1px #ddd;
    border-radius: $borderRadius;
    @include respond-to(mobile-only) {
        padding: 16px 12px;
    }
    .nav {
        display: flex;
        flex-direction: column;
        @include respond-to(tablet) {
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: row;
            gap: 3rem;
        }
        .header {
            display: flex;
            gap: 0.5rem;
            justify-content: space-between;
            .logo-container {
                display: flex;
                gap: 0.6rem;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;
                &__vertical {
                    flex-direction: column;
                    align-items: flex-start;
                    .spons {
                        gap: 0.2rem;
                        p {
                            opacity: 0.5;
                        }
                    }
                }
                @include respond-to(tablet) {
                    flex-wrap: nowrap;
                }
                a {
                    .logo {
                        height: 40px;
                        width: auto;
                    }
                    .logo-text {
                        margin: 0;
                        font-size: 1.5em;
                    }
                }
                .header-divider {
                    height: 3rem;
                    width: 1px;
                    background-color: #dddddd;
                }
                .spons {
                    text-decoration: none;
                    color: inherit;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    p {
                        color: inherit;
                        font-size: 0.8em;
                        margin: 0;
                        white-space: nowrap;
                    }

                    .spons-logo {
                        width: auto;
                        height: 36px;
                        &.small {
                            height: 14px;
                        }
                    }
                }
            }
        }
        .search {
            width: 100%;
            @include respond-to(mobile-only) {
                margin-top: 1rem;
            }
            &-row {
                display: flex;
                gap: 0.5rem;
                align-items: center;
            }
            form {
                font-family: Inter;
                position: relative;
                display: inline-block;
                box-sizing: border-box;
                width: 100%;

                #icon-search {
                    pointer-events: none;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-52%);
                    left: 13px;
                }
                &.open {
                    input#autocomplete {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                        box-shadow: none;
                    }
                    .dropdown {
                        display: flex;
                    }
                    #chevron-football {
                        transform: translateY(-43%);
                        -webkit-transform: translateY(-43%);
                    }
                }

                #chevron-football {
                    transition-duration: 0.225s;
                    transition-property: transform;
                    cursor: pointer;
                    position: absolute;
                    right: 7px;
                    padding: 0.4rem;
                    top: 50%;
                    transform: translateY(-50%) scaleY(-1);
                }

                input#autocomplete {
                    border: 1px solid #ddd;
                    background-color: white;
                    border-radius: 20px;
                    width: 100%;
                    padding: 12px;
                    text-indent: 26px;
                    font-size: 16px;
                    box-sizing: inherit;
                    @include respond-to(tablet) {
                        text-indent: 30px;
                    }
                }

                .dropdown {
                    position: absolute;
                    top: 44px;
                    z-index: 999;
                    display: none;
                    flex-direction: column;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                    border: 1px solid #ddd;
                    background-color: #fff;
                    max-height: 300px;
                    overflow-y: scroll;
                    overflow-x: auto;
                    box-sizing: inherit;
                    li {
                        display: inline-flex;
                        &:not(:last-of-type) {
                            border-bottom: 1px solid #eee;
                        }
                        &:last-of-type {
                            padding-bottom: 0.3rem;
                        }
                        &.last {
                            a {
                                border-bottom-left-radius: 20px;
                                border-bottom-right-radius: 20px;
                            }
                        }
                        a {
                            cursor: pointer;
                            padding: 0.6rem;
                            width: 100%;
                            max-width: 100%;
                            color: inherit;
                            text-decoration: none;
                            word-wrap: break-word;
                            &:hover,
                            &:focus {
                                background-color: #eee;
                            }
                        }
                        &#no-result {
                            display: none;
                            padding: 0.5rem;
                            color: #484848;
                            border-bottom-left-radius: 20px;
                            border-bottom-right-radius: 20px;
                        }
                    }
                }
            }

            .previous-search {
                margin: 0.5rem 0 0;
                display: flex;
                gap: 0.4rem;
                justify-content: flex-end;

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    justify-content: flex-end;
                    flex-wrap: wrap;
                    gap: 0.4rem;
                    @include respond-to(mobile-only) {
                        flex-wrap: wrap;
                    }
                    li a {
                        display: inline-flex;
                        gap: 0.4rem;
                        border-radius: 25px;
                        padding: 0.4rem 0.8rem;
                        background-color: #eee;
                        max-width: 8rem;
                        img {
                            height: auto;
                            width: 20px;
                        }
                        p {
                            margin: 0;
                            font-weight: 500;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                        }
                        &:hover {
                            background-color: #e7e7e7;
                        }
                    }
                }
            }
        }
        .disclaimer-button {
            all: unset;
            display: none;
            @include respond-to(mobile-only) {
                display: flex;
                padding: 4px 0 0;
            }
            @include respond-to(table) {
                padding: 3px 0 0;
            }
            &.desktop-only {
                display: flex;
                @include respond-to(mobile-only) {
                    display: none;
                }
            }
            cursor: pointer;

            .disclaimer-icon {
                width: 20px;
                height: 20px;
            }
        }
    }
    .divider {
        background-color: #ddd;
        &--horizontal {
            height: 1px;
            width: 100%;
            margin: 1rem 0;
            display: none;
            @include respond-to(mobile-only) {
                display: block;
            }
        }
    }

    .teasers {
        display: flex;
        gap: 1rem 0.75rem;
        flex-direction: column;

        @include respond-to(tablet) {
            flex-direction: row;
            border: none;
            margin-top: 1rem;
        }

        .kicker {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            letter-spacing: -0.01em;
            gap: 0.5rem;
            margin: 0 0 0.3rem;
            @include respond-to(tablet) {
                margin: 0 1rem;
            }
            .breaking {
                background-color: #f6231e;
                color: #fff;
                border-radius: 4px;
                padding: 0.3rem 0.6rem;
                font-size: 1.1em;
            }
        }

        .teaser-group {
            flex-grow: 1;
            flex-basis: 0;
            flex-shrink: 0;
            &.big {
                overflow: hidden;
                flex-basis: unset;
                @include respond-to(tablet) {
                    flex-basis: 0;
                    border: 1px solid #ddd;
                    border-radius: $borderRadiusSmall;
                    padding-bottom: 1rem;
                }
                img {
                    width: 100%;
                    height: auto;
                    margin-bottom: 0.75rem;
                    border-radius: 4px;
                }
                h3 {
                    margin: 0.5rem 0 0;
                    font-size: 1.8rem;
                    font-weight: $fontHeadlineWeight;
                    letter-spacing: -0.52px;
                    font-family: $fontHeadline;
                    @include respond-to(tablet) {
                        margin: 0.5rem 1rem 0;
                    }
                }
            }
            &.list {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-top: 0;

                @include respond-to(tablet) {
                    border: 1px solid #ddd;
                    border-radius: $borderRadiusSmall;
                    padding: 0.75rem;
                }
                .teaser {
                    @include respond-to(mobile-only) {
                        &:nth-child(n + 3) {
                            display: none;
                        }
                    }
                    padding: 0.75rem 0;
                    border-top: 1px solid #ddd;
                    @include respond-to(tablet) {
                        padding: 0.75rem;
                        &:first-of-type {
                            border-top: none;
                        }
                    }

                    .kicker {
                        margin: 0 0 0.3rem;
                    }
                    h3 {
                        font-weight: 600;
                        margin: 0;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1.125em;
                        margin-block: 0;
                    }
                }
            }
        }
    }
    .isHidden {
        visibility: hidden;
    }

    #info-transparent-football {
        background-color: rgba(0, 0, 0, 0.34);
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10002;
    }

    #info-content-football {
        font-family: Inter;
        font-weight: 300;
        font-size: 16px;
        line-height: 1.2em;
        letter-spacing: 0.02em;
        background-color: #fff;
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 400px;
        max-height: fit-content;
        height: -moz-max-content;
        margin: auto;
        padding: 20px 32px;
        box-sizing: border-box;
        border-radius: $borderRadiusSmall;
        z-index: 10003;

        a {
            color: #30588e;
            line-height: 1.5em;
            font-weight: 500;
        }

        .linkText {
            border-bottom: #30588e solid 1px;
        }

        .linkIcon {
            display: inline;
            margin: 0 0 0 8px;
            cursor: pointer;

            svg {
                width: 16px;
                height: 15px;
                margin-bottom: -1px;
            }
        }

        #close {
            position: absolute;
            display: inline;
            top: 18px;
            right: 18px;
            cursor: pointer;

            figure {
                margin: 0;
                padding: 0;
            }

            svg {
                height: 16px;
                width: 16px;
            }
        }

        .header {
            font-size: 26px;
            font-weight: 600;
            line-height: 1.2em;
            padding: 0 38px 0 0;
            margin-top: 16px;
        }

        .footer {
            margin-top: 20px;
            margin-bottom: 32px;

            .schibsted-logo svg {
                display: block;
                width: 149px;
                height: 27px;
                margin: 0 auto 16px;
            }
        }

        @include respond-to(mobile-only) {
            width: 94vw;
            height: fit-content;
            max-height: 80%;
            margin: 100px auto 14px;
            padding: 18px 26px;
            overflow: scroll;

            .header {
                font-size: 24px;
                padding-right: 30px;
            }

            .linkIcon {
                margin-left: 4px;
            }
        }
    }
}
