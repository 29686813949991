body.huberto {
  .ad.ad-loaded:not(.ad-takeover)::before,
  .native-ad::before {
    background: none;

    @media #{$queryPhone} {
      width: 100%;
      padding: 0;
    }
  }

  .ad.ad-takeover.ad-loaded::before {
    background: $colorBackgroundMobile;

    @media #{$queryDesktop} {
      background: $colorBackground;
    }
  }

  .ad.ad-loaded,
  .ad.blink,
  .native-ad {
    @media #{$queryPhone} {
      margin-bottom: $gapStandard;
    }
  }

  .brandstudio-premium-content-widget {
    margin-bottom: $gapStandard;
  }

  .infeed-wrapper {
    margin-bottom: $gapStandard;
  }

  .ad {
    &:not(.ad-loaded) {
      &:before {
        display: none;
      }
    }
  }
}
