.super-section {
  background: linear-gradient($super-section-page-bg 10%, #fff 40%);
  padding: 0 $gap-regular;
}
.super-subsection {
  background-color: $super-section-page-bg;
}

@media (min-width: $tablet-width) {
  .super-section {
    background: #fff;
    padding: 0;
  }
}
