.classic-perks-container {
  background-color: rgb(238, 249, 255);
  min-height: 500px;
  margin: 32px 0;
  overflow: hidden;
  padding: 10px 0;
}

#classic-perks-content {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.classic-perks-container .pks-header {
  min-height: 80px;
  text-align: center;
  line-height: 80px;
}

.classic-perks-container .article__img-container-small {
  object-fit: cover;
}

#classic-widget-perks-frontpage {
  margin: 0 auto;
  width: 100%;
}

.pks-bt-frontpage-classic .owl-item {
  padding-right: 16px;
}

@include respond-to(mobile-only) {
  #classic-widget-perks-frontpage {
    margin: 0;
  }

  .classic-perks-container .pks-header {
    min-height: 60px;
    line-height: 21px;
    padding: 20px;
  }

  .pks-header-title {
    font-size: 18px;
  }

  #sudokuCrssComicPromo {
    order: 1;
    flex-direction: row;
    height: 160px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
}

@include respond-to(tablet-only) {
  #classic-widget-perks-frontpage {
    margin: 0;
  }
}
