.breaking-x1 {
  .article-tip-us {
    margin-bottom: 0;
  }

  .article__teaser-container {
    height: 100%;
    display: flex;
  }
  .article__img-container-container {
    flex-basis: 35%;
    height: 100%;
    .article__img-container {
      padding: 0;
      height: 100%;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .article__img-container {
    @include articleImage-ratio-16by9();
  }
  .article__text-container {
    flex-basis: calc(65% - (#{$gapStandard} * 2));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: $gapStandard;

    .live__timeline-container {
      margin: $gapStandard 0 0;
    }

    .article__labels {
      width: 100%;
      padding: 0;
    }
  }

  .article--live {
    .article__text-container {
      .article__title {
        &.article__title--long {
          @include articleTitle-h3();
        }
        &.article__title--medium {
          @include articleTitle-h2();
        }
        &.article__title--short {
          @include articleTitle-h1();
        }
      }

      margin: $gapStandard;
      &.no-margin {
        margin: 0;
      }
    }
    .article__labels {
      padding: 0;
    }
    .live__timeline-container .timeline--item {
      margin-left: -($gapStandard + 4px);
      margin-right: -($gapStandard + 4px);
      padding: 7px ($gapStandard + 4px);
    }
  }

  .article--noImage {
    .article__text-container {
      .article__title {
        @include articleTitle-h1();
      }
    }
    .article__img-container-container {
      display: none;
    }
  }

  .article:not(.article--noImage) {
    &:not(.article--major-news) {
      .article__text-container {
        .article__title {
          &.article__title--long {
            @include articleTitle-h3();
          }
          &.article__title--medium {
            @include articleTitle-h2();
          }
          &.article__title--short {
            @include articleTitle-h1();
          }
        }
      }
    }

    &.article--live:not(.article--major-news) {
      .article__img-container-container {
        flex-basis: 60%;
      }
      .article__text-container {
        flex-basis: calc(40% - (#{$gapStandard} * 2));
      }
      .article__labels {
        width: calc(
          40% - (#{$gapStandard} * 2)
        ); // To match flex width of article__text-container
      }
    }
    &.article--super-breaking:not(.article--major-news) {
      height: 100%;

      .article__img-container-container {
        flex: 5;
        flex-basis: unset;
        margin: $gapStandard 0 $gapStandard $gapStandard;
        border-right-width: 0;
        border-radius: $borderRadius;
        overflow: hidden;
        .article__img-container {
          height: 0;
          padding-bottom: 56.25%;
          img {
            height: auto;
          }
        }
      }
      .article__text-container {
        flex-basis: unset !important;
        flex: 3;
        &.no-margin {
          margin: 0;
        }
      }
    }
  }
}
