$gapSize: 12px;
$iconSize: 36px;
$background-dark: rgba(0, 0, 0, 0.05);
$text-color-light: #838181;

.notifications {
  position: relative;
  font-family: $fontSansSerif;
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  width: $grid-width-tablet;
  min-height: 70vh;
  margin: auto;
  padding-bottom: 18px;
  box-sizing: border-box;

  h1 {
    font-weight: 700;
    font-size: 22px;
    line-height: 35px;
    margin-top: 36px;
    margin-bottom: 18px;
  }

  h2 {
    font-weight: 600;
    font-size: 16px;
    line-height: 35px;
    margin-bottom: 15px;
    margin-top: 18px;
  }

  ol {
    list-style: none;
    padding: 0px;
  }

  .notification-item {
    display: flex;
    border: 1px solid $borderColor;
    border-radius: $borderRadiusSmall;
    background-color: transparent;
    box-sizing: border-box;
    margin: $gapSize 0;
    padding: $gapSize;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    width: 100%;

    &:hover {
      border-color: $darkmodeBorderColor;
      background-color: $background-dark;
    }

    .notification-icon {
      flex-shrink: 0px;
      text-align: center;
      font-weight: 500;
      line-height: $iconSize;
      height: $iconSize;
      width: $iconSize;
      margin: 0;
      border-radius: 50%;
    }

    .up-arrow {
      background: #bee6b0;
    }
    .down-arrow {
      background: #d9d9d9;
    }
    .reply-arrow {
      background: #c3e3ed;
    }

    .notification-content {
      margin-left: $gapSize;

      p {
        margin: 0;
        padding: 0;
        align-self: center;
      }

      .username {
        font-weight: 700;
      }

      .timestamp {
        display: flex;
        color: $text-color-light;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  .status-empty,
  .status-unverified {
    margin-top: 30%;
    text-align: center;
    line-height: 22px;

    img {
      margin: 0px;
      width: 62px;
      height: 62px;
    }

    p {
      margin: 10px;
    }

    button {
      background-color: #fff;
      border: $text-color-light 1px solid;
      border-radius: $borderRadiusSmall;
      padding: 12px 26px;
      margin-top: 10px;
      cursor: pointer;
    }

    button:hover {
      border-color: $darkmodeBorderColor;
      background-color: $background-dark;
      text-decoration: underline;
    }

    button:active {
      background-color: #fff;
    }
  }
}

@include respond-to(mobile-only) {
  .notifications {
    width: 100%;

    h1,
    h2 {
      margin-left: $gapSize;
      margin-top: $gapSize;
    }

    .notification-item {
      border: 0px;
      border-top: 1px solid $borderColor;
      border-radius: 0px;
      margin: 0;
      &:last-child {
        border-bottom: 1px solid $borderColor;
      }
    }
  }
}
