$shadow-color: rgb(0, 0, 0);

#podcast-overview {
  margin: $gapStandard;
  background-color: #f3f1ef;
  border-radius: $borderRadiusSmall;
  color: #333333;

  .podcast-header {
    padding: 24px 16px 24px;
    justify-content: space-between;
    display: flex;

    .icon-title {
      display: flex;
      font-family: Inter, Helvetica, Arial, sans-serif;
      font-weight: 600;
      font-size: 19px;

      .icon {
        background-image: url(https://cdn.bt.no/rnd/icon-audio.svg);
        height: 24px;
        width: 24px;
        background-repeat: no-repeat;
        flex-direction: row;
      }

      .title {
        flex-direction: row;
        padding-top: 4px;
        padding-left: 8px;
        font-size: 19px;
        margin-block: 0;
      }
    }

    .see-all {
      text-decoration: underline;
      padding-top: 4px;
      font-family: Inter, Helvetica, Arial, sans-serif;
      text-underline-offset: 5px;
    }
  }

  .podcast-items {
    margin: 16px 8px;

    ul {
      display: flex;
      padding: 0;
      margin: 0;
      flex-wrap: nowrap;
      justify-content: center;

      li {
        list-style: none;
        flex: 1 0 25%;
        text-align: center;

        img {
          width: 70px;
          border-radius: $borderRadiusSmall;
          box-shadow:
            0px 8px 16px 1px rgba(0, 0, 0, 0.08),
            0px 3px 6px -3px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
  .overview-article {
    padding: 16px 16px 24px 16px;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-size: 17px;
    line-height: 21px;
  }
}

@media screen and (min-width: 768px) {
  #podcast-overview {
    height: 400px;
    width: 388px;
    overflow: hidden;
    margin: 0;

    .podcast-items {
      margin: 16px 46px 0;

      ul {
        flex-wrap: wrap;
        li:nth-last-child(2),
        li:nth-last-child(1) {
          margin-top: 28px;
        }

        li {
          flex: 1 0 50%;

          img {
            width: 96px;
            height: 96px;
          }
        }
      }
    }

    .overview-article {
      padding: 32px 16px 24px 50px;
    }
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  #podcast-overview {
    .podcast-header {
      justify-content: flex-start;

      .icon-title,
      .see-all {
        font-size: 14px;
      }

      .see-all {
        padding-left: 8px;
      }
    }

    .podcast-items {
      margin-left: 0;
      margin-right: 26%;
    }

    .overview-article {
      padding-left: 16px;
    }

    .overview-article a {
      font-size: 14px;
    }
  }
}
