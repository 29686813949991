.benefits-section-selector {
  display: flex;

  &.variant-scroll {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;

    > :first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    > :last-child {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }

  &.variant-wrap {
    flex-wrap: wrap;

    border-radius: 12px;
    overflow: hidden;
    border: $desktop-menu-border;

    .benefits-section-selector-navigation-link {
      // Trick to get border between flex items even when it wraps
      border-top: 1px solid #ddd;
      border-left: 1px solid #ddd;
      margin-top: -1px;
      margin-left: -1px;
    }
  }

  &.elevation-medium {
    box-shadow: 0px 12px 28px 0px rgba(0, 0, 0, 0.3);
  }

  .benefits-section-selector-navigation-link {
    display: flex;
    flex: 1 1 20%;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.75rem;
    padding: 20px 16px;
    color: $desktop-menu-navigation-link-color;
    background-color: $desktop-menu-navigation-bg-color;
    white-space: nowrap;

    .menu-icon {
      width: 20px;
      height: 20px;
      color: $desktop-menu-navigation-prefix-color;
    }

    &[aria-current='page'] {
      color: $desktop-menu-navigation-active-color;
      background-color: $desktop-menu-navigation-active-bg-color;
      border-color: $desktop-menu-navigation-active-bg-color;

      .menu-icon {
        color: $desktop-menu-navigation-active-prefix-color;
      }
    }

    &:hover {
      color: $desktop-menu-navigation-hover-color;
      background-color: $desktop-menu-navigation-hover-bg-color;
      border-color: $desktop-menu-navigation-hover-bg-color;

      .menu-icon {
        color: $desktop-menu-navigation-hover-prefix-color;
      }
    }

    &:not(:last-of-type) {
      border-right: 1px solid $desktop-menu-navigation-border-color;
    }
  }
}

@media screen and (min-width: 1123px) {
  .benefits-section-selector {
    .benefits-section-selector-navigation-link {
      flex-basis: auto;
    }
  }
}
