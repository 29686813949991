#podcast-teaser {
  font-family: Inter;
  padding-bottom: 8px;
  .article__text-container {
    margin-bottom: 2px;
  }
  .podcast-teaser-image-src {
    width: 100%;
    height: 100%;
  }
  .podcast-teaser__banner-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    border-radius: 0px;
    height: 210.94px;

    .podcast-teaser__banner-image-container {
      width: 134px;
      height: 134px;
      display: block;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: auto;
      margin-right: auto;
    }
    .podcast-tease__banner-image {
      filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.16));
      border-radius: 8px;
    }
  }
  .podcast-teaser__description {
    width: 100%;
    height: 57px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    /* or 130% */
    letter-spacing: -0.01em;

    /* bt/c-dark */
    color: #333333;
    word-wrap: normal;
    z-index: 1;
  }
  .podcast-teaser__title {
    width: 100%;
    height: auto;
    font-family: $fontHeadline;

    font-size: 26px;
    font-weight: 500;
    line-height: 30px;

    letter-spacing: -1px;

    color: #333333;
  }
  .podcast-teaser__see-more {
    width: 100%;
    height: 19px;

    margin-top: 8px;
    margin-bottom: auto;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    letter-spacing: -0.01em;

    color: #333333;
  }
  .bottom-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    margin-right: 12px;

    .podcast-button {
      box-shadow: 0 0 0 2px;
      background-color: #012133;
      border-width: thin;
      border-radius: $borderRadiusSmall;
      padding: 6px;
      margin-bottom: 6px;

      .play-icon {
        width: 8px;
        height: 8px;
        background-repeat: no-repeat;
        background-position: 0 0;
        opacity: 1;
        background-size: contain;
        margin-right: 9px;
        margin-left: 9px;
        background-image: url('https://cdn.bt.no/bt/videoassets/play.svg');
      }
      .podcast-teaser-duration {
        width: 40px;
        height: 11px;
        margin-left: 32px;
        margin-right: 6px;
        color: #f2f2f2;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .podcast-bt-teaser {
    margin: 0;
    height: 400px;
    justify-content: unset;

    .item {
      padding-bottom: 16px;
    }
  }
}
