.horizontal-x1 {
  .article {
    .article__img-container-container {
      flex: 2;
    }

    .article__teaser-container {
      display: flex;
    }

    .article__img-container {
      @include articleImage-ratio-16by9();
    }

    .article__text-container {
      flex: 4;
      .article__live-container {
        .live__timeline-container {
          margin: $gapStandard $gapStandard 0 0;
        }
      }
    }

    &.article--noImage {
      flex-direction: column;
      .article__title {
        @include articleTitle-h1();
      }
      .article__img-container-container {
        display: none;
      }
    }
  }

  .article:not(.article--noImage) {
    .article__labels {
      width: calc(
        66% - #{$gapStandard}
      ); // To match flex width of article__text-container
      position: absolute;
      bottom: 0;
      left: unset;
      right: $gapStandard;
      padding: 0;
    }

    &.article--live {
      .article__img-container-container {
        flex: 6;
      }
      .article__labels {
        width: calc(
          40% - #{$gapStandard}
        ); // To match flex width of article__text-container
      }
    }
  }
}
