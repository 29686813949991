.sudoku {
  border: 1.3px solid var(--color-purple-shade-10, #9e46e6);

  .icon-container {
    background: var(--color-purple-tint-70, #e7caff);
  }

  .text-container h3 {
    color: #9e46e6;
  }
}

.minikryss {
  border: 1.3px solid var(--color-teal-shade-10, #0ac2b9);

  .icon-container {
    background: var(--color-teal-tint-70, #b6f3f0);
  }

  .text-container h3 {
    color: #09ada5;
  }
}

.kryssord {
  border: 1.3px solid var(--color-orange-shade-10, #e66c00);

  .icon-container {
    background: var(--color-orange-tint-70, #ffd7b3);
  }

  .text-container h3 {
    color: #cc6000;
  }
}

.memo {
  border: 1.3px solid var(--color-green-shade-10, #00b463);

  .icon-container {
    background: var(--color-green-tint-70, #b3efd4);
  }

  .text-container h3 {
    color: #00a058;
  }
}

.ordjakten {
  border: 1.3px solid var(--color-yellow-shade-10, #e6aa00);

  .icon-container {
    background: var(--color-yellow-tint-70, #ffebb3);
  }

  .text-container h3 {
    color: #cc9700;
  }
}

.helgekryss {
  border: 1.3px solid var(--color-blue-shade-10, #1971e6);

  .icon-container {
    background: var(--color-blue-tint-70, #bbd8ff);
  }

  .text-container h3 {
    color: #1665cc;
  }
}

.kdoku {
  border: 1.3px solid var(--color-magenta-shade-10, #d400d4);

  .icon-container {
    background: var(--color-magenta-tint-70, #f9b3f9);
  }

  .text-container h3 {
    color: #bc00bc;
  }
}
