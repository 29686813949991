body:not(.section-Kultur, .section-Meninger, .section-Sport) {
  // Defaults
  --ch-subsection-li-bg-color: var(--sectionDefaultsLinksBg);
  --ch-subsection-li-text-color: var(--sectionDefaultsLinks);
  --ch-section-bg-color: var(--sectionDefaultsBg);
  --ch-subsection-label-text-color: var(--sectionDefaultsLabel);
  --ch-subsection-li-bg-color--active: var(--sectionDefaultsLinksBgHover);
}

.ch-core-header {
  .ch-header-sub.section-visible {
    background-color: $sectionBg;
  }

  .hasSubheader {
    .page-main-container {
      padding-top: 0;
      .page-title {
        padding-top: 0;
      }
    }
  }

  // kultur
  .section-Kultur {
    --ch-subsection-li-bg-color: var(--sectionCultureLinksBg);
    --ch-subsection-li-text-color: var(--sectionCultureLinks);
    --ch-section-bg-color: var(--sectionCultureBg);
    --ch-subsection-label-text-color: var(--sectionCultureLabel);
    --ch-subsection-li-bg-color--active: var(--sectionCultureLinksBgHover);
  }

  // meninger
  .section-Meninger {
    --ch-subsection-li-bg-color: var(--sectionOpinionLinksBg);
    --ch-subsection-li-text-color: var(--sectionOpinionLinks);
    --ch-section-bg-color: var(--sectionOpinionBg);
    --ch-subsection-label-text-color: var(--sectionOpinionLabel);
    --ch-subsection-li-bg-color--active: var(--sectionOpinionLinksBgHover);
  }

  // Sport
  .section-Sport {
    --ch-subsection-li-bg-color: var(--sectionSportLinksBg);
    --ch-subsection-li-text-color: var(--sectionSportLinks);
    --ch-section-bg-color: var(--sectionSportBg);
    --ch-subsection-label-text-color: var(--sectionSportLabel);
    --ch-subsection-li-bg-color--active: var(--sectionSportLinksBgHover);
  }
}

.page-header {
  font-family: var(--sections-font-family);
  background: $sectionBg;

  .page-description {
    color: $sectionText;
    font-size: var(--sections-description-font-size, 17px);
  }
}

.page-title {
  color: $sectionTitleTextColor;
  padding-top: 32px;
  font-weight: var(--sections-title-font-weight, 100);
}

.page-timestamp {
  color: $sectionTimestamp;
  font-size: 13px;
  font-family: Inter, sans-serif;
}

// Meninger submenu
.page-header.section-Meninger {
  background-color: $sectionOpinionBg;

  .page-title {
    padding-top: unset;
    color: $sectionOpinionTitle;
  }
  .page-description {
    color: $sectionOpinionText;
  }
  .page-timestamp {
    color: $sectionOpinionTimestamp;
  }
  .ch-section-label {
    color: $sectionOpinionLabel;
  }
}

// Kultur submenu
.page-header.section-Kultur {
  background-color: $sectionCultureBg;
  .page-title {
    padding-top: unset;
    color: $sectionCultureTitle;
  }
  .page-description {
    color: $sectionCultureText;
  }
  .page-timestamp {
    color: $sectionCultureTimestamp;
  }
  .ch-section-label {
    color: $sectionCultureLabel;
  }
}

// Sport submenu
.page-header.section-Sport {
  background-color: $sectionSportBg;
  .page-title {
    padding-top: unset;
    color: $sectionSportTitle;
  }
  .page-description {
    color: $sectionSportText;
  }
  .page-timestamp {
    color: $sectionSportTimestamp;
  }
  .ch-section-label {
    color: $sectionSportLabel;
  }
}

@media (min-width: $tablet-width) {
  .page-header {
    padding-top: 48px;
    .page-main-container {
      padding-top: 0;
      .page-title {
        padding-top: 0;
      }
      .page-description {
        font-size: var(--sections-description-font-size, 17px);
      }
    }
  }

  .hasSubheader {
    .page-main-container {
      padding-top: 56px;
    }
  }
}
