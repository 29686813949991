.top-60-40-block {
  .column .article:not(:last-child) {
    margin-bottom: unset;
  }
  .column-60 {
    flex-basis: 60%;
    .bundle-block {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: $borderRadius;
      .article {
        border: none;
        flex: 1;

        .article__kicker-title {
          font-weight: 600;
          margin-bottom: $gapSmall * 0.5;
        }
      }
      .article--large {
        .article__kicker-title {
          font-size: 16px;
          margin-bottom: $gapSmall;
        }
        .article__section-kicker-container {
          .article__section {
            font-size: 16px;
          }
        }
      }
      .small-bundle-row {
        .article__img-container {
          display: none;
        }

        .article:last-child {
          .article__labels {
            padding: 0 $gapSmall $gapSmall;
          }
        }
      }
    }
    .article--large {
      .article__title--long {
        @include articleTitle-h2();
      }
    }
  }

  .column-40 {
    flex-basis: 40%;
    .article__imageOrAuthor-container {
      .article__img-container {
        display: block;
      }
      .author__image-container {
        display: none;
      }
    }
    .article__text-container {
      .author__image-container {
        display: block;
      }
    }
  }

  .column-60,
  .column-40 {
    .article {
      height: 100%;
    }
  }
}
