.collage-x4 {
  .article {
    &.article--noImage .article__labels {
      left: 0;
    }
  }

  .column--big {
    flex: 1;
    margin-right: $gapStandard;

    .article__img-container {
      width: 100%;
    }

    .article--live:not(.article--breaking) {
      .article__text-container {
        .article__section::before {
          margin-top: -1px;
        }
      }
    }

    .article--large {
      .article__title--short {
        @include articleTitle-h2();
      }
    }

    .article--live {
      .live__timeline-container {
        padding-bottom: $gapSmall;
      }
    }

    .article--grade-1,
    .article--grade-2,
    .article--grade-3,
    .article--grade-4,
    .article--grade-5,
    .article--grade-6 {
      .article__text-container {
        font-size: 14px;
      }
    }
  }
  .column--small {
    flex: 1;

    .article--small {
      a {
        flex-direction: row-reverse;
      }
      .article__img-container {
        float: right;
        margin: 0 0 $gapMedium $gapMedium;
        height: 102px;
        width: 180px;

        img {
          border-radius: $borderRadius;
        }
      }
      .author__image-container {
        width: 74px;
        height: 74px;
      }

      .article__title {
        margin-bottom: 0;
      }

      &.article--bt-inviterer {
        .article__img-container {
          padding-bottom: 0;
        }
      }
    }

    .article__imageOrPodcast-container {
      padding: $gapStandard $gapStandard 0 0;
    }
  }

  // Highlighted comments tweak
  .bundle-block
    .article--questions
    .highlighted-comments-container
    .highlighted-comments-outer {
    height: 70%;
  }
}
