* {
  border-color: $darkmodeBorderColor;
}

&,
.content-outer,
.articles-container {
  background: $darkmodeBackgroundColor;
}

.article {
  background: $darkmodeBackgroundColor;
  color: $darkmodeTextColor;

  .article__labels {
    .label--paywall {
      color: $darkmodeTextColor;
    }
  }

  .label--comments {
    .counter-container {
      color: $darkmodeTextColor;
    }
  }

  .article__kicker-title {
    color: $darkmodeTextColor;
  }

  &.article-section--btmagasinet {
    .article__text-container {
      &::before {
        background-image: url('https://cdn.bt.no/bt/btmagasinet-smaller-white.svg');
      }
    }
  }

  &.article--breaking {
    .article__section {
      color: $darkskinBreakingLabel;

      &::before {
        background: $darkskinBreakingLabel;
      }
    }
  }

  &.article--live {
    .article__section {
      color: $darkmodeLiveColor;

      &::before {
        background: $darkmodeLiveColor;
      }
    }

    .live__timeline-container {
      .timeline--date {
        color: $darkmodeLiveColor;

        &::before {
          background: $darkmodeLiveColor;
        }
      }
    }
  }
}

.ch-core-header .ch-header-main {
  background: $darkskinBackgroundColor;
}

footer {
  background: $darkmodeBackgroundColor;
}

#archivePromo {
  background: $darkmodeBackgroundColor;

  #covers ul li p,
  p.archive-teaser {
    color: $darkmodeTextColor;
  }
}

.classic-perks-container,
.perks-container {
  &,
  .pks-bt-frontpage-classic,
  .pks-indent,
  .pks-indent::before,
  .pks-bt-frontpage-classic .pks-products .pks-product-classic .pks-indent,
  .pks-bt-frontpage-classic
    .pks-products
    .pks-product-classic
    .pks-indent::before {
    background: $darkmodeBackgroundColor;
  }
}
