.article-tip-us {
  font-family: $fontSansSerif;
  background-color: var(--tipus-bg-color);
  color: var(--tipus-text-color);
  font-size: 15px;
  padding: 12px 16px;
  margin: 12px;
  border-radius: 4px;
  &.no-side-margin {
    margin-left: 0;
    margin-right: 0;
  }

  p {
    margin: 0;
    font-weight: 600;
  }
}

.tip-us-underline {
  color: inherit;
  font-weight: 700;
  text-decoration: underline;
}

.bundle-block:not(.bundle-major-news) {
  & > .article:has(.article-tip-us):not(.article--major-news) {
    border-radius: 0;
    margin-bottom: 0;
    & ~ .article-divider {
      display: none;
    }
  }
  .subteasers :last-child {
    &:has(.article-tip-us) {
      padding-bottom: 0;
    }
  }
}
.article--medium {
  .article-tip-us {
    padding: 12px 10px;
  }
}

[class*='bundle']:not(.column--bundle, .bundle-block) {
  .article--large:has(.article-tip-us) {
    border-radius: 0;
    .article-tip-us {
      @include respond-to(tablet) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  &.mobile .subteasers .article:has(.article-tip-us) {
    padding-bottom: 0;
  }
}

.horizontal-x1 {
  .article:has(.article-tip-us):not(.article--noImage) {
    .article__img-container-container {
      flex: 1;
    }
    .article__text-container {
      flex: 2;
      margin-bottom: 67px;
    }

    a:has(.article-tip-us) {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 67.4%;
    }
  }
}
