.collage-x3 {
  .column--big {
    flex: 2;

    .article--large {
      .article__img-container {
        width: 100%;
      }
    }
    .article__text-container {
      .article__title--long {
        @include articleTitle-h1();
      }
      .article__title--medium {
        @include articleTitle-h1();
      }
      .article__title--short {
        @include articleTitle-h1();
      }
    }

    .article--live {
      .live__timeline-container {
        padding-bottom: $gapSmall;
      }
    }

    &.column--bundle {
      .article--large {
        .article__img-container {
          height: 0;
          padding-bottom: 56.25%;

          img {
            height: auto;
          }
        }
      }
      .bundle-x3 {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-style: solid;
        border-width: 1px;

        .article {
          border: none;

          .article__kicker-title {
            margin-bottom: 4px;
          }
        }
        .article--large {
          .article__kicker-title {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  .column--small {
    flex: 1;

    .article {
      .article__title--short,
      .article__title--medium {
        @include articleTitle-h3();
      }
    }

    .article {
      .article__title--long {
        @include articleTitle-h4();
      }
    }

    .article.article--noImage:not(.article--live) {
      .article__title {
        @include articleTitle-h2();
      }
    }

    .article__img-container {
      height: 0;
      padding-bottom: 56.25%;

      img {
        height: auto;
      }
    }
  }
}
