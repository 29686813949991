@font-face {
  font-family: Inter;
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: Regular;
  src: url('#{$cdnHost}/rnd/fonts/Inter-roman.var.subset.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'TiemposHeadlineMedium';
  src: url('#{$cdnHost}/rnd/fonts/TiemposHeadlineWeb-Medium.woff2')
    format('woff2');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'TiemposTextRegular';
  src: url('#{$cdnHost}/rnd/fonts/TiemposTextWeb-Regular.woff2') format('woff');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

// font metrics
@import 'font-metrics';
