.lf-widget {
  width: 100%;
  font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial;
  border-radius: 4px;
  border: 1px solid #dddddd;
  margin-bottom: 50px;

  &-title {
    margin: 0;
    padding: 10px 16px;
    background-color: #f6f6f6;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    font-weight: 700;

    &__padding {
      padding: 10px 8px;

      @include bigger-than(mobile) {
        padding: 10px 16px;
      }
    }
  }

  .lf-table {
    padding: 8px 0px;
    width: 100%;
    border-spacing: 0;

    &-row {
      &__head {
        font-weight: 700;
        margin-top: 8px;
      }

      &__body {
        margin: 2px 0;

        &:nth-child(odd) {
          background-color: #fafafa;
        }
      }

      &__hidden {
        display: none;
      }

      &-item {
        padding: 4px 5px 4px 0;
        font-size: 0.8em;
        min-width: 12px;

        &:first-child {
          padding-left: 5px;
        }

        &:last-child {
          padding-right: 10px;

          &.regular {
            padding-right: 5px;

            @include bigger-than(mobile) {
              padding-right: 10px;
            }
          }
        }

        &__date {
          @include bigger-than(mobile) {
            padding-left: 10px !important;
          }
        }

        &__number {
          text-align: right;
          width: 14px;
        }

        &__minimize {
          width: 1%;
          white-space: nowrap;
        }

        &__rank {
          min-width: 16px;
        }

        &__diff {
          min-width: 24px;
          white-space: nowrap;
        }

        &__goals {
          width: 16px;
          text-align: right;
          white-space: nowrap;
        }

        &__name {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 8px;
          font-weight: 700;
        }

        &__place {
          padding-left: 0;
          width: fit-content;
        }

        &-logo {
          height: 20px;
          width: 20px;
        }

        &__name {
          font-weight: 400;
        }

        &__res {
          position: relative;

          &-text {
            position: absolute;
            bottom: 4px;
            right: 5px;

            @include bigger-than(mobile) {
              right: 10px;
            }
          }
        }

        &__small {
          font-size: 0.8em;
          min-width: 50px;
        }
      }
    }

    &-row {
      &:nth-child(even) {
        background-color: rgb(248, 248, 248);
      }
    }

    .text-right {
      text-align: right;
    }
  }

  &-btn {
    background: #f2f1ef;
    color: $text-color;
    border: 1px solid #dddddd;
    border-radius: $borderRadiusSmall;
    padding: 0.5rem;
    font-family: inherit;
    cursor: pointer;
    width: calc(100% - 16px);
    margin: 0 8px 8px;
  }
}
