/* Webfont: TabacG2 */
@font-face {
  font-family: 'TabacG2';
  src: url('#{$cdnHost}/rnd/fonts/TabacG2.eot'); /* IE9 Compat Modes */
  src:
    url('#{$cdnHost}/rnd/fonts/TabacG2.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$cdnHost}/rnd/fonts/TabacG2.woff2') format('woff2'),
    /* Modern Browsers */ url('#{$cdnHost}/rnd/fonts/TabacG2.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$cdnHost}/rnd/fonts/TabacG2.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: TabacG2Medium */
@font-face {
  font-family: 'TabacG2';
  src: url('#{$cdnHost}/rnd/fonts/TabacG2Medium.eot'); /* IE9 Compat Modes */
  src:
    url('#{$cdnHost}/rnd/fonts/TabacG2Medium.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$cdnHost}/rnd/fonts/TabacG2Medium.woff2')
      format('woff2'),
    /* Modern Browsers */ url('#{$cdnHost}/rnd/fonts/TabacG2Medium.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$cdnHost}/rnd/fonts/TabacG2Medium.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}

/* Webfont: TabacG2Bold */
@font-face {
  font-family: 'TabacG2';
  src: url('#{$cdnHost}/rnd/fonts/TabacG2Bold.eot'); /* IE9 Compat Modes */
  src:
    url('#{$cdnHost}/rnd/fonts/TabacG2Bold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$cdnHost}/rnd/fonts/TabacG2Bold.woff2') format('woff2'),
    /* Modern Browsers */ url('#{$cdnHost}/rnd/fonts/TabacG2Bold.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$cdnHost}/rnd/fonts/TabacG2Bold.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

/* Webfont: TabacG2 Semi bold italic */
@font-face {
  font-family: 'TabacG2';
  src: url('#{$cdnHost}/rnd/fonts/TabacG2SemiBold-Italic.eot'); /* IE9 Compat Modes */
  src:
    url('#{$cdnHost}/rnd/fonts/TabacG2SemiBold-Italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$cdnHost}/rnd/fonts/TabacG2SemiBold-Italic.woff2')
      format('woff2'),
    /* Modern Browsers */
      url('#{$cdnHost}/rnd/fonts/TabacG2SemiBold-Italic.woff') format('woff'),
    /* Modern Browsers */
      url('#{$cdnHost}/rnd/fonts/TabacG2SemiBold-Italic.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: italic;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

/* Webfont: TabacG2Medium Italic */
@font-face {
  font-family: 'TabacG2';
  src: url('#{$cdnHost}/rnd/fonts/TabacG2Medium-Italic.eot'); /* IE9 Compat Modes */
  src:
    url('#{$cdnHost}/rnd/fonts/TabacG2Medium-Italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('#{$cdnHost}/rnd/fonts/TabacG2Medium-Italic.woff2')
      format('woff2'),
    /* Modern Browsers */ url('#{$cdnHost}/rnd/fonts/TabacG2Medium-Italic.woff')
      format('woff'),
    /* Modern Browsers */ url('#{$cdnHost}/rnd/fonts/TabacG2Medium-Italic.ttf')
      format('truetype'); /* Safari, Android, iOS */
  font-style: italic;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}
