:root {
  --white-base: #fff;
  --black-base: #000;
  --blue-base: #1c7eff;
  --blue-shade-10: #1971e6;
  --blue-shade-70: #08264d;
  --teal-base: #0bd8ce;
  --teal-shade-10: #0ac2b9;
  --teal-shade-70: #03413e;
  --magenta-base: #eb00eb;
  --magenta-shade-20: #bc00bc;
  --magenta-shade-80: #2f002f;
  --red-base: #f62924;
  --red-shade-10: #dd2520;
  --red-shade-70: #4a0c0b;
  --sections-title-font-weight: 500;
  --sections-description-font-size: 18px;
  --sections-font-family: TabacG2, Georgia, 'Times New Roman', Times, serif;

  --sectionDefaultsBg: var(--white-base);
  --sectionDefaultsTitle: var(--blue-shade-70);
  --sectionDefaultsText: var(--black-base);
  --sectionDefaultsTimestamp: var(--black-base);
  --sectionDefaultsLinks: var(--white-base);
  --sectionDefaultsLabel: var(--black-base);
  --sectionDefaultsLinksBg: var(--blue-shade-10);
  --sectionDefaultsLinksBgHover: var(--blue-base);

  --sectionOpinionBg: var(--white-base);
  --sectionOpinionTitle: var(--teal-shade-70);
  --sectionOpinionText: var(--black-base);
  --sectionOpinionTimestamp: var(--black-base);
  --sectionOpinionLinks: var(--white-base);
  --sectionOpinionLabel: var(--black-base);
  --sectionOpinionLinksBg: var(--teal-shade-10);
  --sectionOpinionLinksBgHover: var(--teal-base);

  --sectionCultureBg: var(--white-base);
  --sectionCultureTitle: var(--magenta-shade-80);
  --sectionCultureText: var(--black-base);
  --sectionCultureTimestamp: var(--black-base);
  --sectionCultureLinks: var(--white-base);
  --sectionCultureLabel: var(--black-base);
  --sectionCultureLinksBg: var(--magenta-shade-20);
  --sectionCultureLinksBgHover: var(--magenta-base);

  --sectionSportBg: var(--white-base);
  --sectionSportTitle: var(--red-shade-70);
  --sectionSportText: var(--black-base);
  --sectionSportTimestamp: var(--black-base);
  --sectionSportLinks: var(--white-base);
  --sectionSportLabel: var(--black-base);
  --sectionSportLinksBg: var(--red-shade-10);
  --sectionSportLinksBgHover: var(--red-base);
}

// BT colors
$color-white: rgb(255, 255, 255);
$color-lightGrey: rgb(225, 225, 225);
$color-mediumGrey: rgb(187, 189, 192);
$color-darkGrey: rgb(50, 50, 50);
$color-grey: rgb(110, 110, 110);
$color-blue: rgb(1, 71, 109);
$color-black: rgb(0, 0, 0);
$red-red-base: #f62924;
$color-green: #09ada5;
$color-magenta: #bc00bc;
$base-black-color: #000;
$byline-bg-green: #6de8e2;
$byline-bg-magenta: #f580f5;
$byline-bg-red: #f96966;
$red-tint-80: #fdd4d3;
$black-base: $color-black;
$grey-tint-60: #cfcecd;
$grey-tint-80: #eae9e8;
$grey-tint-90: #f2f1f0;
$blue-shade-90: #030d19;

$colorBrand: #01476d;

$colorLive: $red-red-base;
$colorLiveLine: transparent;
$colorBackgroundPage: $color-white;
$colorBackgroundArticle: $color-white;
$colorBackgroundMobile: $color-white;
$colorText: $color-black;
$defaultColorText: $color-black;
$colorHeadline: $color-black;
$colorArticleLabels: $color-black;
$borderColor: #ddd;
$borderColorBreaking: $red-red-base;
$borderColorBreakingHover: #bbb;
$adBackgroundColor: #f3f1ef;
$adLabelColor: #666;

$darkskinBackgroundColor: $blue-shade-90;
$darkskinTextColor: $color-white;
$darkskinLiveColor: $red-red-base;
$darkskinBreakingLine: transparent;
$darkskinBreakingLabel: $red-red-base;
$darkskinDividerColor: #666;
$darkSkinHoverBackground: $blue-shade-90;
$darkskinBorderColor: $blue-shade-90;

$darkmodeBorderColor: $red-red-base;
$darkmodeBackgroundColor: #161616;
$darkmodeTextColor: #d9d9d9;
$darkmodeLiveColor: $red-red-base;

$colorBackgroundNoImage: #fff;

$colorBackgroundFooter: #161616;
$colorTextFooter: #f2f2f2;
$colorBorderFooter: #d9d9d9;
$logoFooter: 'bt-monogram-white.svg';

$colorTextPageTag: #6d767e;
$colorTextPageTagBackground: rgb(243, 241, 239);

// Sectionpages
$sectionText: var(--sectionDefaultsText);
$sectionBg: var(--sectionDefaultsBg);
$sectionTimestamp: var(--sectionDefaultsTimestamp);
$sectionTitleTextColor: var(--sectionDefaultsTitle);

$sectionOpinionBg: var(--sectionOpinionBg);
$sectionOpinionTitle: var(--sectionOpinionTitle);
$sectionOpinionText: var(--sectionOpinionText);
$sectionOpinionTimestamp: var(--sectionOpinionTimestamp);
$sectionOpinionLinks: var(--sectionOpinionLinks);
$sectionOpinionLabel: var(--sectionOpinionLabel);

$sectionCultureBg: var(--sectionCultureBg);
$sectionCultureTitle: var(--sectionCultureTitle);
$sectionCultureText: var(--sectionCultureText);
$sectionCultureTimestamp: var(--sectionCultureTimestamp);
$sectionCultureLinks: var(--sectionCultureLinks);
$sectionCultureLabel: var(--sectionCultureLabel);

$sectionSportBg: var(--sectionSportBg);
$sectionSportTitle: var(--sectionSportTitle);
$sectionSportText: var(--sectionSportText);
$sectionSportTimestamp: var(--sectionSportTimestamp);
$sectionSportLinks: var(--sectionSportLinks);
$sectionSportLabel: var(--sectionSportLabel);

$page-top-gap: 14px;

// font style
$fontBaseSize: 16px;
$fontBaseSizeTablet: 13px;

// we use variable width Inter that has all the font weights
$fontSerifFallbacks: Georgia, 'Times New Roman', Times, serif;
$fontSansSerif: 'Inter', Helvetica, Arial, sans-serif;
$fontSerif: 'TabacG2', $fontSerifFallbacks;

$fontHeadline: $fontSerif;
$fontPremium: $fontSerif;
$fontHeadlineWeight: 500;
$fontMajorNewsWeight: 700;

// other
$ad-annonse-marker-height: 13px;

// sections
$grid-width-tablet: 768px;
$grid-width-desktop: 980px;

// magasinet transitions
$transition-duration-default: 0.3s;
$transition-function-default: ease-in-out;

// magasinet header
$header-height: 60px;

// next page link from core
$nextPageLinkBackgroundColor: $colorBrand;
$nextPageLinkColor: $darkskinTextColor;

$cdnHost: 'https://cdn.bt.no';

$borderRadius: 10px;
$borderRadiusSmall: 6px;
$borderRadiusTiny: 4px;
$gridBase: 6px;
$gapStandard: $gridBase * 2;

// Frontpage kicker size and colors
$kicker-desktop-size: 0.9375rem; // 15px
$kicker-mobile-size: 0.8125rem; // 13px
$kicker-desktop-lh: 1.21em;
$kicker-gap: 6px;
$kicker-margin: 8px;
$breakingLabelMargin: $kicker-margin;
$live-label-icon-size: 10px;

@media (min-width: $grid-width-tablet) {
  :root {
    --sections-description-font-size: 20px;
  }
}
