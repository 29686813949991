.vertical-static-ad {
  @media (max-width: 700px) {
    background-color: $adBackgroundColor;
    padding: $gapStandard;
    margin-left: -($gapStandard * 2);
    margin-right: -($gapStandard * 2);
    .column--medium {
      display: none;
    }
    .column--big {
      margin: 0 auto;
    }
  }
}

.row.vertical-static-ad.vertical-x1-ad .column:last-child {
  margin-right: auto;
}
