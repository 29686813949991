// -------------------------------------------
// TRANSITION MIXIN
// -------------------------------------------
@mixin transition($args...) {
  $list: null;
  $i: 0;
  @each $property in $args {
    @if $i > 0 {
      $list: append($list, unquote(','));
    }
    $list: append(
      $list,
      $property $transition-function-default $transition-duration-default
    );
    $i: $i + 1;
  }
  transition: $list;
}

// -------------------------------------------
// BOX-SIZING MIXIN
// -------------------------------------------
@mixin box-sizing($box-sizing) {
  -webkit-box-sizing: $box-sizing;
  -moz-box-sizing: $box-sizing;
  box-sizing: $box-sizing;
}

// ------------------------------------
// CONTROL AMOUNT OF TEXT DISPLAYED
// ------------------------------------
@mixin text-overflow($fontSize, $lineHeight, $lines) {
  font-size: $fontSize;
  line-height: $lineHeight;
  height: $lineHeight * $lines;
  overflow: hidden;

  @if $lines == 1 {
    text-overflow: ellipsis;
    white-space: nowrap;
  } @else {
    text-overflow: inherit;
    white-space: normal;
  }
}

@mixin articleTitleMedium {
  &.article__title--medium {
    @content;
  }
}

@mixin articleTitle-majorNews-large() {
  @include articleTitle(
    nth($h1-major-news-metrics, 1),
    nth($h1-major-news-metrics, 2),
    nth($h1-major-news-metrics, 3)
  );
}

@mixin articleTitle-majorNews-medium() {
  @include articleTitle(
    nth($h2-major-news-metrics, 1),
    nth($h2-major-news-metrics, 2),
    nth($h2-major-news-metrics, 3)
  );
}

@mixin articleTitle-majorNews-small() {
  @include articleTitle(
    nth($h3-major-news-metrics, 1),
    nth($h3-major-news-metrics, 2),
    nth($h3-major-news-metrics, 3)
  );
}

@mixin articleTitle-majorNews-subteaser-medium() {
  @include articleTitle(
    nth($h4-major-news-metrics, 1),
    nth($h4-major-news-metrics, 2),
    nth($h4-major-news-metrics, 3)
  );
}

@mixin articleTitle-majorNews-subteaser-small() {
  @include articleTitle(
    nth($h5-major-news-metrics, 1),
    nth($h5-major-news-metrics, 2),
    nth($h5-major-news-metrics, 3)
  );
}

@mixin articleTitle-majorNews-mobile-large() {
  @include articleTitle(
    nth($h6-major-news-metrics, 1),
    nth($h6-major-news-metrics, 2),
    nth($h6-major-news-metrics, 3)
  );
}

@mixin articleTitle-majorNews-mobile-medium() {
  @include articleTitle(
    nth($h7-major-news-metrics, 1),
    nth($h7-major-news-metrics, 2),
    nth($h7-major-news-metrics, 3)
  );
}

@mixin articleTitle-majorNews-mobile-small() {
  @include articleTitle(
    nth($h8-major-news-metrics, 1),
    nth($h8-major-news-metrics, 2),
    nth($h8-major-news-metrics, 3)
  );
}

@mixin articleTitle-majorNews-mobile-xsmall() {
  @include articleTitle(
    nth($h9-major-news-metrics, 1),
    nth($h9-major-news-metrics, 2),
    nth($h9-major-news-metrics, 3)
  );
}

@mixin articleTitle-bundle-xsmall() {
  @include articleTitle(
    nth($bundle-xsmall-metrics, 1),
    nth($bundle-xsmall-metrics, 2),
    nth($bundle-xsmall-metrics, 3)
  );
}
@mixin articleTitle-bundle-small() {
  @include articleTitle(
    nth($bundle-small-metrics, 1),
    nth($bundle-small-metrics, 2),
    nth($bundle-small-metrics, 3)
  );
}
@mixin articleTitle-bundle-medium() {
  @include articleTitle(
    nth($bundle-medium-metrics, 1),
    nth($bundle-medium-metrics, 2),
    nth($bundle-medium-metrics, 3)
  );
}
@mixin articleTitle-bundle-large() {
  @include articleTitle(
    nth($bundle-large-metrics, 1),
    nth($bundle-large-metrics, 2),
    nth($bundle-large-metrics, 3)
  );
}

@mixin articleTitle-kicker-label() {
  @include articleTitle(
    nth($kicker-label-metrics, 1),
    nth($kicker-label-metrics, 2),
    nth($kicker-label-metrics, 3)
  );
}
@mixin articleTitle-kicker-label-big() {
  @include articleTitle(
    nth($kicker-label-big-metrics, 1),
    nth($kicker-label-big-metrics, 2),
    nth($kicker-label-big-metrics, 3)
  );
}

@mixin articleTitle-kicker-label-mobile() {
  @include articleTitle(
    nth($kicker-label-metrics-mobile, 1),
    nth($kicker-label-metrics-mobile, 2),
    nth($kicker-label-metrics-mobile, 3)
  );
}

@mixin articleTitle-kicker-label-mobile-bundle() {
  @include articleTitle(
    nth($kicker-label-metrics-mobile-bundle, 1),
    nth($kicker-label-metrics-mobile-bundle, 2),
    nth($kicker-label-metrics-mobile-bundle, 3)
  );
}

@mixin articleTitle-section-label() {
  @include articleTitle(
    nth($section-label-metrics, 1),
    nth($section-label-metrics, 2),
    nth($section-label-metrics, 3)
  );
}

@mixin articleTitle-section-label-big() {
  @include articleTitle(
    nth($section-label-big-metrics, 1),
    nth($section-label-big-metrics, 2),
    nth($section-label-big-metrics, 3)
  );
}

@mixin articleTitle-section-label-mobile() {
  @include articleTitle(
    nth($section-label-metrics-mobile, 1),
    nth($section-label-metrics-mobile, 2),
    nth($section-label-metrics-mobile, 3)
  );
}

@mixin articleTitle-paywall-label() {
  @include articleTitle(
    nth($paywall-label-metrics, 1),
    nth($paywall-label-metrics, 2),
    nth($paywall-label-metrics, 3)
  );
}

@mixin articleImage-ratio-16by9() {
  padding: 0 0 56.25% 0;
  height: 0;

  img {
    height: auto;
  }
}

@mixin articleImage-ratio-3by2() {
  padding: 0 0 66.67% 0;
  height: 0;

  img {
    height: auto;
  }
}
