.row.vertical-x2 {
  .article {
    .article__imageOrAuthor-container {
      .article__img-container {
        display: block;
      }
      .author__image-container {
        display: none;
      }
    }
    .article__text-container {
      .author__image-container {
        display: block;
      }
    }
    .article__title {
      @include articleTitle-h2();
    }
  }
}
