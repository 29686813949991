@import 'variables';
@font-face {
  font-family: Inter;
  font-weight: 100 900;
  font-style: normal;
  font-named-instance: Regular;
  src: url('#{$cdnHost}/rnd/fonts/Inter.var.regional.v2.woff2') format('woff2');
  font-display: swap;
}
@import 'mixins';
@import 'header';
@import 'articles';
@import 'widgets';
@import 'search';
@import '../widgets/user-feedback';
// Resets
section {
  margin: initial;
  font-family: Inter;
}

.lf {
  &-view {
    &__desktop {
      display: none !important;

      @include bigger-than(large) {
        display: flex !important;
      }
    }

    &__mobile {
      @include bigger-than(large) {
        display: none !important;
      }
    }
  }

  &-logo {
    height: 40px;
    width: auto;
    fill: #0f055e;
  }

  &-container {
    padding: 45px 16px;
    display: flex;
    flex-direction: column;

    @include bigger-than(large) {
      max-width: 1300px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      grid-template-rows: repeat(5, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
    }

    &-main {
      grid-area: 1 / 3 / 6 / 7;

      @include bigger-than(large) {
        order: initial;
        margin-right: $gap;
        margin-left: $gap;
      }

      .lf-feedback {
        margin: 2rem 0 50px;

        .feedback-form {
          flex-direction: column;
        }
      }
    }

    &-widgets {
      grid-area: 1 / 7 / 6 / 10;
    }

    &-nav {
      grid-area: 1 / 1 / 6 / 3;
      display: flex;
      gap: 8px;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 50px;

      @include bigger-than(large) {
        flex-direction: column;
      }

      &-item {
        color: $text-color;
        border: 1px solid #ddd;
        border-radius: $borderRadiusSmall;
        background-color: $color-btn;
        display: none;
        gap: 8px;
        font-size: 1em;
        align-items: center;
        font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial;
        cursor: pointer;
        padding: 12px;
        text-align: left;

        &-icon {
          height: 22px;
          width: 22px;
        }
        &:hover {
          background-color: $color-btn-hover;
        }
      }
    }

    .lf-section {
      border-radius: 4px;
      border: 1px solid #dddddd;
      padding: 16px;
      margin-bottom: 50px;
      font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial;

      &-title {
        font-family: inherit;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        gap: 8px;
        margin: 0;

        &-icon {
          height: 34px;
          width: 34px;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      &-body {
        margin: 8px 0 0;
      }

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        font-family: inherit;
      }
      &-ad {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f2f1ef;
        padding: 14px;
        margin: 16px -16px -16px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        &-text {
          margin: 0;
          font-size: 0.9em;
          font-family: inherit;

          color: #999090;
        }
        .lf-ad-logo {
          height: 18px;
          margin-top: 3px;
          width: auto;
          fill: #00234f;
        }
      }
    }
  }
  &-next-page {
    grid-area: 6 / 1 / 6 / 9;
  }
}
