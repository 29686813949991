.article {
  $labelHeight: 28px;

  .article__labels {
    position: relative;
    padding: 0 $gapStandard;
    font-family: $fontSansSerif;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: end;
    @include articleTitle-paywall-label();

    .label--paywall {
      text-transform: uppercase;
      line-height: $labelHeight;
      display: inline-block;

      &.open {
        display: none;
      }
    }

    .label--comments {
      height: auto;
      float: unset;
      display: flex;
      flex: 1;
      flex-direction: row-reverse;

      .counter-wrapper {
        display: flex;
        height: $labelHeight;
        align-self: flex-end;
        flex-direction: row-reverse;
        .widget-comment-counter {
          display: flex;
          flex-direction: row-reverse;

          div.counter-container {
            // TODO: all other font sizes and line-heights are rem/em
            // need to tweak the size of the comments bubble for this to work
            //font-size: 10px;
            line-height: 17px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0px;
          }

          div.counter-icon {
            width: 25px;
            height: 20px;
            background-image: url('#{$cdnHost}/rnd/comments-bubble.svg');
            background-size: contain;
            background-repeat: no-repeat;
          }
          &.wide {
            div.counter-icon {
              width: 30px;
              background-image: url('#{$cdnHost}/rnd/comments-bubble-wide.svg');
            }
          }
        }
      }
    }
    body.hide-comment-labels & {
      display: none;
    }
  }
}

.article.article--noImage .article__labels {
  left: 0;
  right: 0;
}

.article.article--dark .article__labels,
.article.article--dark a .article__labels {
  left: 0;
  right: 0;
}

.article.article--large {
  .article__labels {
    padding: 0 ($gapStandard + 4px);
  }
}

.horizontal-x1 .article.article--noImage {
  .article__labels {
    padding: 0;
  }
}
