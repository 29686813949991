$color-feedback-neutral: #00878a !default;
$color-icon-background: #e0f6f6 !default;
$tablet-breakpoint: 920px !default;
$mobile-breakpoint: 760px !default;

$color-white: #fff !default;
$color-lightGrey: #ddd !default;
$color-dark: #333 !default;
$color-dark-shade: rgba(0, 0, 0, 0.05) !default;

.regional-feedback {
    display: flex;
    padding: $gapStandard;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $gapStandard * 2;
    position: relative;
    border-radius: $borderRadius;
    border: 1px solid $color-lightGrey;
    background-color: $color-white;

    @media only screen and (min-device-width: $tablet-breakpoint) {
        max-width: $grid-width-desktop;
        margin: $gapStandard auto;
        padding: $gapStandard * 2;
    }

    .regional-feedback--beta-indicator {
        display: flex;
        padding: 0.375rem;
        margin-top: 0.3rem;
        justify-content: center;
        align-items: center;
        gap: 0.625rem;
        border-radius: 0.25rem;
        background-color: #1d7be8;

        color: #fff;
        text-align: right;
        font-family: Inter;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.0625rem; /* 130.769% */
    }

    .regional-feedback--button--close {
        position: absolute;
        width: 2.375rem;
        height: 2.375rem;
        top: 0.5rem;
        right: 0.5rem;
        flex-shrink: 0;

        border-radius: 50%;
        background-color: #f1f1f3;
        border: unset;

        svg {
            width: 100%;
            height: 100%;
        }

        @media only screen and (min-device-width: $tablet-breakpoint) {
            top: 1rem;
            right: 1rem;
        }
    }

    .regional-feedback--title {
        display: flex;
        flex-direction: column;
        color: $color-dark;
        text-align: center;
        font-size: 1.25rem;
        font-family: Inter;
        line-height: 1.5rem;
        max-width: 36.25rem;
        margin: 0rem;
    }

    .regional-feedback--form {
        width: 100%;
        .regional-feedback--emoji-container {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            gap: 0.4rem;

            .regional-feedback--user-input-container {
                display: flex;
                flex-grow: 1;
                flex-basis: 0;
                width: 100%;
                max-width: 4.25rem;
                height: 3.6875rem;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 0.25rem;
                flex-shrink: 0;

                border-radius: 6px;
                border: none;
                box-shadow: none;
                background-color: $color-dark-shade;

                .regional-feedback--user-input {
                    width: 100%;
                    flex-shrink: 0;
                    font-size: 2rem;
                    line-height: 4rem;
                    font-style: normal;
                }
            }
            .regional-feedback--user-input-container:hover:not(
                    .regional-feedback--user-input--not-chosen
                ) {
                border: 1px solid $color-lightGrey;
                cursor: pointer;
            }

            .regional-feedback--user-input--not-chosen {
                background-color: rgba(255, 255, 255, 0.25);
                opacity: 0.25;
            }

            .regional-feedback--user-input--chosen {
                background-color: $color-icon-background;
                border: 1px solid $color-feedback-neutral;
            }

            @media only screen and (min-device-width: $tablet-breakpoint) {
                gap: 0.5rem;
            }
        }
    }

    .regional-feedback--form--text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        align-self: stretch;

        @media only screen and (min-device-width: $tablet-breakpoint) {
            width: 36.25rem;
            padding: 0.75rem;
            gap: 0.625rem;
            align-self: unset;
        }

        .regional-feedback--textarea {
            display: flex;
            height: 6.125rem;
            padding: 0.5rem;
            align-items: flex-start;
            gap: 0.625rem;
            align-self: stretch;

            border-radius: 0.5rem;
            border: 1px solid $color-lightGrey;
            background-color: $color-dark-shade;
            border: none;
            box-shadow: none;
            resize: none;

            font-size: 1.0625rem;
            font-family: Inter;
            color: $color-dark;

            @media only screen and (min-device-width: $tablet-breakpoint) {
                height: 3.1875rem;

                font-size: 1.0625rem;
                font-family: Inter;
                margin: 0 1rem;
            }
        }
        .regional-feedback--textarea:focus {
            outline: 1px solid $color-dark;
        }

        .regional-feedback--textarea-button {
            // Layout
            display: flex;
            height: 3.125rem;
            padding: 0.5rem 1rem;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            display: flex;
            align-self: stretch;

            // Style
            border-radius: 6px;
            border: 1px solid $color-feedback-neutral;
            background-color: $color-white;

            // Text
            color: $color-feedback-neutral;
            text-align: center;
            font-size: 1.0625rem;
            font-family: Inter;
            font-weight: 500;
            line-height: 1.5rem;

            @media only screen and (min-device-width: $tablet-breakpoint) {
                align-self: unset;
            }
        }
        .regional-feedback--textarea-button:hover {
            cursor: pointer;
        }
        .regional-feedback--textarea-button--active {
            background: $color-feedback-neutral;
            color: $color-white;
        }
    }

    .regional-feedback--logo {
        display: flex;
        width: 16.5rem;
        height: 2.15769rem;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: 100%;
            flex-shrink: 0;
        }
    }

    .regional-feedback--checkmark {
        text-align: center;
        font-size: 2rem;
    }
}

@media only screen and (max-width: $tablet-breakpoint) {
    .regional-feedback {
        background-color: #fefefe;
        margin: $gapStandard;

        .regional-feedback--form--text {
            flex-direction: column;
            height: auto;
        }

        .feedback-buttons-container {
            flex-direction: row-reverse;
        }

        .regional-feedback--title {
            font-size: 1.125rem;
            line-height: 1.2em;
            color: $color-dark;
            text-align: center;
            font-family: 'Inter', sans-serif;
        }

        .regional-feedback--title--message {
            margin: 48px 0px 48px;
        }

        .regional-feedback--user-input-container {
            background-color: rgba(0, 0, 0, 0.05);
            border-radius: 12px;

            .regional-feedback--user-input {
                font-size: 2rem;
            }
        }
    }
}

@media screen and (max-width: 273px) {
    .regional-feedback {
        display: none;
    }
}
