.hjernetrim-front-widget {
    margin: 0 auto;
    max-width: 984px;
    padding-top: 12px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding-left: 12px;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 16px;
        h2 {
            color: var(--color-black-base, #000);
            font-family: Inter;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.17px;
        }

        a {
            align-self: center;
        }

        .alle-spill {
            width: 75px;
            margin-right: 12px;
            display: flex;
            padding: 8px 16px;
            justify-content: center;
            align-items: center;
            align-content: center;
            gap: var(--spacing-spacing-12, 12px) 8px;
            flex-wrap: wrap;
            border-radius: 6px;
            background: var(--color-black-shade-5, rgba(0, 0, 0, 0.05));
        }
    }

    .content {
        gap: 12px;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        overflow-y: hidden;
        .gamecard {
            border-radius: 2px;
            display: flex;
            flex-direction: column;

            .icon-container {
                display: flex;
                justify-content: center;
                width: 100%;
                height: 50%;

                .icon {
                    padding: 24px 45px;
                    width: 100px;
                    height: 100px;
                    align-self: center;
                }
            }

            .text-container {
                padding: 12px;
                color: var(--color-black-base, #000);
                text-align: center;
                font-family: Inter;
                font-style: normal;
                line-height: normal;
                gap: 6px;
                display: flex;
                flex-direction: column;

                h3 {
                    margin: 0px;
                    font-size: 18px;
                    font-weight: 700;
                }

                .description {
                    margin: 0px;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }

        .widget-inner-container {
            width: 100%;
            height: auto;
            border: 1px solid black;
        }
    }
}

@media only screen and (max-width: 500px) {
    #date {
        width: 200px;
    }
}

@media only screen and (min-width: 1023px) {
    .hjernetrim-front-widget {
        padding-left: 0px;
    }

    .alle-spill {
        margin-right: 0px;
    }
}
