%btmag-title {
  font-family: $fontPremium;
  font-weight: normal;
}

.article-type--premium {
  .article__text-container {
    position: relative;

    .article__title {
      @extend %btmag-title;
      line-height: 1.2em;
      font-size: 21px;
    }
  }

  .article__img-container img {
    width: 100%; // Always use big image on feature
    float: none;
    margin: 0;
  }
}

.article-section--btmagasinet,
.article-section--magasin {
  .article__text-container {
    .article__title {
      @extend %btmag-title;
    }

    .author__image-container {
      display: none; // it looks shitty, so do not display in premium card
    }
  }

  .column .article__text-container {
    padding-left: $gapLarge;
    padding-right: $gapLarge;
  }

  .horizontal-x2 & .article__labels {
    text-align: left;
  }
}

// use white btmagasinet logo when article has dark background
.article--dark.article-section--btmagasinet {
  .article__text-container::before {
    background-image: url('#{$cdnHost}/bt/btmagasinet-smaller-white.svg');
  }
}

// use white magasin-logo on sa when article has dark background
.article--dark.article-section--magasin {
  .article__text-container::before {
    background-image: url('#{$cdnHost}/sa/magasin-smaller-white.svg');
  }
}

.article-section--btmagasinet {
  .article__text-container {
    .article__section:has(.article__section--julekalender) {
      display: inline;
    }
  }
}

// Magasinet-feature desktop
.row.collage-x3 .column--big:not(.column--bundle) .magasinet-feature,
.row.top-60-40-block .column.column-60:not(.column--bundle) .magasinet-feature {
  .article__teaser-container {
    height: 100%;

    a {
      .article__text-container {
        flex: initial;

        &::before {
          display: none;
        }

        .article__kicker-title {
          @include articleTitle-kicker-label-big();
          margin-bottom: 3px;
          font-family: $fontSansSerif;
          font-weight: 600;
          text-transform: none;
        }
      }
      .article__labels {
        margin-top: auto;
      }
      .article__img-container {
        margin: 0 $gapStandard $gapStandard;
        height: 0;
        width: calc(100% - $gapLarge);
        padding-bottom: 66.66%;

        img {
          border-radius: $borderRadiusSmall;
          height: auto;
          width: 100%;
        }
      }
    }
  }
}

.row.bundle-large .article--large.article-section--btmagasinet {
  .article__labels {
    padding: 0;
  }
  .article__img-container {
    margin-bottom: $gapStandard;
  }
}

.list {
  .article--small.article-type--premium {
    a.article__link {
      display: initial;
    }
  }
}

// Magasinet-feature mobile
.list .list-row .article--large.magasinet-feature {
  border: none;

  a.article__link {
    margin: $gapStandard 0 0 0;

    .article__text-container::before {
      display: none;
    }

    .article__text-container {
      margin-bottom: $gapLarge;

      .article__kicker-title {
        @include articleTitle-kicker-label-mobile();
        margin-bottom: 3px;
        font-family: $fontSansSerif;
        font-weight: 600;
        text-transform: none;
      }

      .article__section {
        margin-bottom: $gapStandard;
      }
    }

    .article__labels {
      padding-top: $gapStandard;
    }
  }
}

// Feature article
.list-row .article--feature,
.feature-x1 {
  .article-feature__gradient-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-flow: row wrap;

    .article-feature__gradient--first,
    .article-feature__gradient--second {
      width: 100%;
    }
  }

  .article-type--premium .article-feature__text-container::before {
    display: none;
  }

  .article-feature__text-container {
    .article-feature__items {
      border-radius: 4px;
    }
    .article__labels {
      padding: 0;
    }
  }
}

// Feature mobile
.list-row .article--feature {
  border-style: none;
  border-left: none;
  border-right: none;
  border-radius: 0;

  .article-feature__image_container {
    width: 100vw;
    height: 100vw;
    position: relative;
    .image-wrapper {
      width: 100vw;
      height: 56.66vw;
      position: absolute;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .image-wrapper.feature__image-last {
      transform: scaleY(-1);
      top: 56.66vw;
      margin-top: -1px;
    }
  }

  .article-feature__gradient-container {
    .article-feature__gradient--second {
      align-self: flex-end;
      height: 50%;
      background-image: linear-gradient(
        0deg,
        #000000 0%,
        rgba(0, 0, 0, 0.661458) 58.33%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  &.article--dark .article-feature__text-container .article-feature__items {
    background-color: #012133;
  }

  .article-feature__text-container {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    position: absolute;
    top: 0;

    &::before {
      display: none;
    }

    .article-feature__items {
      width: 100%;
      min-height: 45%;
      display: flex;
      flex-direction: column;
      align-self: flex-end;
      background: #fff;
      margin: $gapStandard;
      padding: $gapStandard $gapStandard 0;

      .article__section,
      .article__kicker-title {
        margin-bottom: 3px;
        font-family: $fontSansSerif;
        font-weight: 600;
        text-transform: none;
      }

      .article__kicker-title {
        @include articleTitle-kicker-label-mobile();
      }

      .article__section {
        @include articleTitle-section-label-mobile();
      }

      .article__title {
        &.article__title--long {
          @include articleTitle-h3();
        }
        &.article__title--medium {
          @include articleTitle-h3();
        }
        &.article__title--short {
          @include articleTitle-h3();
        }
      }

      .article__title {
        display: flex;
        flex-direction: column;
        margin-bottom: $gapStandard;
        font-family: $fontPremium;
        font-weight: 400;
      }

      .article__labels {
        margin-top: auto;
      }
    }
  }
}

// Desktop feature article
.feature-x1 {
  height: 360px;
  position: relative;

  .article {
    a.article__link {
      height: auto;

      .article-feature__image_container {
        display: flex;

        .feature__image-first {
          transform: scalex(-1);
          width: 300px;
          img {
            object-position: left;
            object-fit: cover;
          }
        }
        .feature__image-last {
          width: 680px;
          left: 44%;
          img {
            object-position: left;
            object-fit: cover;
          }
        }
      }
    }

    .article-feature__gradient-container {
      flex-flow: column;

      .article-feature__gradient--first {
        height: 100%;
        width: 50%;
        background-image: linear-gradient(
          90deg,
          #000000 0%,
          rgba(0, 0, 0, 0.661458) 58.33%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }

    .article--dark .article-feature__text-container .article-feature__items {
      background-color: #012133;
    }

    .article-feature__text-container {
      width: 458px;
      height: calc(100% - 2 * $gapLarge);
      display: flex;
      position: absolute;
      top: 0;
      margin: 24px 0 0 24px;

      &::before {
        display: none;
      }

      .article-feature__items {
        width: 100%;
        height: initial;
        display: flex;
        background: #fff;
        padding: $gapLarge $gapLarge $gapStandard;
        flex-direction: column;

        .article__section,
        .article__kicker-title {
          margin-bottom: 3px;
          font-family: $fontSansSerif;
          font-weight: 600;
          text-transform: none;
        }

        .article__kicker-title {
          @include articleTitle-kicker-label-big();
        }

        .article__section {
          @include articleTitle-section-label-big();
        }

        .article__title {
          &.article__title--long {
            @include articleTitle-h2();
          }
          &.article__title--medium {
            @include articleTitle-h2();
          }
          &.article__title--short {
            @include articleTitle-h2();
          }
        }

        .article__title {
          display: flex;
          flex-direction: column;
          font-family: $fontPremium;
          font-weight: 400;
        }

        .article__labels {
          margin-top: auto;
        }
      }
    }
  }
}
