.highlighted-comments-x1 {
  border-bottom-color: $borderColor !important;
  border-radius: $borderRadius;
  .article--questions {
    display: flex;
    flex-direction: row !important;
    padding: 16px;
    .article__teaser-container {
      flex-direction: column;
      flex: 2;
      a {
        margin-right: $gapStandard;
      }
      .video-box,
      .text-box {
        width: 100%;
      }
      .video-box {
        border-radius: $borderRadius;
        overflow: hidden;
        height: 344px;
      }
      .article__text-container {
        margin: $gapStandard 0;
        .article__title--long {
          @include articleTitle-h3();
        }
        .article__title--medium {
          @include articleTitle-h3();
        }
        .article__title--short {
          @include articleTitle-h3();
        }
      }
    }
    .highlighted-comments-container {
      flex: 1;
      font-family: Inter;
      .comments-header {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #0075b0;
        left: 0;
        justify-content: flex-start;
        .comments-counter {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #0075b0;
          left: 0;
          margin-right: 4px;

          &::before {
            content: none;
          }
        }
      }
      .comments-lead-text {
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        margin-top: 4px;
      }
      .highlighted-comments-outer {
        height: 80%;
      }
      .comment-text {
        width: 100%;
        .comment-text-inner {
          border-radius: 16px;
          width: 100%;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: -0.01em;
          color: #333333;
          border-width: 16px;
        }
        &:after {
          content: none;
        }
      }
      .additional-info {
        .comment-author {
          font-size: 12px;
          line-height: 14px;
          color: #666;
        }
      }
      .go-to-comment {
        color: #0075b0;
        border-color: #0075b0;
      }
    }
    .article-divider {
      height: auto;
      width: 1px;
      margin: 0 16px;
    }
  }
}
