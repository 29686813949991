.vertical-small-x2 {
  .article--small {
    padding: 0;

    .article__teaser-container a {
      flex-direction: row-reverse;
    }
    .article__img-container {
      float: right;
      height: 102px;
      width: 180px;
      border-radius: $borderRadius;
      margin: 0 0 $gapSmall $gapSmall;
    }
  }
  .article.article--noImage.article--small .article__text-container {
    margin-top: 0;
    margin-right: 0;
  }
}
