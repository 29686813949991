.lf-articles {
  font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial;
  display: flex;
  flex-direction: column;
  margin: 1rem 0 50px;
  &-container {
    display: flex;
    flex-direction: column;

    .lf-inline-ad {
      margin: 1rem 0;
      display: flex;
      flex-direction: column;
      .title-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 0.5rem;
        p {
          font-size: 1em;
          margin: 0;
          font-weight: 600;
          @include bigger-than(tablet) {
            font-size: 1.2em;
          }
        }
        .logo-image {
          max-width: 8rem;
          width: 100%;
          height: auto;
          max-height: 2.5rem;
          object-fit: contain;
          object-position: right;
          @include bigger-than(tablet) {
            max-width: 15rem;
          }
        }
      }
      .teaser-image {
        width: 100%;
        height: auto;
        margin-bottom: 0.75rem;
      }
      h4 {
        margin: 0 0 0.3rem;
        font-weight: 400;
      }
      h3 {
        margin: 0;
        font-family: TiemposHeadlineMedium;
        font-size: 1.4em;
        font-weight: 400;
      }
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.3rem;
    flex-direction: column;

    @include bigger-than(tablet) {
      flex-direction: row;
    }

    &-icon {
      width: 22px;
      height: 34px;
      margin-right: 14px;
      &__sa {
        width: 40px;
        height: 40px;
      }
    }

    &-title {
      font-weight: 500;
      margin: 0;
      display: inline-flex;
      align-items: center;
    }

    .lf-column {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: relative;

      &__left {
        justify-content: flex-start;
      }
    }
  }

  // Kampreferat
  &-match {
    margin: 0.4rem 0;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #dddddd;
    border-radius: 4px;
    gap: 1rem;

    &-body {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      width: 100%;
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .lf-match-result {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .lf-match-meta {
        display: flex;
        gap: 4px;
      }

      &-kicker {
        margin: 0;
        font-weight: 700;
        width: fit-content;
        padding: 0 8px;
        white-space: nowrap;
        font-size: 1.1rem;
      }

      &__logo {
        width: 24px;
        height: auto;
      }
      .lf-match-badge {
        padding: 4px 8px;
        background-color: #eeeeee;
        border-radius: $borderRadiusSmall;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 0.5rem;
    }

    &:hover {
      border-color: #bbb;
    }

    &-title {
      margin: 8px 0 0;
    }

    &__big {
      flex-direction: column-reverse;

      @include bigger-than(mobile) {
        flex-direction: row;
      }
    }
  }

  &-editorial {
    &-top {
      margin-bottom: 0.3rem;
    }

    &__big {
      margin: 16px 0;
      padding: 16px;
      border: 1px solid #dddddd;
      border-radius: 4px;
      display: flex;
      flex-direction: column;

      &:hover {
        border-color: #bbb;
      }

      &-kicker {
        margin: 0 0 0.2rem;
        font-weight: 400;
        font-size: 0.9em;
      }

      &-title {
        font-family: TiemposHeadlineMedium;
        font-size: 1.2em;
        margin: 0;
      }
    }

    &-list {
      &-item {
        padding: 16px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-top: 1px solid #dddddd;
        line-height: 21px;
        gap: 1rem;

        &:first-of-type {
          border: none;
        }

        &-kicker {
          margin: 0 0 0.3rem;
          font-weight: 700;
          font-size: 0.9em;
        }

        &-title {
          margin: 0;
        }
      }
    }
  }

  &-img {
    border-radius: 4px;
    background-color: #ddd;
    object-fit: cover;
    object-position: center center;
    aspect-ratio: 16/9;

    width: 100%;

    &-container {
      flex-shrink: 0;

      &__small {
        max-width: 128px;
      }

      &__big {
        height: auto;
        width: 100%;

        &__editorial {
          margin-bottom: 0.8rem;
        }
      }
    }
  }

  &-more {
    display: none;

    &.visible {
      &.editorial {
        border-top: 1px solid #ddd;
      }
      display: block !important;
    }

    &-btn {
      background: $color-btn;
      color: $text-color;
      border: 1px solid #dddddd;
      border-radius: $borderRadiusSmall;
      padding: 0.5rem;
      font-family: inherit;
      cursor: pointer;
      &:hover {
        background-color: $color-btn-hover;
      }
    }
  }
}
