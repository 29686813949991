.vertical-x4 {
  .article {
    flex-direction: column;
    justify-content: space-between;

    &.article--noImage .article__labels {
      left: 0;
    }
    &.article--xsmall {
      a {
        flex-direction: column;
      }
      .article__img-container {
        height: 0;
        padding-bottom: 56.25%;
        margin-top: 0;
        margin-right: 0;

        img {
          height: auto;
        }
      }
      .article__title {
        margin-bottom: 0;
        @include articleTitle-h4();
      }
    }
    &.article--xsmall.article--noImage:not(.article--live) {
      .article__title:not(.article__title--long) {
        @include articleTitle-h2();
      }
    }
    a.article__link {
      height: unset;
    }
  }

  &.row {
    .live__timeline-container {
      .timeline--item a {
        display: block;

        .line-clamp {
          -webkit-line-clamp: 2;
        }
      }
    }
  }

  .article--live.no-entry {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
