@include respond-to(tablet-only) {
  html,
  body {
    font-size: $fontBaseSizeTablet;
  }

  .article--live,
  .article--breaking {
    .article__text-container {
      .article__section {
        &::before {
          width: 6px;
          height: 6px;
        }
      }
    }
  }

  .row .author__image-container {
    width: 64px;
    height: 64px;
  }
}

$tabletBreakpoints: (
  xlarge1: (
    bp: 1009px,
    scaler: 0.85,
    width: 1090px,
  ),
  xlarge2: (
    bp: 949px,
    scaler: 0.8,
    width: 1050px,
  ),
  xlarge3: (
    bp: 900px,
    scaler: 0.8,
    width: 1000px,
  ),
  large: (
    bp: 833px,
    scaler: 0.75,
    width: 1020px,
  ),
  medium: (
    bp: 784px,
    scaler: 0.71,
  ),
  small: (
    bp: 768px,
    scaler: 0.7,
    width: 1025px,
  ),
  xsmall: (
    bp: 600px,
    scaler: 0.55,
    width: 1010px,
  ),
);

@each $value, $key in $tabletBreakpoints {
  @media screen and (max-width: map-get($key, bp)) {
    .row.vertical-x1-ad {
      -webkit-transform-origin: 0 0;
      -webkit-transform: scale(map-get($key, scaler));
      transform-origin: 0 0;
      transform: scale(map-get($key, scaler));
      overflow-x: hidden;
      width: #{map-get($key, width)};
      margin-bottom: calc(-395px * (1 - #{map-get($key, scaler)}));
    }
  }
}
