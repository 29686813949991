$base-widget-title-font-size: 1.375rem;
$base-widget-description-font-size: 0.9375rem;
$base-widget-when-where-font-size: 1rem;

$base-widget-title-line-height: 1.625rem;
$base-widget-description-line-height: 1.125rem;
$base-widget-when-where-line-height: 1.25rem;

.widget-container--single {
  flex: 1 0 90%;

  .teaser-container {
    .title {
      font-size: calc($base-widget-title-font-size * 1.3 * 1.3);
      line-height: calc($base-widget-title-line-height * 1.3 * 1.3);
    }

    .description {
      font-size: calc($base-widget-description-font-size * 1.3 * 1.3);
      line-height: calc($base-widget-description-line-height * 1.3 * 1.3);
    }

    .when-where {
      font-size: calc($base-widget-when-where-font-size * 1.3 * 1.3);
      line-height: calc($base-widget-when-where-line-height * 1.3 * 1.3);
    }
  }
}

.widget-container.widget-container--double {
  flex: 1 0 49%;
  .teaser-container {
    .title {
      font-size: calc($base-widget-title-font-size * 1.45);
      line-height: calc($base-widget-title-line-height * 1.45);
    }

    .description {
      font-size: calc($base-widget-description-font-size);
      line-height: calc($base-widget-description-line-height);
    }

    .when-where {
      font-size: calc($base-widget-when-where-font-size);
      line-height: calc($base-widget-when-where-line-height);
    }

    .teaser-image {
      padding-top: 49%;
    }
  }
}

.widget-container.widget-container--triple {
  flex: 1 0 32%;

  .teaser-container {
    .title {
      font-size: $base-widget-title-font-size;
      line-height: $base-widget-title-line-height;
    }

    .description {
      font-size: $base-widget-description-font-size;
      line-height: $base-widget-description-line-height;
    }

    .when-where {
      font-size: $base-widget-when-where-font-size;
      line-height: $base-widget-when-where-line-height;
    }
  }
}

// general global (mobile) styles

.widget-container {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 100%;
  text-decoration: none;
  background-color: $color-white;
  border: 1px solid $color-lightGrey;
  border-radius: $border-radius-lg;
  overflow: hidden;

  .teaser-image {
    padding-top: 66%;
    background-repeat: no-repeat;
  }
  .teaser-container {
    display: flex;
    flex-direction: column;
    margin: $margin-size;
    height: 100%;
    justify-content: space-between;

    .description {
      font-size: $base-widget-description-font-size;
      line-height: $base-widget-description-line-height;
      color: $color-fordel-accentSemiLight;
      letter-spacing: 0.218182px;
      text-transform: uppercase;
      font-weight: 500;
      margin: 0 0 $gap-size;

      &.competition {
        color: $color-fordel-teaser-competition-category;
      }
    }

    .teaser-top {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .title {
      font-size: $base-widget-title-font-size;
      line-height: $base-widget-title-line-height;
      font-weight: 700;
      letter-spacing: -0.1px;
      color: $color-fordel-widget-title;
      margin: 0;

      &.competition {
        color: $color-fordel-teaser-competition-title;
      }
    }

    .when-where {
      font-size: $base-widget-when-where-font-size;
      line-height: $base-widget-when-where-line-height;
      font-weight: 500;
      letter-spacing: 0.218182px;
      margin-top: $gap-size * 0.5;
      margin-bottom: $margin-size;
      display: inline-block;
      // bt/c-dark
      color: $color-fordel-widget-font;

      &.competition {
        color: $color-fordel-teaser-competition-text;
      }
    }

    .prices {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      // height: calc(0.9em + 2 * $margin-size);
      border-top: 1px dashed $color-lightGrey;
      margin: -$margin-size;
      padding: $margin-size;
      margin-top: $margin-size;
      gap: $gap-size;

      .presentation-button {
        font-family: 'Inter', sans-serif;
        padding: $margin-size * 0.5 $margin-size;
        background: $color-fordel-widget-button-background;
        border-radius: $border-radius-sm;
        color: $color-fordel-widget-button-font;
        font-size: 1.3125rem;
        line-height: 1.625rem;
        font-weight: 600;
        white-space: nowrap;
        letter-spacing: -0.1px;
        text-align: center;
      }

      span {
        color: $color-fordel-widget-footer-text;
      }

      &.competition {
        .presentation-button {
          width: 100%;
          background: $color-fordel-teaser-competition-button-background;
          color: $color-fordel-teaser-competition-button-text;
          text-transform: uppercase;
          font-size: 1rem;
          line-height: 1.625rem;
        }
      }
    }

    .promotional-prices {
      justify-content: flex-end;
    }
  }

  .teaser-widget-pulse-container {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    flex: 1 0 auto;
  }
}

@media only screen and (max-width: $tablet-breakpoint) {
  .widget-container.widget-container--triple {
    .prices {
      .presentation-button {
        font-size: 1.1rem;
      }
    }
  }
}

// Tablet and desktop styles

@media only screen and (min-width: $mobile-breakpoint) {
  .widget-container--single {
    flex-direction: row-reverse;
    position: relative;
    height: fit-content;

    .article-image-duplicate {
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      .article-image {
        width: auto;
      }
      .duplicate {
        width: 50%;
        .teaser-image {
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.65) 100%
          );
          transform: scaleX(-1);
        }
        .teaser-image:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: inline-block;
          background: linear-gradient(
            270deg,
            rgba(0, 0, 0, 0.65) 0%,
            rgba(51, 51, 51, 0.57) 69.23%,
            rgba(51, 51, 51, 0) 100%
          );
        }
      }

      .original {
        width: 50%;
      }
    }

    .teaser-container {
      position: absolute;
      width: calc(50% - 1.5rem);
      height: auto;
      height: calc(90% - 1.5rem);
      border-radius: $border-radius-sm;
      top: 5%;
      right: 48%;
      background-color: $color-white;
      padding: 0.75rem;
      margin: 0;
    }
  }
}
