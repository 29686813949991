#restaurant-list-widget-desktop {
  max-width: 980px;
  margin: 0 auto;

  .widget-x4,
  .top-collage-x3 {
    background: rgba(0, 0, 0, 0.5);
    background-image: url('https://cdn.bt.no/bt/restaurant-bg.jpg');
    background-size: cover;
    border-radius: 4px;
    max-width: 100%;
  }

  .column--big {
    flex: 2;
  }

  .column--small {
    flex: 1;
    margin-right: 0;
  }

  .restaurant-list-header {
    height: 50px;
    font-family: Inter;
    margin-bottom: 0;
    line-height: 50px;
    max-width: 100%;

    img {
      margin: 12px 8px 0 0;
    }

    span,
    a {
      font-size: 16px;
      margin-left: 30px;
    }

    a {
      text-align: right;
      flex: 2;
    }

    span {
      @include respond-to(tablet-only) {
        display: none;
      }
    }

    .title {
      color: rgb(51, 51, 51);
      font-family: Inter;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.06px;
      margin: 0;
    }
  }

  .disclaimer-mobile {
    line-height: 20px;
    font-family: Inter;
    padding: 0;
    margin: 0 0 15px 0;

    @include respond-to(web) {
      display: none;
    }
  }

  ul {
    margin-top: $gapStandard;
    margin-right: $gapStandard;
    padding-left: 0;
    margin-bottom: 8px;
  }

  li {
    width: auto;
  }

  .article {
    background: transparent;
    border: none;
  }

  .column--big {
    .article-title {
      margin-top: 8px;
    }
    .article__teaser-container,
    .article__labels {
      margin: 0 $gapStandard $gapMedium;
    }
    .article__labels {
      position: relative;
    }
    .article__img-container {
      height: 350px;
    }
  }

  .article__img-container {
    margin: 0 0 8px;
    img {
      border-radius: 4px;
      object-fit: cover;
    }
  }

  .article__kicker-title {
    color: rgb(255, 255, 255);
    display: inline;
    font-family: Inter;
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    font-weight: 600;
  }

  .article-kicker {
    margin-right: 8px;
  }

  .article-title {
    color: rgb(255, 255, 255);
    font-size: 21px;
    font-family: $fontHeadline;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.3px;
  }

  li:first-child,
  li:last-child {
    .article-title {
      font-family: Inter;
      font-size: 14px;
      letter-spacing: -0.1px;
      margin: 0 0 8px;
      line-height: 18px;
    }
    .article__kicker-title img {
      display: inline-block;
    }

    .article__kicker-title {
      @include respond-to(tablet-only) {
        margin-top: 8px;
      }
    }

    .article__img-container {
      height: 146px;
    }
  }

  li:not(:first-child) {
    a {
      display: grid;
      grid-template-columns: 63% 37%;
      padding: $gapStandard 0 0;
      border-top: 1px solid rgb(191, 191, 191);
    }
    .article__labels a {
      border-top: none;
      padding: 0;
    }

    .article__kicker-title img {
      display: block;
    }

    p.article-title {
      display: none;
    }

    .label-grade {
      margin-left: 0;
    }

    .article__img-container {
      min-width: 100px;
      width: 100px;
      height: 66px;
      order: 2;
      justify-self: end;
    }
    &.restaurant-map-teaser {
      p.article-title {
        display: block;
        font-weight: 400;
      }
    }
  }
  .minimal-teaser .article__labels {
    .label--comments .counter-wrapper .widget-comment-counter .counter-icon {
      width: 25px;
      height: 20px;
      background-image: url(https://cdn.bt.no/rnd/comments-bubble.svg);
      background-size: contain;
      background-repeat: no-repeat;
      text-align: center;
      line-height: 18px;
    }
  }

  .article__labels {
    font-size: 10px;
    padding: unset;
    font-family: Inter, Helvetica, Arial, sans-serif;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    margin-bottom: 12px;
    line-height: 1.2em;
    letter-spacing: 0.1em;

    .label--paywall {
      text-transform: uppercase;
      display: inline-block;
      color: rgb(204, 204, 204);
    }

    a.comments-link {
      padding: 0;
      border: none;
    }

    .counter-icon {
      color: rgb(204, 204, 204);
    }

    .label--comments {
      flex-direction: row;
    }

    a {
      margin-left: auto;
    }
  }
  @include respond-to(mobile-only) {
    margin: 16px;
    .restaurant-list-header {
      .title {
        font-size: 17px;
        font-weight: 600;
        letter-spacing: -0.3px;
        line-height: 24px;
        display: contents;
      }
      .header-title {
        display: none;
      }
    }
    .top-collage-x3 {
      flex-direction: column;
      .column--big {
        margin-right: 0;
        .article__img-container {
          height: 171px;
        }
        .article__teaser-container {
          margin: 8px 16px 0;
        }
        .article__labels {
          margin: 0 16px 6px;
        }
      }
      .column--small {
        margin: 0 16px;
        ul {
          margin: 0;
          .column--small {
            margin: 0;
          }
          li:first-child {
            a {
              display: grid;
              grid-template-columns: 63% 37%;
              padding: 12px 0 0;
              border-top: 1px solid #bfbfbf;
            }
            .article__img-container {
              min-width: 100px;
              width: 100px;
              height: 66px;
              order: 2;
              justify-self: end;
            }
            .article-title {
              display: none;
            }
          }
          li {
            .article__kicker-title {
              display: flex;
              flex-direction: column;
              img {
                width: 101px;
              }
            }
            .article__labels a {
              border-top: none;
            }
          }
        }
      }
    }
  }
}
