#archivePromo {
  z-index: 10000;
  background: rgb(240, 239, 237);
  margin-bottom: 16px;
  min-height: 660px;
  @include respond-to(mobile-only) {
    min-height: 700px;
  }

  p.archive-teaser {
    font-family: $fontSansSerif;
    font-weight: normal;
    max-width: 619px;
    margin: 20px auto;
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    color: rgb(102, 102, 102);
    @include respond-to(mobile-only) {
      margin: 20px auto;
      max-width: 350px;
    }
  }

  #archive-container {
    z-index: 10000;
    position: relative;
  }

  #results-header {
    display: none;
  }

  #archive-results {
    max-height: 400px;
    overflow: auto;
    overflow-x: hidden;
    display: none;
    padding: 0;
  }

  #refine-results {
    height: 250px;
    padding-top: 40px;
    display: none;
    text-align: center;

    #periode {
      color: rgb(102, 102, 102);
      font-size: 13px;
    }
    .button {
      margin-top: 65px;
    }
  }

  #results-container {
    font-family: $fontSansSerif;
    font-weight: normal;
    position: relative;
    // top: 10%;
    padding: 10px;
    margin: auto;
    background: white;
    border-radius: 25px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 526px;
    z-index: 1;
    // border: 1px solid rgba(0, 0, 0, 0.22);
    box-shadow:
      0 4px 10px 0 rgba(0, 0, 0, 0.16),
      0 24px 24px -16px rgba(0, 0, 0, 0.5);
  }

  .initial:hover {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.31);
  }

  .initial {
    width: 328px !important;
    border-radius: 25px !important;
  }

  .noshadow {
    box-shadow: none !important;
  }

  #close-results  {
    height: 70px;
    box-shadow:
      0 -18px 21px -12px rgba(0, 0, 0, 0.3),
      0 24px 24px -16px rgba(0, 0, 0, 0.5);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    width: 100%;
    background: white;
    left: 0px;
    bottom: -30px;
    display: none;
    text-align: center;
    .button {
      margin-top: 15px;
    }
  }
  .article__title {
    font-size: 41px;
    text-align: center;
    margin: 28px 0 28px;
  }

  .article__img-container {
    height: 250px;
  }
  #archive-results li {
    padding: 3px;
    list-style: none;
    margin-bottom: 15px;
    display: flex;
  }

  #archive-results .date {
    font-family: $fontSansSerif;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase;
  }

  .excerpt {
    color: rgb(102, 102, 102);
    font-size: 15px;
    line-height: 22px;
    @include respond-to(mobile-only) {
      line-height: 18px;
    }
  }

  .excerpt strong {
    background-color: rgb(184, 228, 250);
    padding: 2px;
    display: inline-block;
    font-family: $fontSansSerif;
    font-weight: 500;
  }

  #archive-results .y {
    background: #236fa8;
    color: #f3f1ef;
    padding: 2px;
  }

  #archive-results a {
    color: rgb(0, 116, 171);
  }

  .archive-item-cover {
    width: 91px;
    height: 130px;
    margin-right: 20px;
    position: relative;

    .overlay {
      background: linear-gradient(
          -180deg,
          rgba(0, 0, 0, 0) 0,
          rgba(0, 0, 0, 0.1) 100%
        )
        #fff0;
      width: 91px;
      height: 130px;
      position: absolute;
      top: 0px;
      left: 0px;
    }

    img {
      box-shadow:
        0px 1px 3px 0px rgba(0, 0, 0, 0.5),
        4px 7px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2.12px 2.12px 4.23px 4.23px;
      background: linear-gradient(
          -180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.1) 100%
        )
        rgb(255, 255, 255);
    }

    p {
      text-align: left !important;
    }
  }

  .archive-query {
    font-family: $fontSansSerif;
    font-weight: normal;
    padding: 0 0 0 30px;
    border-radius: 22.5px;
    border: none;
    width: 100%;
  }

  .archive-query::placeholder {
    color: rgb(51, 51, 51);
    font-size: 16px;
    opacity: 1;
  }

  .archive-query::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  .archive-query:focus {
    outline: none;
  }

  .archive-search-icon {
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
    font-size: 20px;
  }

  #reset-search {
    display: none;
    font-size: 30px;
    position: absolute;
    top: 3px;
    right: 13px;
    cursor: pointer;
  }

  #covers {
    overflow: hidden;
    height: 405px;
    ul {
      padding: 8px;
      // width: 350px;  -> tablet
      // overflow-x: scroll;
      white-space: nowrap;
      text-align: center;
      margin: 0 auto;
      width: 785px;

      li {
        display: table-cell;
        margin: 10px;

        .gradient {
          background: linear-gradient(
              -180deg,
              rgba(0, 0, 0, 0) 0,
              rgba(0, 0, 0, 0.1) 100%
            )
            #fff0;
          position: relative;
          top: -388px;
          left: 10px;
          width: 242px;
          height: 345px;
        }

        .button-read {
          position: relative;
          top: 49%;
          background: rgb(250, 242, 242);
          border-radius: 34px;
          box-shadow:
            0px 12px 16px 2px rgba(0, 0, 0, 0.05),
            0px 8px 32px 4px rgba(0, 0, 0, 0.05),
            0px 6px 12px -4px rgba(0, 0, 0, 0.12);
          height: 40px;
          width: 130px;
          font-family: $fontSansSerif;
          font-weight: 500;
          color: rgb(1, 71, 109);
          padding: 5px 10px;
          cursor: pointer;
        }

        img {
          box-shadow:
            0px 1px 3px 0px rgba(0, 0, 0, 0.5),
            4px 7px 4px 0px rgba(0, 0, 0, 0.1);
          border-radius: 2.12px 2.12px 4.23px 4.23px;
          background: linear-gradient(
              -180deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0.1) 100%
            )
            rgb(255, 255, 255);
        }

        p {
          color: rgb(102, 102, 102);
          font-family: $fontSansSerif;
          font-weight: 500;
          font-size: 18px;
          text-align: center;
          width: 242px;
          margin: 10px;
          @include respond-to(mobile-only) {
            text-align: left;
          }
        }
      }
    }
  }

  .archive-link:hover {
    text-decoration: underline;
  }

  #top-search {
    text-align: center;
  }

  #refine-search {
    text-decoration: underline;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }

  .button {
    border-radius: 20px;
    border: 1px solid rgb(111, 111, 111);
    width: 157px;
    height: 39px;
    background: white;
    margin-bottom: 20px;
    font-family: $fontSansSerif;
    font-weight: normal;
    cursor: pointer;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  input {
    flex: 1 0 0;
    min-width: 0;
    margin: 0;
    padding: 0;
  }
  input::-webkit-slider-thumb {
    margin: 0;
    padding: 0;
  }

  input[type='tel'] {
    border: 0;
    width: 60px;
    padding: 3px;
    color: rgb(0, 116, 171);
    font-size: 18px;
    text-decoration: underline;
    text-align: center;
  }

  #slidecontainer {
    padding: 30px 20px 3px;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 2px;
    background: #0074ab;
    outline: none;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    background: #fff;
    border: 4px solid rgb(0, 116, 171);
    cursor: pointer;
    border-radius: 50%;
    box-shadow:
      1px 2px 2px 0 rgba(0, 0, 0, 0.25),
      4px 7px 4px 0 rgba(0, 0, 0, 0.1);
  }

  .slider::-moz-range-thumb {
    width: 16px;
    height: 16px;
    background: #fff;
    border: 4px solid rgb(0, 116, 171);
    cursor: pointer;
    border-radius: 50%;
    box-shadow:
      1px 2px 2px 0 rgba(0, 0, 0, 0.25),
      4px 7px 4px 0 rgba(0, 0, 0, 0.1);
  }

  #range-container {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    padding: 0 20px;
  }

  #range {
    padding-top: 10px;
  }

  #rangefrom,
  #rangemid,
  #rangeto {
    position: relative;
    top: 15px;
    color: rgb(102, 102, 102);
    font-size: 13px;
  }

  #rangefrom {
    text-align: left;
  }

  #rangeto {
    text-align: right;
  }

  #rangemid {
    text-align: center;
  }

  .uil-ring-alt circle {
    stroke: #236fa8;
  }

  @include respond-to(tablet-only) {
    #covers {
      overflow-y: hidden;
      overflow-x: scroll;
    }
  }

  @include respond-to(mobile-only) {
    #covers {
      overflow-y: hidden;
      overflow-x: scroll;
    }

    #results-container {
      position: relative;
      display: block;
      // height: 100px;
      top: 10px;
      left: 0;
      padding: 10px;
      border: none;
      width: unset;
      box-shadow: none;
      border: none;
    }

    #results-container ul {
      padding-left: 10px;
    }
  }
}
