.list {
  //override Huberto desktop defaults for mobile listing flow
  margin-right: 0;
  margin-top: $gapStandard;

  .author__image-container {
    float: right;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .list-row {
    min-width: 100%;
    margin-bottom: 0;

    &:last-child {
      margin-bottom: $gapStandard;
    }

    .article--small {
      .article__img-container {
        // Floated image base
        float: right;
        min-width: 128px;
        width: 128px;
        height: 85px;
        margin: 0 0 $gapSmall $gapSmall;
        img {
          border-radius: $gapSmall;
        }
      }
    }
    .article--xsmall {
      .article__img-container {
        display: none;
      }
    }
    .article .article__labels {
      display: flex;
      height: auto;
      justify-content: space-between;
    }

    .article-type--default.mm-integration {
      margin: 0;
    }
    .mm-container div.jwplayer {
      position: absolute;
      top: 0;
      left: 0;
    }
    .article__link.mm-container {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
      width: 100%;
    }
    .article__link.mm-container.podcast {
      padding-top: 0;
    }
    .article__link.mm-container.podcast > div {
      height: 184px !important;
    }
    .article__link.mm-container.podcast .jwplayer.svp-flag-media-audio {
      border-radius: $gapStandard * 0.25;
      height: 184px !important;
    }
    .article__link.mm-container.podcast
      .jwplayer.svp-flag-media-audio
      .jw-preview:after {
      clip-path: inset(0% 0% 0% 0% round $gapStandard * 0.25);
      -webkit-clip-path: inset(0% 0% 0% 0% round $gapStandard * 0.25);
    }

    .article--large {
      border-left: none;
      border-right: none;
      border-radius: 0;

      .article__title {
        @include articleTitle-h4-mobile;
      }

      .article--img_container {
        @include articleImage-ratio-16by9();
      }
    }

    .article--medium {
      .article__title {
        @include articleTitle-h4-mobile;
      }
    }

    .article--small,
    .article--xsmall {
      border-left: none;
      border-right: none;
      border-radius: 0;

      .article__title {
        @include articleTitle-h5-mobile;
      }
    }

    .article--medium.article--dark.article--breaking {
      border-radius: 0;
    }

    .article--noImage {
      .article__title {
        @include articleTitle-h4-mobile;
      }
    }

    &.live .article--major-news .article__link {
      flex-direction: column-reverse;
    }

    .article--major-news {
      margin: 0 0 $gapStandard;
      border-radius: $borderRadius;

      .article__link {
        height: unset;
        flex-direction: column;
      }
      .article__img-container {
        height: 0;
        padding-bottom: 56.25%;
        img {
          height: auto;
          object-fit: cover;
        }
      }
      .article__text-container {
        margin: $gapStandard $gapStandard ($gapSmall + 2);
        .article__section {
          @include articleTitle-h6-mobile;
          font-weight: 600;
          &:before {
            @include articleTitle-h5-mobile;
          }
        }
        .article__kicker-title {
          @include articleTitle-h6-mobile;
          font-weight: 600;
          margin-bottom: ($gapSmall + 2);
        }
      }
      .article__title {
        font-weight: $fontMajorNewsWeight;

        @include articleTitleShort {
          @include articleTitle-majorNews-mobile-large();
        }
        @include articleTitleMedium {
          @include articleTitle-majorNews-mobile-medium();
        }
        @include articleTitleLong {
          @include articleTitle-majorNews-mobile-small();
        }
      }
    }

    // Dødsannonse margin fix
    #aLOLE {
      .article__img-container {
        margin: 0 0 $gapSmall $gapSmall;
      }
    }

    // Podcast teaser
    .podcast__image-container,
    .article__imageOrPodcast-container {
      display: block;
      .podcastImage {
        background-size: contain;
        background-repeat: no-repeat;
        height: 64px;
        width: 64px;
      }
    }

    .podcast__image-container {
      float: right;
      overflow: hidden;
      margin-left: $gapStandard * 0.5;
      border-radius: $gapStandard * 0.5;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
    }

    // Hides podcast image (which replaces main image) as default
    .article__imageOrPodcast-container .podcastImage {
      display: none;
    }

    // Hides teaser image, when small podcast teaser
    &.list-row--small
      .article-type--default
      .article__imageOrPodcast-container
      .article__img-container {
      display: none;
    }

    // Hides podcast image, when small podcast teaser
    &.list-row--small .article-type--default .podcast__image-container {
      display: none;
    }

    // Shows podcast image as main image, when there is podcast teaser small
    &.list-row--small .article-type--default .podcastImage {
      margin: $gapStandard;
      border-radius: $gapStandard * 0.5;
      display: block;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
    }
  }

  .article__text-container {
    .article__title {
      margin-bottom: 0;
    }

    .article__labels {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      font-size: 11px;
      margin-top: $gapStandard;

      .label--comments
        .counter-wrapper
        .widget-comment-counter
        div.counter-container {
        font-size: 10px;
      }
    }
  }

  .list-row--xsmall {
    .article-type--default {
      margin: 0 $gapStandard;

      a.url {
        display: flex;
        flex-direction: row-reverse;
      }

      .article__text-container {
        position: relative;
      }

      $imageWidth: 102px;
      $imageHeight: 68px;
      .article__img-container {
        display: none;
      }

      &.article--withAuthorMeta {
        .article__img-container {
          display: none;
        }
      }
    }

    .article-type--default:not(.article--dark):not(.article--noImage) {
      margin: $gapStandard;

      .article__img-container {
        margin-top: $gapSmall;
        margin-right: 0;
      }
    }
  }

  .list-row--small {
    .article-type--default {
      margin-left: 0;
      margin-right: 0;

      a.url {
        display: flex;
        flex-direction: row-reverse;
      }

      .article__text-container {
        position: relative;
      }

      $imageWidth: 128px;
      $imageHeight: 85px;
      .article__img-container {
        min-width: $imageWidth;
        width: $imageWidth;
        height: $imageHeight;
        margin-right: $gapStandard;
        margin-bottom: $gapStandard;
        margin-top: $gapSmall;
      }

      &.article--withAuthorMeta {
        .article__img-container {
          display: none;
        }
      }
    }

    .article.mm-integration a.url {
      flex-direction: column;
    }

    .article-type--default:not(.article--dark):not(.article--noImage) {
      margin-left: 0;
      margin-right: 0;

      .article__img-container {
        margin-top: $gapStandard;
        margin-right: $gapStandard;
      }
    }
  }

  .list-row.list-row--small + .list-row.list-row--small {
    margin-top: -1px;

    .article--dark {
      margin-top: 0;
      border-top: solid 1px $darkskinDividerColor;
    }
  }

  .list-row--medium {
    margin-top: $gapStandard;
    margin-bottom: $gapStandard;
    .article-type--default {
      margin: $gapStandard;

      .article__img-container {
        @include articleImage-ratio-16by9();
      }
    }

    .article-type--premium {
      .article__img-container {
        @include articleImage-ratio-16by9();
      }
    }
  }

  .list-row--large {
    margin-top: $gapStandard;
    margin-bottom: $gapStandard;
    .article-type--default {
      .article__img-container {
        @include articleImage-ratio-16by9();
      }
    }
    .article-type--premium {
      .article__img-container {
        @include articleImage-ratio-16by9();
      }
    }
    .article__title {
      @include articleTitle-h4-mobile();
    }
  }

  // margin on abo-label under image on large liveReordered
  .article--large.article--live.article--liveReordered {
    .article__labels {
      margin-top: $gapStandard;
    }
  }

  // Removes top margin between large-medium & medium-large

  .list-row.list-row--medium + .list-row.list-row--medium {
    margin-top: 0;
  }

  .list-row.list-row--medium + .list-row.list-row--large {
    margin-top: 0;
  }

  .list-row.list-row--large + .list-row.list-row--medium {
    margin-top: 0;
  }

  .list-row.list-row--large + .list-row.list-row--large {
    margin-top: 0;
  }

  // Bundle css

  .bundle-block {
    margin-left: $gapStandard;
    margin-right: $gapStandard;
    margin-bottom: $gapStandard;

    .article--large {
      .article__img-container {
        @include articleImage-ratio-16by9();
      }
    }

    .article__imageOrAuthor-container {
      .author__image-container {
        display: none;
      }
    }
  }

  .bundle-block:nth-child(1) {
    margin-top: $gapStandard;
  }

  & ~ .list .bundle-block:nth-child(1) {
    margin-top: 0;
  }

  .bundle-block + .bundle-block {
    margin-top: $gapStandard;
  }

  .bundle-block + .list-row--xsmall,
  .bundle-block + .list-row--small {
    margin-top: $gapStandard;
  }

  .list-row.list-row--xsmall + .bundle-block,
  .list-row.list-row--small + .bundle-block {
    margin-top: $gapStandard;
  }

  .bundle-block .list-row.bundle-item {
    margin-top: 0;
    margin-bottom: $gapStandard;
  }

  .bundle-block .article,
  .bundle-block
    .list-row.bundle-item
    .article-type--default:not(.article--dark):not(.article--noImage) {
    border: none;
    border-bottom: none;
    margin-left: 0;
    margin-right: 0;
  }

  .bundle-block .list-row.bundle-item.sub .article-type--default a.url {
    display: flex;
    flex-direction: row-reverse;
    border-top: 1px solid rgb(210, 210, 210);
    margin-left: $gapStandard;
    margin-right: $gapStandard;
  }

  .bundle-block
    .list-row.bundle-item.sub
    .article-type--default
    .article__img-container {
    min-width: 102px;
    width: 102px;
    height: 68px;
    margin-right: 0;
    margin-top: $gapStandard * 0.5;
    margin-bottom: 0;
  }

  .bundle-block .list-row.bundle-item .article__text-container {
    padding: 0;
  }

  .bundle-block
    .list-row.bundle-item.main
    .article.has-comments-counter
    .article__labels.paywall-false {
    padding-bottom: $gapSmall * 3;
  }

  .bundle-block .list-row.bundle-item .article__labels .label--comments {
    height: auto;
  }

  .bundle-block .list-row.bundle-item.sub .article__labels {
    display: none;
  }

  .bundle-block
    .list-row.bundle-item.main
    .article__text-container
    .article__title {
    margin-bottom: $gapStandard;
  }

  .bundle-block
    .list-row.bundle-item.sub
    .article-type--default
    .article__text-container
    .article__title {
    font-family: $fontSansSerif;
    font-weight: normal;
    letter-spacing: -0.1px;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0;
  }

  .bundle-block
    .list-row.bundle-item
    .article-type--default
    .article__text-container
    .article__kicker-title {
    font-family: $fontSansSerif;
    font-weight: 600;
    letter-spacing: -0.1px;
    font-size: 14px;
    line-height: 17px;
    // margin-top: $gapSmall;
    margin-bottom: $gapSmall * 0.5;
  }

  .bundle-block .list-row.bundle-item.main {
    margin-bottom: $gapSmall * 0.5;
  }

  // Bundle Medium

  .bundle.medium .bundle-x2,
  .bundle.small .bundle-x2,
  .bundle.xsmall .bundle-x2 {
    display: flex;
    flex-wrap: wrap;
    border-top-style: solid;
    border-top-width: 1px;
    margin: 0 $gapStandard;
  }

  .bundle.medium .list-row.bundle-item,
  .bundle.small .list-row.bundle-item,
  .bundle.xsmall .list-row.bundle-item {
    min-width: unset;
  }

  .bundle.medium .list-row.bundle-item.main,
  .bundle.small .list-row.bundle-item.main,
  .bundle.xsmall .list-row.bundle-item.main {
    width: 100%;
  }

  .bundle.medium .bundle-x2 .list-row.bundle-item.sub,
  .bundle.small .bundle-x2 .list-row.bundle-item.sub,
  .bundle.xsmall .bundle-x2 .list-row.bundle-item.sub {
    width: 50%;
  }

  .bundle.medium .bundle-x2 .sub.x-1 a.url,
  .bundle.small .bundle-x2 .sub.x-1 a.url,
  .bundle.xsmall .bundle-x2 .sub.x-1 a.url {
    margin-left: 0;
    margin-right: 0;
  }

  .bundle.medium .bundle-x2 .sub.x-2 a.url,
  .bundle.small .bundle-x2 .sub.x-2 a.url,
  .bundle.xsmall .bundle-x2 .sub.x-2 a.url {
    margin-left: $gapStandard;
    margin-right: 0;
  }

  .bundle.medium .bundle-x2 .sub.x-1 .article:before,
  .bundle.small .bundle-x2 .sub.x-1 .article:before,
  .bundle.xsmall .bundle-x2 .sub.x-1 .article:before {
    position: absolute;
    right: 0;
    top: 11px;
    height: 75%;
    width: 50%;
    border-right-width: 1px;
    border-right-style: solid;
    content: '';
  }

  .bundle.medium
    .bundle-x2
    .list-row.bundle-item.sub
    .article-type--default
    a.url,
  .bundle.small
    .bundle-x2
    .list-row.bundle-item.sub
    .article-type--default
    a.url,
  .bundle.xsmall
    .bundle-x2
    .list-row.bundle-item.sub
    .article-type--default
    a.url {
    border-top: none;
  }

  .bundle.medium .bundle-item.sub:nth-child(2) .article:before,
  .bundle.small .bundle-item.sub:nth-child(2) .article:before,
  .bundle.xsmall .bundle-item.sub:nth-child(2) .article:before {
    display: none; //trenger denne?
  }

  .bundle.medium
    .list-row.bundle-item.sub
    .article__live-loaded
    .article__live-container,
  .bundle-block.large
    .list-row.bundle-item.sub
    .article__live-loaded
    .article__live-container {
    display: none;
  }

  .bundle-block .list-row.bundle-item.sub .article__live-loaded/*,
  TODO: see if this class has effect on mobile
  .row .article__live-loaded */ {
    margin-left: 0;
    margin-right: 0;
    border: none;
    border-radius: 0;
  }

  .bundle-block
    .list-row.bundle-item.sub
    .article__live-loaded
    .article__kicker-title {
    display: none;
  }

  .bundle.medium .list-row.bundle-item.sub .article__img-container,
  .bundle.small .list-row.bundle-item.sub .article__img-container,
  .bundle.xsmall .list-row.bundle-item.sub .article__img-container {
    display: none;
  }

  .bundle-block
    .list-row.bundle-item.sub
    .article--withAuthorMeta
    .article__img-container {
    display: none;
  }

  .bundle-block
    .list-row.bundle-item.sub
    .article--withAuthorMeta
    .article__text-container {
    margin-bottom: $gapSmall * 4;
  }

  .bundle-block .list-row.bundle-item.sub .author__image-container {
    position: absolute;
    right: 17px;
  }

  .bundle-block
    .list-row.bundle-item.sub
    .article__titlesAndAuthor-container
    .author__image-container
    img {
    min-width: 70px;
    width: 70px;
    max-width: 70px;
    min-height: 70px;
    height: 70px;
    max-height: 70px;
  }

  .bundle-block
    .list-row--xsmall
    .article-type--default.article__live-loaded
    .article__text-container
    .article__section {
    margin-bottom: 0;
  }

  .bundle-block .article__text-container .e24-icon {
    line-height: 1px;
  }

  // feature small teasers
  .list-row--small .article-type--premium {
    margin-top: $gapStandard;
    margin-bottom: $gapStandard;
  }

  // Stacking xsmall and small articles (remove margin)
  .list-row.list-row--small:not(.live):not(.feature)
    + .list-row.list-row--xsmall
    article,
  .list-row.list-row--small:not(.live):not(.feature)
    + .list-row.list-row--xsmall
    .article-type--default:not(.article--dark):not(.article--noImage) {
    margin: 0;
  }

  .list-row.list-row--small:not(.live):not(.feature)
    + .list-row.list-row--xsmall
    + .list-row.list-row--xsmall
    .article {
    margin: 0;
  }

  .list-row.list-row--small:not(.live):not(.feature)
    + .list-row.list-row--xsmall
    + .list-row.list-row--xsmall
    + .list-row.list-row--xsmall
    .article {
    margin: 0;
  }

  .list-row.list-row--small:not(.live):not(.feature)
    + .list-row.list-row--xsmall
    + .list-row.list-row--xsmall
    + .list-row.list-row--xsmall
    + .list-row.list-row--xsmall
    .article {
    margin: 0;
  }

  .xsmall-before-small:not(.next-live):not(.feature) .article {
    margin: 0;
  }

  .xsmall-before-small
    .article-type--default:not(.article--dark):not(.article--noImage) {
    margin: 0;
  }

  // Avoid double border
  .list-row.list-row--small:not(.live):not(.feature)
    + .list-row.list-row--xsmall,
  .list-row.list-row--xsmall + .list-row.list-row--xsmall {
    margin-top: -1px;
  }

  .xsmall-before-small + .small-after-xsmall {
    margin-top: -1px;
  }

  .list-row.list-row--xsmall.xsmall-before-small + .list-row--small {
    margin-top: -1px;
  }

  // Small live articles need top margin
  .list-row--small + .list-row.list-row--small.live,
  .list-row--xsmall + .list-row.list-row--small.live,
  .list-row.list-row--small.live + .list-row--small,
  .list-row.list-row--small.live + .list-row--xsmall {
    margin-top: $gapStandard;
  }

  .list-row.list-row--xsmall.xsmall-before-small
    + .list-row.list-row--xsmall.xsmall-before-small.next-live {
    margin-top: $gapStandard;
  }

  .list-row.list-row--small.small-after-xsmall.live + .xsmall-after-small {
    margin-top: $gapStandard;
  }

  // Highlighted comment
  .article--highlightedComments {
    .article__text-container .article__title {
      margin-bottom: 0;
    }
    .article-divider {
      width: calc(100% - 32px);
      height: 1px;
      margin: 16px auto;
    }
  }

  .highlighted-comments-container.questions {
    flex: 1;
    font-family: Inter;
    padding: 0 12px;
    height: auto;
    .comments-header {
      font-size: 15px;
      line-height: 18px;
      font-weight: 600;
      color: #0075b0;
      left: 0;
      justify-content: flex-start;
      .comments-counter {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #0075b0;
        left: 0;
        margin-right: 4px;

        &::before {
          content: none;
        }
      }
    }
    .comments-lead-text {
      font-size: 14px;
      line-height: 17px;
      color: #666666;
      margin-top: 4px;
      margin-bottom: 12px;
    }
    .comment-text {
      width: 100%;
      .comment-text-inner {
        height: 131px;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.01em;
        color: #333333;

        border-radius: 16px;
        width: 100%;
        margin: 0 auto;
        border-width: 12px;
        overflow: hidden;
      }
      &:after {
        content: none;
      }
    }
    .additional-info {
      .comment-author {
        font-size: 12px;
        line-height: 14px;
        color: #666;
      }
    }
    .go-to-comment {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #0075b0;
      border-color: #0075b0;
    }
    .highlighted-comments-outer {
      display: block;
      position: static;
      bottom: 0;
      height: auto;
      width: auto;
    }
    .go-to-comment {
      display: block;
    }

    .highlighted-comments {
      -webkit-mask-image: none;
      height: 150px;
      position: relative;
    }
  }

  .mm-boxes {
    .mm-box-content a {
      .jwplayer {
        height: 0 !important;
        padding-bottom: 56.25%;
        margin-bottom: $gapStandard;
        .jw-controls,
        .jw-controls-backdrop {
          display: none;
        }
      }
      .mm-btn {
        display: none;
      }
    }
  }

  .article--bt-inviterer {
    border-radius: 0;
    border-right: 0;
    border-left: 0;
    .article__teaser-container a {
      flex-direction: column;
      .article__img-container {
        position: relative;
        height: 0;
        width: 100%;
        padding-bottom: 56.25%;
        img {
          height: auto;
        }
      }
    }
  }
}

.list .list-row .article--videoFrontTeaser a.url {
  flex-direction: column;
}

// Local-papers show less articles hack in "most-read"-widget
.phone {
  .widget-most-read .news-list-articles li:nth-child(n + 6) {
    display: none;
  }
}

.video-container {
  position: relative;
  &.autoplay .video-icon {
    display: none;
  }
  video {
    display: block;
    position: inherit;
    width: 100%;
  }
  .video-icon {
    position: absolute;
    display: flex;
    bottom: 8px;
    margin: 8px;
    img {
      width: 44px;
      height: 44px;
    }
  }
}

// Podcast & video looping small
.list
  .list-row.list-row--small
  .article-type--default.article--svpVideoPreview
  .podcastImage {
  display: none;
}
.list
  .list-row.list-row--small
  .article-type--default.article--svpVideoPreview
  .article__text-container.podcast
  .podcastImage {
  display: block;
  margin: 0;
  border-radius: 0;
  box-shadow: none;
}
.list
  .list-row.list-row--small
  .article-type--default.article--svpVideoPreview
  .article__text-container.podcast
  .podcast__image-container {
  display: block;
}
