.countdown-container {
    position: relative;
    overflow: hidden;
    height: 140px;
    display: block;
    max-width: 980px;
    margin: 0 auto 12px;
    @media screen and (min-width: 980px) {
        height: 200px;
    }

    .countdown-video {
        position: absolute;
        top: 0;
        object-position: 50% 50%;
        object-fit: cover;
        width: 100%;
        @media screen and (min-width: 980px) {
            top: -100px;
        }
    }

    .countdown-text-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: space-around;
        background: linear-gradient(
            to bottom,
            black,
            rgba(0, 0, 0, 0.2) 50%,
            black
        );

        .countdown-title,
        .countdown-info {
            color: #f5f2f2;
            font-weight: 400;
            padding-left: 10px;
            font-family: 'Inter', sans-serif;
            padding-right: 10px;
            @media screen and (min-width: 980px) {
                padding-left: 14px;
            }
        }

        .countdown-title {
            font-family: 'TiemposHeadlineMedium';
            font-size: 1rem;
            padding-top: 4px;
            @media screen and (min-width: 980px) {
                font-size: 1.7rem;
            }
        }
        .countdown-info {
            font-size: 0.9rem;
            text-align: center;
            padding-right: 12px;
            padding-bottom: 12px;
            font-weight: 500;
            @media screen and (min-width: 980px) {
                font-size: 1.1rem;
            }
        }
    }

    .countdown-time {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .countdown-element {
            display: flex;
            flex-direction: column;
            width: 1.8rem;
            gap: 0rem;
            //background-color: #0300005a;
            color: #f5f2f2;
            justify-content: center;
            align-items: center;
            padding-left: 0.8rem;
            padding-right: 0.8rem;
            padding-top: 0.3rem;
            padding-bottom: 0.3rem;
            @media screen and (min-width: 980px) {
                width: 2.8rem;
            }

            .element {
                font-size: 1.8rem;
                font-variant-numeric: tabular-nums;
                @media screen and (min-width: 980px) {
                    font-size: 2.5rem;
                }
            }

            .text {
                font-size: 0.6rem;
                color: #f5f2f2;
                font-weight: 700;
            }
        }
    }

    .countdown-salogo {
        width: 30px;
        stroke: #f5f2f2;
        position: absolute;
        fill: #f5f2f2;
        right: 8px;
        bottom: 8px;
        @media screen and (min-width: 980px) {
            width: 35px;
            right: 14px;
            bottom: 14px;
        }
    }

    .countdown-btlogo {
        width: 35px;
        stroke: #f5f2f2;
        position: absolute;
        fill: #f5f2f2;
        right: 8px;
        bottom: 8px;
        @media screen and (min-width: 980px) {
            width: 45px;
            right: 14px;
            bottom: 14px;
        }
    }
}
