$color-hc-1: #0f8e90;

body .highlighted-comments-x1 {
  border-bottom: 1px solid #bdb4af;

  .article {
    border-bottom: none;
  }

  .widget-comment-counter {
    display: none !important;
  }

  .article__img-container {
    margin-bottom: $gapLarge;
    margin-right: $gapStandard;
  }

  .article__title {
    @include articleTitle-h4();

    @include articleTitleShort {
      @include articleTitle-h3();
    }
  }

  .article--withTitleMeta .article__title {
    @include articleTitleLong {
      @include articleTitle-h5();
    }
  }
}

.highlighted-comments-container {
  height: 100%;
  font-family: 'Roboto', Arial, sans-serif;
  position: relative;

  .highlighted-comments-outer {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    height: 85%;
    width: 100%;
  }

  .comments-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $color-hc-1;
    font-size: 18px;
  }

  .comments-counter {
    color: #000;
    position: relative;
    left: -33px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: -33px;
      width: 28px;
      height: 27px;
      background: url('/img/icon-msg.png');
    }
  }

  .comments-lead-text {
    color: #6f6f6f;
    margin-top: 15px;
  }

  .highlighted-comments {
    font-family: Arial, Tahoma;
    flex: 1;
    margin-bottom: 10px;
    overflow: hidden;

    -webkit-mask-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 75%,
      rgba(255, 255, 255, 0) 95%
    );
    -webkit-mask-size: 100% 100%;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: left top, left bottom;

    .highlighted-comment {
      transition: all 1s;
    }

    .comment-text {
      display: flex;
      align-items: flex-end;

      &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border-bottom: 12px solid #ebe9e7;
        border-right: 12px solid transparent;
      }
    }

    .comment-text-inner {
      background: #ebe9e7;
      border-radius: 15px 15px 0 15px;
      box-sizing: border-box;
      width: 292px;
      color: #333333;
      font-size: 16px;
      border-style: solid;
      border-color: #ebe9e7;
      border-width: 24px 21px;
    }

    .highlighted-comment-recomendations {
      color: #118686;
      margin-top: 18px;
      font-size: 14px;
      font-weight: bold;
    }

    .additional-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      font-size: 11px;
    }

    .comment-author {
      margin-top: 3px;
      margin-bottom: 15px;
      font-size: 15px;
      color: #6f6f6f;
    }
  }

  .go-to-comment {
    color: $color-hc-1;
    border: 1px solid $color-hc-1;
    margin-bottom: 20px;
    display: block;
    box-sizing: border-box;
    text-align: center;
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    font-weight: bold;
    background: transparent;
  }
}
