@import 'helpers/mixins';

// desktop layout
@import 'page/desktop-layout';
@import 'row-templates';
@import 'page/desktop-ads';

// mobile layout
@import 'page/mobile-layout';
@import 'page/mobile-ads';

@import 'tablet-tweaks';

// comments alert icon in header
@import 'notifications-header-icon';

// article teaser elements
@import 'page/article';
@import 'page/article-elements';

// widgets
@import 'widgets/boligsalg';
@import 'widgets/resultat';
@import 'widgets/cabin';
@import 'widgets/football';

// Braze
@import 'widgets/braze';

// TODO: move to regional 'tweaks' file
body .highlighted-comments-container .go-to-comment {
  font-family: $fontSansSerif;
  font-weight: 600;
}
