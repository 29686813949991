#regional-user-feedback {
    display: none;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 12px;
    padding: 12px;

    .feedback-title {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        text-align: center;

        color: #000000;
        margin-bottom: 24px;
    }

    .feedback-form {
        display: flex;
        margin: 0 auto;
        margin-bottom: 24px;
    }

    .feedback-form .emoji-container.colored {
        background: #f1f1f3;
    }

    .feedback-form .emoji-container {
        display: flex;
        border-radius: 100px;
        min-width: 250px;
        justify-content: space-between;
        padding: 6px 12px;
    }

    .feedback-form .emoji-container.text-phase {
        margin-right: 12px;
    }

    .user-input {
        font-size: 32px;
        line-height: 39px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }

    .user-input:hover {
        cursor: pointer;
    }

    .user-input-value {
        display: none;
    }

    .text-input-container {
        width: 100%;
        display: flex;
    }

    .text-input-container #userfeedback-text-input {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        background: #f1f1f3;
        border-radius: 4px;
        padding: 12px;
        margin-right: 12px;
        flex: 1;
        border: none;
    }

    #userfeedback-submit {
        border: 1px solid #333;
        box-sizing: border-box;
        border-radius: 100px;
        padding: 12px;

        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 30px;
    }

    #userfeedback-submit:hover {
        cursor: pointer;
        background-color: #333;
        color: #fff;
        border-color: #fff;
    }

    .feedback-done-thanks {
        width: 100%;
    }

    .logo {
        margin: 0 auto;
    }

    .logo img {
        height: 21px;
    }

    .unselected {
        opacity: 20%;
    }

    .feedback-form.fullWidth {
        width: 100%;
    }

    .hidden {
        display: none;
    }

    @media (max-width: 794px) {
        .feedback-form {
            flex-direction: column;
        }

        .feedback-form.fullWidth .emoji-container {
            margin-bottom: 12px;
        }
        .feedback-form .text-input-container {
            flex-direction: column;
        }

        .feedback-form .text-input-container #userfeedback-text-input {
            margin-bottom: 12px;
            font-size: 13px;
            line-height: 16px;
        }
        .feedback-form .text-input-container #userfeedback-submit {
            width: fit-content;
            margin: 0 auto;
        }
    }

    textarea {
        resize: none;
    }
}
