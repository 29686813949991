html {
  height: auto; // should override Core Header's style that sets height: 100% for html
  min-width: $pageWidthTablet;

  @media #{$queryDesktop} {
    min-width: $pageWidthDesktop;
  }

  @media #{$queryPhone} {
    min-width: $pageWidthPhone;
  }
}

body {
  margin: 0;
  background-color: $colorBackgroundMobile;
  -webkit-font-smoothing: antialiased;

  @media #{$queryDesktop} {
    background-color: $colorBackground;
  }
}

#content-wrapper {
  position: relative;
}

.articles-container {
  width: auto;
  padding: $gapStandard;
  margin: 0 $gapStandard $gapLarge;
  background-color: #ffffff;
  box-sizing: border-box;
  position: relative;
  z-index: 1;

  @media #{$queryDesktop} {
    width: $widthContent + 2 * $gapLarge;
    padding: $gapLarge;
    margin: 0 auto $gapLarge;
  }
}

a,
a:active,
a:focus,
a:hover,
a:visited {
  color: inherit;
  text-decoration: none;
}

.content-outer {
  overflow: hidden;
}

@media screen and (min-width: 1360px) {
  .content-outer {
    overflow: visible;
  }
}
