#comics-tablet {
  display: none;
}

#row-2 {
  margin: 0 auto;
  order: unset;
}

.perks-container {
  background-color: rgb(238, 249, 255);
  min-height: 500px;
  margin: 32px 0;
  overflow: hidden;
  padding: 10px 0;
}
.perks-container .pks-header {
  min-height: 80px;
  text-align: center;
  line-height: 80px;
}
.pks-header-title {
  font-family: $fontSerif;
  font-weight: $fontHeadlineWeight;
  font-size: 24px;
  color: #ab7a4b;
}

.pks-card-title {
  font-size: 12px;
  letter-spacing: 0.22px;
  color: #ab7a4b;
  text-transform: uppercase;
  font-family: $fontSerif;
  font-weight: $fontHeadlineWeight;
}

#perks-content {
  display: flex;
  flex-direction: row;
}
#archivePromoNew {
  order: 1;
  width: 304px;
  background-color: white;
  border-radius: $borderRadius;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.22);
  height: 340px;
  font-family: $fontSerif;
  font-weight: $fontHeadlineWeight;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 12px;
  float: left;
}

#archivePromoNew .img-container {
  padding: 0;
  height: 240px;
}

.archive-image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

#archivePromoNew .text-container {
  padding: 0 10px;
}

#archivePromoNew .kicker-title {
  font-family: $fontSansSerif;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.22px;
  color: #ab7a4b;
  text-transform: uppercase;
  margin-bottom: 10px;
}

#archivePromoNew .text {
  margin: 0;
}

#hjernetrim-mobile {
  display: none;
}

#comics-title-mobile {
  display: none;
}

#hjernetrim,
#comics {
  width: 290px;
  float: left;
  margin: 0 10px;
  font-family: $fontSansSerif;
  font-weight: 500;
  font-size: 17px;
  height: 350px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.widget-sub {
  padding: 15px 0 0 15px;
  background-color: white;
  height: 70px;
  border-radius: $borderRadius;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.22);
  display: grid;
  grid-template-columns: 70% 30%;
}

.arrow-container {
  display: block;
}

.arrow {
  display: none;
}

.arrow-desktop,
.arrow {
  background-color: #ebd9c1;
  border-radius: 30px;
  padding: 10px;
  color: #333;
  font-family: $fontSansSerif;
  font-weight: 500;
  font-size: 15px;
  width: max-content;
}

.arrow-desktop:after {
  content: '\2794';
  font-size: 18px;
  color: #333;
  font-family: $fontSansSerif;
  font-weight: normal;
}

#widget-comics,
#widget-hjernetrim {
  padding: 15px;
  background-color: white;
  height: 325px;
  border-radius: $borderRadius;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.22);
}

#widget-hjernetrim {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 340px;
  padding: 0px;
}

.ht-content {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

#comics-title-desktop,
.ht-content h3 {
  margin-top: 4px !important;
}

#comics {
  order: 2;
}

#hjernetrim {
  order: 1;
}

#comicsteaser .article__title {
  font-size: 32px;
}

.perks-container .article__img-container-small {
  object-fit: cover;
}

#tablet-container {
  display: none;
}

#hjernetrim h3,
#comics h3,
#hjernetrim-mobile h3,
#comics-tablet h3 {
  margin: 0;
  font-family: $fontSansSerif;
  font-weight: 500;
  font-size: 17px;
}

.pks-bt-frontpage {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
  border-radius: $borderRadius;
  height: 340px;
}

#widget-perks-frontpage {
  order: 3;
  width: 300px;
  float: left;
  margin: 0 10px;
}

#perks-carousel-container {
  order: 3;
}

.pks-divider-container {
  display: grid;
  grid-template-columns: 6px auto 6px;
  padding: 0 10px;
  position: relative;
  top: 250px;
  z-index: 2;
  height: 0;
}

.pks-divider  {
  border-bottom: 1px dashed rgb(222, 222, 222);
}

.pks-indent {
  height: 12px;
  width: 6px;
  border-radius: 0 calc($borderRadiusSmall * 2) calc($borderRadiusSmall * 2) 0;
  background: #eef9ff;
  position: relative;
  top: 6px;
  box-shadow: -3px 3px 1px -2px rgb(222, 222, 222) inset;
}

.pks-indent.right {
  border-radius: calc($borderRadiusSmall * 2) 0 0 calc($borderRadiusSmall * 2);
  box-shadow: 2px 2px 1px 0 rgb(222, 222, 222) inset;
}

.pks-indent::before {
  content: '';
  width: 6px;
  height: 12px;
  border-radius: calc($borderRadiusSmall * 2);
  background: #eef9ff;
  position: absolute;
  left: -6px;
}

.pks-indent.right::before {
  left: 6px;
}

.archive-perks-container {
  order: 2;
  display: flex;
  flex-direction: row;
}

@include respond-to(tablet-only) {
  #widget-perks-frontpage {
    width: 300px;
    margin: 0px;
  }

  .archive-perks-container {
    width: max-content;
    order: 3;
  }

  .pks-divider-container {
    padding: unset;
  }

  .ht-content {
    padding: 0 15px;
    justify-content: space-evenly;
  }

  #comics-tablet {
    display: flex;
    width: 100%;
    order: 1;
    height: max-content;
    margin: 0 10px;
  }

  #comics-tablet #widget-comics {
    height: max-content;
  }

  #comics {
    display: none;
  }

  #row-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    order: 2;
    margin: 16px 10px;
    width: 100%;
  }

  .perks-container .pks-header {
    min-height: unset !important;
    line-height: 21px;
    padding: 20px;
  }

  .pks-header-title {
    font-size: 18px;
  }

  #perks-content {
    flex-direction: column;
    padding-right: 25px;
  }

  #widget-hjernetrim,
  #widget-comics {
    margin: unset !important;
    width: unset;
    margin: unset;
  }

  .comic {
    margin-bottom: 0;
  }

  #hjernetrim {
    order: 2;
    max-height: 340px;
    width: unset;
    margin: 0;
    margin-right: 10px;
  }

  .widget-sub {
    min-width: 170px;
    height: 130px;
    grid-template-columns: auto;
  }

  #hjernetrim-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #hjernetrim-arrow-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .widget-sub {
    /*min-width: 170px;*/
    height: 130px;
    grid-template-columns: auto;
  }

  .arrow:after {
    content: '\2794';
    margin-left: 23px;
    font-size: 18px;
    color: #333;
    font-family: $fontSansSerif;
    font-weight: normal;
  }

  .widget-sub,
  #widget-comics {
    margin-right: 1px;
  }

  #widget-comics {
    min-width: 290px;
  }

  #archivePromoNew {
    order: 2;
    width: 344px;
  }
}

@include respond-to(mobile-only) {
  .perks-container .pks-header {
    min-height: 60px;
    line-height: 21px;
    padding: 20px 0 0 0;
  }

  .pks-header-title {
    font-size: 18px;
    margin: 0 $gapStandard;
  }

  #perks-content {
    flex-direction: column;
    padding: 10px;
    max-width: 380px;
    margin: auto;
  }

  #row-2 {
    margin: unset;
    order: unset;
    flex-direction: column;
    display: flex;
  }

  #hjernetrim {
    display: none;
  }

  #hjernetrim-mobile {
    display: block;
    margin-bottom: 8px;
  }

  #hjernetrim {
    order: 1;
    height: 120px;
  }

  #comics {
    order: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: unset;
    height: unset;
    margin: 0;
  }

  .comic {
    margin-bottom: 0;
  }

  #comic-nemi {
    display: none;
  }

  #comics-title-desktop {
    display: none;
  }

  #comics-title-mobile {
    display: block;
    margin-bottom: 10px;
  }

  .pks-card-title {
    display: none;
  }

  #widget-hjernetrim,
  #widget-comics {
    margin: unset !important;
    width: unset;
    margin: unset;
    height: 130px;
  }

  .widget-sub {
    min-width: 170px;
    height: 130px;
    grid-template-columns: auto;
  }

  .arrow-container {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  #hjernetrim-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #hjernetrim-arrow-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .arrow {
    display: block;
    height: 30px;
    width: 73px;
    height: 23px;
    line-height: 20px;
    text-align: center;
  }

  .arrow:after {
    content: '\2794';
    font-size: 18px;
    color: #333;
    font-family: $fontSansSerif;
    font-weight: normal;
  }

  #widget-comics {
    min-width: 290px;
    padding: 10px 15px 25px 15px;
  }

  #widget-hjernetrim {
    padding: 15px;
  }

  #widget-perks-frontpage,
  .pks-bt-frontpage {
    margin: 0 0 10px 0;
    width: 100%;
    max-width: 380px;
    height: max-content;
  }

  .pks-bt-frontpage {
    margin-top: 10px;
  }

  .pks-divider-container {
    padding: unset;
    top: 260px;
  }

  .pks-bt-frontpage .owl-stage-outer {
    height: unset !important;
  }

  .archive-perks-container {
    flex-direction: column;
    order: 2;
    min-height: 370px;
  }
}
