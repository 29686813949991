section.widget-resultat-widget {
    max-width: 15px 0;
    box-sizing: border-box;
    margin: 24px 0;
    font-family: Inter;
}

#resultat-list-widget {
    padding: 24px;
    margin: 16px;
    border: solid 1px #ddd;
    border-radius: $borderRadiusSmall;
    @include respond-to(web) {
        width: auto;
        margin: 0 0 16px;
    }
    @include respond-to(tablet) {
        width: auto;
        margin: 0 0 16px;
    }
    @include respond-to(mobile-only) {
        padding: 16px 12px;
    }

    .resultat-list-header {
        width: 100%;
        font-family: Inter;
        margin-bottom: 24px;
        align-items: baseline;
        .resultat-title-container {
            display: flex;
            margin-right: 12px;
            align-items: baseline;
            .resultat-logo {
                content: url('/seksjon/dist/img/resultat-logo.svg');
                height: 24px;
                width: 24px;
                align-self: center;
                margin-right: 12px;
            }
            .title {
                color: #173a4d;
                font-size: 24px;
                line-height: 27px;
                font-weight: 700;
                margin: 0;
            }
        }
        .header-text,
        a {
            color: #666;
            font-weight: 500;
            font-size: 15px;
            margin: 0;

            @include respond-to(tablet-only) {
                line-height: 20px;
            }
        }
        a {
            text-align: right;
            flex: 2;
        }
        @include respond-to(mobile-only) {
            position: relative;
            display: flex;
            flex-direction: column;
            height: auto;
            margin-bottom: 12px;
            a {
                position: absolute;
                top: 6px;
                right: 0;
            }
            .resultat-title-container {
                margin-bottom: 12px;
            }
            .header-text,
            .see-all {
                color: #666;
            }
        }
    }

    .resultat-list-articles {
        list-style: none;
        margin-bottom: 24px;
        padding: 0;
        // article
        .column--small {
            font-family: $fontSerif;
            font-weight: $fontHeadlineWeight;
            border: 1px solid #ddd;
            &:hover {
                border: 1px solid #bbb;
            }
            border-radius: $borderRadiusSmall;
            overflow: hidden;
            margin-right: 0;
            .article__text-container {
                .article-title {
                    line-height: 1.24em;
                    font-size: 1.3125rem;
                    letter-spacing: -0.01em;
                    margin: 0;
                }
            }

            .article__img-container {
                height: 171px;
                width: unset;
                img {
                    object-fit: cover;
                }
            }

            // last-child doesnt work since there is 4 articles... Therefore first and second
            &:first-child,
            &:nth-child(2) {
                margin-right: 12px;
            }
        }
        @include respond-to(mobile-only) {
            display: flex;
            flex-direction: column;
            .column--small {
                border: none;
                border-radius: 0;
                border-bottom: 1px solid #ddd;
                a {
                    margin-bottom: 12px;
                }

                &:hover,
                &:focus {
                    border: none;
                    border-radius: 0;
                    border-bottom: 1px solid #ddd;
                }

                .article__img-container img {
                    border-radius: $borderRadiusSmall;
                }
                .article__text-container {
                    margin: 12px 0 0;
                    .article-title {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }

                &:first-child,
                &:nth-child(2) {
                    margin-right: 0;
                }

                &:first-child {
                    .article__text-container .article-title {
                        line-height: 1.24em;
                        font-size: 1.3125rem;
                        letter-spacing: -0.01em;
                    }
                }
                &:nth-child(2),
                &:nth-child(3) {
                    font-family: Inter;
                    a {
                        display: flex;
                        flex-direction: row-reverse;
                        .article__img-container {
                            padding-top: 16px;
                            height: auto;
                            img {
                                width: 93px;
                                height: 62px;
                            }
                        }
                        .article__text-container {
                            margin-right: 12px;
                            .article-title {
                                line-height: 1.3em;
                                font-size: 0.875rem;
                                letter-spacing: -0.01em;
                            }
                        }
                    }
                }
            }
        }
    }

    .search-container {
        display: flex;
        form {
            margin-right: 12px;
        }

        .tag-list {
            display: flex;
            flex-wrap: wrap;
            align-self: center;
            margin-top: -6px;
            .tag {
                font-family: Inter;
                background-color: #f1f1f3;
                border-radius: $borderRadiusSmall;
                padding: 16px;
                color: #666;
                font-size: 14px;
                line-height: 16px;
                margin: 6px;
                &:last-child {
                    margin-right: 0;
                }
                &:hover {
                    background-color: #dbdbdb;
                }
            }
        }
        @include respond-to(mobile-only) {
            flex-direction: column;
            form {
                margin-right: 0;
            }
            .tag-list {
                display: flex;
                flex-wrap: wrap;
                margin-top: 12px;
                .tag {
                    padding: 8px 12px;
                }
            }
        }
    }

    #resultat-chevron {
        position: absolute;
        top: 7px;
        right: 12px;

        .leftArm,
        .rightArm {
            transition-duration: 0.225s;
            transition-property: transform;
        }

        .leftArm {
            transform-origin: 33.4% 50%;
        }
        .rightArm {
            transform-origin: 67% 50%;
            position: absolute;
            top: 0;
            left: 0;
        }

        .flipLeft {
            transform: rotate(90deg);
        }
        .flipRight {
            transform: rotate(-90deg);
        }
    }

    .resultat-autocomplete {
        font-family: Inter;
        position: relative;
        display: inline-block;
    }

    input#resultat-autocomplete:hover {
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.31);
    }

    .resultat-autocomplete-items {
        position: absolute;
        max-height: 300px;
        background: #fff none repeat scroll 0% 0%;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        border-color: #ddd;
        border-style: none solid solid;
        border-width: 0px 1px 1px;
        overflow-y: auto;
        scrollbar-width: none;
        z-index: 1001;
        top: 100%;
        left: 0;
        right: 0;
        padding: 12px;
        width: 280px;
    }
    .autocomplete-link {
        padding: 10px 0;
        cursor: pointer;
        background-color: #fff;
        display: block;
        &:hover {
            font-weight: bold;
        }
    }

    ::placeholder {
        color: #212529;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
        color: #212529;
    }

    ::-ms-input-placeholder {
        color: #212529;
    }

    input#resultat-autocomplete {
        border: 1px solid #ddd;
        background-color: white;
        border-radius: 25px;
        min-width: 276px;
        padding: 14px;
        text-indent: 30px;
    }

    .open {
        border-bottom: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .resultat-list-header a.autocomplete-link,
    .autocomplete-items .autocomplete-link {
        display: block;
        background: white;
        text-align: left;
    }

    // Remove 4th article
    .resultat-list-articles li:last-child {
        display: none;
    }
    .underlined {
        border-bottom: 1px solid #666;
    }
}
