// Set color variables for publication and then import this file (just like in bt/public/scss/highlighted-comments)
@each $publication, $key in $publicationsConfig {
  body .highlighted-comments-container {
    .comments-header-text {
      color: map-get($key, header);
    }

    .comments-lead-text {
      color: map-get($key, commentLeadText);
    }

    .go-to-comment {
      color: map-get($key, linkText);
      border-color: map-get($key, linkText);
    }

    .highlighted-comments {
      .comment-text-inner {
        color: map-get($key, commentText);
      }

      .comment-author {
        color: map-get($key, author);
      }
    }
  }
}
