// Common styling mobile and desktop :point-down:
.list .list-row,
.row {
  .live__timeline-container {
    list-style: none;
    padding: 0;
    margin: 0 $gapStandard 0 $gapStandard;

    font-family: $fontSansSerif;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    .timeline--item {
      border-bottom-style: solid;
      border-bottom-width: 1px;
      padding: ($gapSmall + 1px) $gapStandard;
      margin-left: -$gapStandard;
      margin-right: -$gapStandard;

      a {
        display: flex;
        flex-direction: row;
      }

      &:last-child {
        border: none;
      }

      .timeline--date {
        font-weight: normal;
        font-family: $fontSansSerif;
        position: relative;
        padding-left: 10px; // tweaks
        padding-right: 10px;
        font-size: 11px;
        line-height: 17px;
        align-self: baseline;
        font-variant-numeric: tabular-nums;

        &::before {
          content: '';
          position: absolute;
          border: none;
          border-radius: 50%;
          width: 6px;
          height: 6px;
          margin-top: 5px;
          left: 0;
        }
      }

      .timeline--content {
        align-self: baseline;

        .line-clamp {
          align-self: baseline;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
        }
      }
    }
  }

  .article--dark {
    .timeline--item {
      border-bottom-color: #28414f;

      .timeline--date {
        font-weight: 600;
      }
    }
  }
}

// Mobile styling below :point-down:
.list {
  .article--live {
    &.article--liveReordered {
      .timeline--item:first-child {
        padding-top: $gapSmall;
      }
    }

    &.article--large {
      .article__img-container {
        overflow: hidden;

        height: 0;
        padding: 0 0 56.25% 0;

        img {
          height: auto;
        }
      }
    }

    &.article--medium {
      margin-left: $gapStandard;
      margin-right: $gapStandard;

      .article__img-container {
        overflow: hidden;

        height: 0;
        padding: 0 0 56.25% 0;

        img {
          height: auto;
        }
      }
    }
  }
}
