/* mobile styles */

.bundle-block.mobile {
  &.bundle-breaking {
    border: 1px solid $borderColorBreaking;

    .article-divider {
      background-color: $borderColorBreaking;
    }
    &.bundle-dark {
      border: 1px solid $borderColorBreaking;
      .article-divider {
        background-color: $borderColorBreaking;
      }
    }
  }
  .article.article--breaking {
    .article__text-container {
      .article__section {
        line-height: 2rem;
      }
    }
  }
  .article.article--large {
    .article__title {
      line-height: 1.15em;
      letter-spacing: -0.025em;
    }
  }
  .article:not(.article--major-news) .subteasers {
    .article__text-container {
      .article__title {
        line-height: 1.35rem;
        font-size: 1.14rem;
        font-weight: 500;
        letter-spacing: -0.036em;
      }
    }
  }
}

.list .list-row {
  .article--small .article__title,
  .article--large .article__title,
  .article--medium .article__title {
    @media (max-width: $tablet-width) {
      line-height: 1.1em;
      letter-spacing: -0.025em;
    }
  }
}

/* desktop styles */

.row.top-60-40-block {
  .article--large {
    .article__title,
    .article__title--long,
    .article__title--medium,
    .article__title--short {
      line-height: 1.1em;
      letter-spacing: -0.025em;
    }
  }
}

.collage-x3 {
  .column--big {
    .article__text-container {
      .article__title,
      .article__title--long,
      .article__title--medium,
      .article__title--short {
        line-height: 1.1em;
        letter-spacing: -0.025em;
      }
    }
  }
  .column--small {
    .article,
    .article.article--noImage:not(.article--live) {
      .article__title,
      .article__title--long,
      .article__title--medium,
      .article__title--short {
        line-height: 1.1em;
        letter-spacing: -0.025em;
      }
    }
  }
}

.collage-x4 {
  .column--big {
    .article__text-container {
      .article__title,
      .article__title--long,
      .article__title--medium,
      .article__title--short {
        line-height: 1.1em;
        letter-spacing: -0.025em;
      }
    }
  }
  .column--small {
    .article {
      .article__title,
      .article__title--long,
      .article__title--medium,
      .article__title--short {
        line-height: 1.1em;
        letter-spacing: -0.025em;
      }
    }
  }
}

.row.vertical-x2 {
  .article {
    .article__title,
    .article__title--long,
    .article__title--medium,
    .article__title--short {
      line-height: 1.1em;
      letter-spacing: -0.025em;
    }
  }
}

.row.vertical-x3 {
  .article__text-container {
    .article__title,
    .article__title--long,
    .article__title--medium,
    .article__title--short {
      line-height: 1.1em;
      letter-spacing: -0.025em;
    }

    .article__title--short {
      font-size: 1.625rem;
    }
  }
}

.vertical-x4 {
  .article.article--xsmall {
    .article__section-kicker-container {
      font-size: 0.8rem;
    }
    .article__text-container {
      .article__section {
        font-size: 0.8rem;
      }
      .article__title,
      .article__title--long,
      .article__title--medium,
      .article__title--short {
        letter-spacing: -0.02em;
        line-height: 1.15em;
      }
    }
  }
}

.horizontal-x1 {
  .article,
  .article.article--noImage {
    .article__title {
      line-height: 1.1em;
      letter-spacing: -0.025em;
    }
  }
}

.row.bundle-large.bundle-breaking,
.row.bundle-major-news.bundle-breaking {
  border: 1px solid $borderColorBreaking;

  .article-divider {
    background-color: $borderColorBreaking;
  }
  &.bundle-dark {
    border: 1px solid $borderColorBreaking;
    .article-divider {
      background-color: $borderColorBreaking;
    }
  }
}

.row .article.article--large:not(.article--major-news) {
  .article__text-container {
    margin: $gapStandard;
  }
}
