#podcast-latest {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-style: solid;
  border-width: 1px;
  border-radius: $borderRadius;
  color: #333333;
  font-family: Inter, Helvetica, Arial, sans-serif;
  margin: $gapStandard;

  .podcast-main,
  .podcast-sub,
  .podcast-sub .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .item {
    margin: 16px 16px 8px 16px;
  }

  .item a {
    flex-direction: row-reverse;
    display: flex;
    height: 100%;
  }

  .podcast-image img {
    border-radius: $borderRadiusSmall;
  }

  .podcast-main {
    color: #ffffff;
    z-index: 0;

    .item {
      margin-bottom: 0;
      border-radius: $borderRadiusSmall;
    }

    .item a {
      margin: 16px;
    }
    .podcast-kicker {
      font-weight: 600;
      font-size: 18px;
      line-height: 21.78px;
      letter-spacing: -0.1px;
    }

    img {
      width: 80px;
      height: 80px;
    }
  }

  .podcast-header {
    padding: 24px 16px 0px 16px;
    justify-content: space-between;
    display: flex;

    .icon-title {
      display: flex;
      font-family: Inter, Helvetica, Arial, sans-serif;
      font-weight: 600;
      font-size: 19px;

      .icon {
        background-image: url(https://cdn.bt.no/bt/podcast-headphones-icon.svg);
        height: 24px;
        width: 24px;
        background-repeat: no-repeat;
        flex-direction: row;
      }

      .title {
        flex-direction: row;
        padding-top: 4px;
        padding-left: 8px;
        font-size: 19px;
        margin-block: 0;
      }
    }

    .see-all {
      padding-top: 4px;
      font-family: Inter, Helvetica, Arial, sans-serif;
      padding-bottom: 5px;
      border-bottom-style: solid;
      border-bottom-color: #e9e8e5;
      border-bottom-width: 1px;
      width: fit-content;
    }
  }

  .podcast-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
    letter-spacing: -0.1px;
  }

  .podcast-text {
    flex: 1;
    margin-right: 8px;
  }

  .podcast-divider {
    width: calc(100% - 32px);
    height: 1px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #bfbfbf;
    margin: 0 auto;
  }

  .podcast-sub {
    .podcast-kicker {
      font-weight: 600;
      font-size: 14px;
      line-height: 16.94px;
      letter-spacing: -0.1px;
    }

    .podcast-title {
      margin-top: 4px;
    }

    .item img {
      width: 64px;
      height: 64px;
    }
  }

  .podcast-background {
    background-image: url('https://cdn.bt.no/rnd/hva-skjedde_180x180.webp');
    display: block;
    position: relative;
    background-size: 0;
    opacity: 1;
    visibility: visible;
  }

  .podcast-background:before {
    content: '';
    position: absolute;
    background-image: inherit;
    background-position: center;
    background-size: cover;
    filter: blur(20px) saturate(0.9);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    clip-path: inset(0.3333% 0.3333% 0.3333% 0.3333% round 4px);
    -webkit-clip-path: inset(0.3333% 0.3333% 0.3333% 0.3333% round 4px);
    z-index: -1;
  }

  .podcast-background:after {
    content: '';
    position: absolute;
    background-color: rgba(68, 68, 68, 0.5);
    transform: translate3d(0, 0, 0);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    clip-path: inset(0% 0% 0% 0% round 4px);
    -webkit-clip-path: inset(0% 0% 0% 0% round 4px);
    z-index: -1;
  }
}

@media screen and (min-width: 768px) {
  #podcast-latest {
    margin: 0;
    height: 400px;
    justify-content: unset;

    .podcast-main {
      .item {
        padding-bottom: 16px;
      }
    }

    .podcast-divider.bottom {
      display: none;
    }

    .podcast-sub .item:last-child {
      display: none;
    }
  }
}
