@import '../../../node_modules/@schibsted-regional/components/dist/es/assets/style.css';
@import 'node_modules/@sch-inventory/advertory/styles/bt/styles';

body {
  overflow-x: hidden;
}

.breaking-x1 .article--super-breaking:not(.article--major-news) {
  &:not(.article--noImage) {
    .article__text-container {
      margin: $gapStandard;
      &.no-margin {
        margin: 0;
      }
    }
  }
}

.ch-core-header {
  z-index: 99999;

  .ch-header-main {
    border-left: none;
  }
}

#navigation {
  &.ch-header-sub {
    border-left: none;
  }
}

.b-lazy {
  max-width: 100%;
}

#container {
  width: 100%;
  position: relative;
}

.frontpage #container {
  background-color: #ffffff;
}

.hidden {
  display: none;
}

a.link__nextPage {
  font-family: $fontSansSerif;
}

@include respond-to(mobile-only) {
  .mm-box3-container {
    overflow-x: hidden;
  }
}

// advertory styles
.advertory-common-netboard-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}

.contentboard-container {
  position: relative;
}
