* {
  border-color: $borderColor;
}
.article-divider {
  background-color: $borderColor;
}

.content-outer {
  background: $colorBackgroundPage;
}

.articles-container {
  background: $colorBackgroundArticle;
}

.article {
  color: $defaultColorText;
  background: $colorBackgroundArticle;

  .article__labels {
    .label--paywall {
      color: $colorArticleLabels;
      font-weight: 400;
      letter-spacing: 0.02em;
    }
  }

  .label--comments {
    .counter-container {
      color: $colorArticleLabels;
    }
  }

  .article__kicker-title,
  .article__section {
    font-size: $kicker-desktop-size;
    line-height: $kicker-desktop-lh;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: $kicker-mobile-size;
      font-weight: 500;
    }
  }

  &.article-section--btmeninger-kommentar,
  &.article-section--btmeninger-debatt,
  &.article-section--btmeninger-leder {
    .article__section {
      color: $color-green;
    }
    &.article--dark {
      .article__section {
        color: #5ac9ca;
      }
      .article__kicker-title {
        color: #f2f2f0;
      }
    }
    .author__image-container img {
      background: $byline-bg-green;
    }
  }

  &.article-section--kultur-kulturdebatt {
    .article__section {
      color: $color-magenta;
    }
  }

  &.article-section--kultur-bergensbeste {
    .article__section {
      color: #b21c64;
    }
  }

  &.article-section--kultur,
  &.article-section--kultur-anmeldelser,
  &.article-section--kultur-fripenn {
    .author__image-container img {
      background: $byline-bg-magenta;
    }
  }

  &.article-section--sport-kommentar {
    .article__section {
      color: $colorLive;
    }

    .author__image-container img {
      background: $byline-bg-red;
    }
  }

  &.article--live {
    .article__section {
      color: $colorLive;

      &::before {
        background: $colorLive;
      }
    }

    .live__timeline-container {
      .timeline--date {
        color: $colorLive;
        font-weight: 600;
        padding: 0 8px 0 12px;
        font-size: 0.75rem; // 12px

        &::before {
          background: $colorLive;
        }
      }

      .timeline--content {
        font-size: $kicker-desktop-size;
        font-weight: 500;
      }
    }
  }

  .article__teaser-container {
    .article__text-container {
      .article__section-kicker-container {
        display: flex;
        align-items: center;
      }

      .article__kicker-title {
        font-size: $kicker-desktop-size;
        line-height: $kicker-desktop-lh;
        font-weight: 500;

        @media (max-width: 768px) {
          font-size: $kicker-mobile-size;
        }
      }

      .article__section {
        font-size: $kicker-desktop-size;
        line-height: $kicker-desktop-lh;

        @media (max-width: 768px) {
          font-size: $kicker-mobile-size;
        }
      }
    }
  }

  &.article--breaking {
    .article__text-container .article__section::before {
      border-radius: 4px;
      margin-right: $kicker-gap;
    }
  }

  &.article--live {
    .article__text-container .article__section::before {
      padding: 2px 5px;
    }

    &:not(.article--breaking, :has(.article__section--valgstudio-sa-2023))
      .article__text-container
      .article__section {
      &::before {
        width: $live-label-icon-size;
        height: $live-label-icon-size;
        padding: 0;
      }
    }
  }
}

.article.article--dark {
  color: $darkskinTextColor;
  background-color: $darkskinBackgroundColor;
  border-color: $darkskinBorderColor;

  .timeline--item {
    border-bottom-color: $red-red-base;
    border-width: 0.5px;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }

  &.article--breaking {
    border-color: $borderColorBreaking;

    .article__section {
      color: $darkskinTextColor;

      &::before {
        background: $darkskinBreakingLabel;
      }
    }
  }

  &.article--live {
    .article__section {
      color: $darkskinLiveColor;

      &::before {
        background: $darkskinLiveColor;
      }
    }

    .live__timeline-container {
      .timeline--date {
        color: $darkskinLiveColor;

        &::before {
          background: $darkskinLiveColor;
        }
      }
    }
  }

  .article__labels {
    .label--paywall {
      color: $darkskinTextColor;
    }
  }

  .label--comments {
    .counter-container {
      filter: invert(100%);

      &::after {
        border-color: transparent;
      }

      .widget-comment-counter {
        background-color: transparent;
      }
    }
  }
}
