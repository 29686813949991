// all the regional fonts
@import 'fonts';

// core/curate configs/mixins, etc
@import 'core/core-main';

// regional mixins
@import 'helpers/mixins';

// common regional frontpage styles
@import 'frontpage';

// common regional page styles
@import 'page';

// common regional frontpage ad styles
@import 'ads';

@import 'section-header';

@import 'helpers/helpers';

// Localfootball
@import 'localfootball/index';

// Stories overlay from @vgtv/stories-overlay
.stories-overlay {
  z-index: 9999;
}
